import { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { SelectOptions } from '../SelectOptions/SelectOptions';
import { Container } from './Styles';

const SelectScheme = ({ option, error, placeholder, getValue, handleChange, disabledInput }) => {
  const [value, setValue] = useState('');

  useEffect(() => setValue(getValue), [getValue]);

  return (
    <Flex width={1} mb={36}>
      <Container>
        <Box width={1}>
          <SelectOptions
            placeholder={placeholder}
            option={option}
            handleChange={handleChange}
            error={error}
            getValue={value === '' ? [] : [{ label: value }]}
            disabledInput={disabledInput}
          />
        </Box>
      </Container>
    </Flex>
  );
};

export default SelectScheme;
