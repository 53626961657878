import React from 'react';
import ReactDOM from 'react-dom';
import useAlert from '../../hooks/useAlert';
import { ReactComponent as Success } from '../../images/Logo_success.svg';
import { ReactComponent as Error } from '../../images/Logo_error.svg';
import { Background, ModalWrapper } from './Styles';

/* Modal de aprobacion cuando todo sucede bien o mal */
const ModalApprove = () => {
  const { response } = useAlert();
  return ReactDOM.createPortal(
    response.show ? (
      <Background>
        <ModalWrapper>
          {response.status === 200 && <Success />}
          {response.status >= 400 && <Error />}
          <p>{response.message}</p>
        </ModalWrapper>
      </Background>
    ) : (
      <div />
    ),
    document.getElementById('root')
  );
};

export default ModalApprove;
