import { Button } from "react-bootstrap";
import styled from "styled-components";

const ContainerCard = styled.div`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid;
  border-color: ${(props) => (props.error ? "#EC5454" : "#0c3967")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 2.125rem 2.4375rem 2.5625rem 2.5rem;
  width: 100%;
  margin-bottom: 1.375rem;
`;

const CardTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  h5 {
    margin: 0;
  }
`;

const CardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
`;

const CardEdit = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  h5 {
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  background: var(--bluePrimary);
  border-color: var(--bluePrimary);
  box-shadow: 0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  width: 11.25rem;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.75rem;
  margin-left: 0.75rem;
  user-select: none;
  vertical-align: middle;
  border-radius: 0.25rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #007ab4;
    border-color: #007ab4;
    text-decoration: none;
  }
`;

const StyledButtonEdit = styled(Button)`
  background: #fff;
  border-color: var(--bluePrimary);
  box-shadow: 0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35);
  color: var(--bluePrimary);
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  width: 11.25rem;
  text-align: center;
  border: 1px solid;
  padding: 0.75rem;
  margin-left: 0.75rem;
  user-select: none;
  vertical-align: middle;
  border-radius: 0.25rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #007ab4;
    border-color: #007ab4;
    text-decoration: none;
  }
`;

const StyledButtonRun = styled.button`
  background: #fff;
  border-color: #08d85a;
  box-shadow: 0rem 1.25rem 1.875rem -0.75rem rgba(8, 216, 90, 0.15);
  color: #08d85a;
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  width: 11.25rem;
  text-align: center;
  border: 1px solid;
  padding: 0.75rem;
  user-select: none;
  vertical-align: middle;
  border-radius: 0.25rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, #08d85a 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #08d85a;
    border-color: #08d85a;
    text-decoration: none;
  }
  &:active {
    color: #fff;
    background-color: #08d85a;
    border-color: #08d85a;
    text-decoration: none;
  }
  &:focus {
    color: #fff;
    background-color: #08d85a;
    border-color: #08d85a;
    text-decoration: none;
  }
`;
const GridCards = styled.div`
  column-gap: 0.4375rem;
  display: grid;
  grid-template-columns: repeat(4, 16.1875rem);
  margin: 2.25rem 0 0 0;
  row-gap: 0.4375rem;
`;

export {
  ContainerCard,
  CardTitle,
  StyledButton,
  GridCards,
  CardEdit,
  StyledButtonEdit,
  StyledButtonRun,
  CardButtons
};
