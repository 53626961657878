import styled from 'styled-components';
import { ReactComponent as SvgHistory } from '../../../images/Logo_History.svg';


const HeadInfo = styled.div`
  width: 100%;
  height: auto;
  margin-top: 3.625rem;
  margin-bottom: 2.25rem;
`;

const Container = styled.div`
  width: 100%;
  display: ${(props) => (props.flex ? 'flex' : 'block')};
`;

const ContainerTitle = styled.div`
  width: 100%;
  display: ${(props) => (props.subtitle ? 'flex' : 'block')};
  align-items: ${(props) => (props.subtitle ? 'center' : 'none')};
  & h4 {
    font-weight: 700;
    color: var(--blueDark);
    font-size: 1.5rem;
    margin: ${(props) => (props.subtitle ? '0 0 0 0' : '0 0 1.5rem 0')};
  }
  & span {
    font-weight: 300;
    color: #454545;
    font-size: 1.5rem;
  }
`;
const ContainerText = styled.div`
  width: 100%;
  display: block;
  align-items: none;
  & p {
    font-weight: 400;
    color: #454545;
    font-size: 0.875rem;
  }
  & p:nth-of-type(1) {
    margin: 0 0 1.125rem 0;
  }
  & p:last-child {
    margin: 0;
  }
`;

const ContainerButton = styled.div``;

const Button = styled.div`
  width: 18.6875rem;
  height: 2.875rem;
  background: #ffffff;
  border: 0.0625rem solid #009ce7;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  color: #0c3967;
  padding-left: 3.875rem;
  padding-right: 1.5625rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  transition: color ease-in-out 150ms;
  &:hover {
    background: var(--blueDark);
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  &:hover > svg > path {
    fill: #fff;
  }
  & p{
    margin: 0;
  }
`;

const Delimit = styled.span`
  margin: 0 30px;
  font-weight: 300;
  font-size: 1.5rem;
`;

const StyledHistory = styled(SvgHistory)`
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 2.1875rem;
`;
export { HeadInfo, Container, ContainerTitle, ContainerText, ContainerButton, Button, Delimit, StyledHistory };
