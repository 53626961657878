import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import {
  Label,
  Input,
  handleCustomStyles,
  selectTheme,
  TextMessage
} from './styles';
import {
  SchemeToSelectFormat,
  SchemeFlieldsToSelectFormat
} from 'src/utils/formatting';
import { Box } from 'rebass';


/* Componente encargado de parametrizar la regla Fillfieldfn */
/*
 * @param  {} options: opciones del esquema
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeSelect: handle para cambiar la seleccion
 * @param  {} handleChangeInput: handle para el input
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const Fillfieldfn = ({
  options,
  regla,
  handleChangeSelect,
  handleChangeInput,
  error,
  mError,
  handleOnBlur
}) => {
  const { parameters } = regla;
  const optionsSchemas = SchemeToSelectFormat(options, 'esquema');
  const optionsFields = parameters?.schema?.fields
    ? SchemeFlieldsToSelectFormat(parameters.schema.fields, 'field')
    : [];
  return (
    <Row>
      <Col md={6} xs={12}>
        <Label>Esquema</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, dependentField: 'field' })}
          options={optionsSchemas}
          placeholder='Seleccione un esquema'
          theme={selectTheme}
          value={
            typeof parameters.esquema === 'undefined' ||
              parameters.esquema === ''
              ? []
              : [{ value: parameters.esquema, label: parameters.esquema }]
          }
          styles={handleCustomStyles({ error: error?.esquema })}
          isDisabled={false}
          error={error?.esquema}
          onBlur={handleOnBlur}
        />
        {error?.esquema ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Campos</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, nameNull: 'field' })}
          options={optionsFields}
          placeholder='Seleccione un campo'
          theme={selectTheme}
          value={
            typeof parameters.field === 'undefined' || parameters.field === ''
              ? []
              : [{ value: parameters.field, label: parameters.field }]
          }
          styles={handleCustomStyles({ error: error?.field })}
          isDisabled={false}
          error={error?.field}
          onBlur={handleOnBlur}
        />
        {error?.field ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Extra</Label>
        <Input
          name='extra'
          placeholder='Ingrese el valor'
          value={parameters.extra}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.extra}
          onBlur={handleOnBlur}
        />
        {error?.extra ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default Fillfieldfn;
