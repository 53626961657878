import React from 'react';
import {
  StyledContainer,
  StyledHeader,
  StyledLogoFamilia,
  ContainerLogout,
  StyledLogoLogout,
  ButtonLogout
} from './Styles';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogout } from '../../services';
import { GoogleLogout } from 'react-google-login';
import { CLIENT_ID_GOOGLE } from "../../constants/apis"

/* Header de la apliaccion, contiene el logo y el cierre de sesion del usuario */
const Header = ({ user = null }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(setLogout());
  };
  return (
    <StyledHeader>
      <StyledContainer>
        <Link to='/distribuidores'>
          <StyledLogoFamilia />
        </Link>
        <ContainerLogout>
          {user ? user.name : 'Usuario'}
          <GoogleLogout
            clientId={CLIENT_ID_GOOGLE}
            onLogoutSuccess={handleLogout}
            render={(renderProps) => (
              <ButtonLogout onClick={renderProps.onClick}>
                <StyledLogoLogout />
              </ButtonLogout>
            )}
          />
        </ContainerLogout>
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
