import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset,
  setAddDealer,
  setInputData
} from '../actions/addDealerActions';
import { postDealer, setUpdateDealer } from 'src/services';
import { getDealerAction } from 'src/actions/dealersAction';
import { setResponseModal } from 'src/actions/responseModalActions';
import { createBreadCrumb } from 'src/actions/breadCrumbActions';

const messageModal = {
  title: '¿Está seguro de que quiere abandonar esta página?',
  message:
    'Todos los cambios que no hayan sido guardados se perderán y no podrá recuperarlos.',
  buttons: [
    {
      text: 'PERMANECER EN ESTA PÁGINA',
      type: 'normal'
    },
    {
      text: 'ABANDONAR ESTA PÁGINA',
      type: 'danger'
    }
  ],
  show: false
};

export const useLogicForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getDataForm = useSelector((state) => state.getDataForm);
  const user = useSelector((state) =>
    state.dataUser ? state.dataUser.user : null
  );
  const isLoading = useSelector((state) => state.loading);
  const addDealer = useSelector((state) => state.addDealer);
  const dealers = useSelector((state) => state.dealers);
  const responseModal = useSelector((state) => state.responseModal);
  const { optionCountries, optionTypology, optionSociety } = getDataForm;
  const [disabledInput, setDisabledInput] = useState(false);
  const [error, setError] = useState({
    nameDist: 0,
    codeDist: 0,
    country: 0,
    typology: 0,
    society: 0
  });

  /* Obtener la ruta actual */
  const getPaht = useCallback(() => {
    const string = history.location.pathname;
    const path = string.slice(1, string.length);
    return {
      path
    };
  }, [history]);

  /* Cambia el estado del border de cada campo si los datos estan correctos. */
  const handleOnBlur = (key) => {
    setError((prevError) => ({ ...prevError, [key]: 0 }));
  };

  /* Obtener ruta para difinir el titulo para edidat o actualizar. */
  const getCurrentTitle = (name = 'Defina el titulo') => {
    const title = { name: name };

    if (getPaht().path === 'nuevo-distribuidor') {
      Object.assign(title, { name: 'Añadir distribuidor' });
    }

    if (getPaht().path === 'actualizar-distribuidor') {
      Object.assign(title, { name: 'Editar distribuidor' });
    }

    return title;
  };

  /* Array con las rutas para breadCrumb(miga de pan) */
  const rutas = [
    {
      id: 1,
      name: 'Inicio',
      path: '/'
    },
    {
      id: 2,
      name:
        getPaht().path === 'nuevo-distribuidor'
          ? 'Añadir distribuidor'
          : 'Editar distribuidor',
      path:
        getPaht().path === 'nuevo-distribuidor'
          ? '/nuevo-distribuidor'
          : '/actualizar-distribuidor'
    }
  ];

  /* Itera sobre cada campo que sea obligatorio y cambia el estado del border. */
  const valideInput = () => {
    const { nameDist, codeDist, country, typology, society } = addDealer;

    if (nameDist === '') {
      return setError((prevError) => ({ ...prevError, nameDist: 1 }));
    }
    if (codeDist === '') {
      return setError((prevError) => ({ ...prevError, codeDist: 1 }));
    }
    if (country === '') {
      return setError((prevError) => ({ ...prevError, country: 1 }));
    }
    if (typology === '') {
      return setError((prevError) => ({ ...prevError, typology: 1 }));
    }
    if (society === '') {
      return setError((prevError) => ({ ...prevError, society: 1 }));
    }
  };

  /* Cancela el evento por defecto del formulario */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  /* Selecionamos los datos que el usuario escriba para luego guardarlos en el Store de redux */
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'codeDist') {
      dispatch(setInputData({ name, value: value }));
    } else dispatch(setInputData({ name, value }));
  };

  /* Activa el modal  para avisar de una acción. */
  const handleAlert = () => {
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
  };

  /* Desactiva el modal para continuar en la pagina. */
  const handleContinue = () => {
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
  };

  /* Desactiva el modal para abandonar la pagina. */
  const handleCancel = () => {
    dispatch(reset());
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
    setTimeout(() => history.push('/distribuidores'), 1000);
  };

  /* Primero vadida que todo los datos obligarios esten completado
  Para luego guardar en la base de datos. */
  const handleSave = () => {
    const { nameDist, codeDist, country, typology, society } = addDealer;

    if (getPaht().path === 'actualizar-distribuidor') {
      if (nameDist && codeDist && country && typology && society) {
        setUpdateDealer({ id: codeDist, data: mapObject(addDealer) });
      }
    } else {
      valideInput();
    }

    if (getPaht().path === 'nuevo-distribuidor') {
      if (nameDist && codeDist && country && typology && society) {
        dispatch(postDealer(mapObject(addDealer)));
      } else {
        valideInput();
      }
    } else {
      dispatch(
        setUpdateDealer({ id: codeDist, data: mapObject(addDealer) })
      );
    }
  };

 /*  Mapea los datos guardados ingreados por el usuario. */
  function mapObject(object) {
    const { nameDist, codeDist, country, typology, society } = object;
    return {
      codigo_extraccion: codeDist,
      nombre: nameDist,
      pais: country,
      tipologia: typology,
      sociedad: society,
      estado: false
    };
  }

  /* Advertencia ==>> esta logica cambiara con el tiempo ya que depende de la respuesta del servidor.
  Si la respuesta del servidor fue exitoza actualizamos el estado de la tabla con el titen actualizado */
  useEffect(() => {
    if (getPaht().path === 'actualizar-distribuidor') {
      if (addDealer.response.status === 200) {
        const resulMap = dealers.map((item) => {
          if (item.codigo_extraccion === addDealer.currentId) {
            Object.assign(item, mapObject(addDealer));

            return item;
          } else {
            return item;
          }
        });

        dispatch(getDealerAction(resulMap));
        dispatch(reset());
      } else {
        if (
          addDealer.response.status > 200 &&
          addDealer.response.status < 500
        ) {
          dispatch(setAddDealer({ status: 0 }));
        }

        if (addDealer.response.status === 500) {
          dispatch(setAddDealer({ status: 0 }));
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getPaht,
    addDealer.response,
    addDealer.currentId,
    dealers,
    addDealer
  ]);

  /* Advertencia ==>> esta logica cambiara con el tiempo ya que depende de la respuesta del servidor.
  Si la respuesta del servidor fue exitoza agregamos un nuevo item a la tabla */
  useEffect(() => {
    if (getPaht().path === 'nuevo-distribuidor') {
      if (addDealer.response.status === 200) {
        dispatch(
          getDealerAction(
            dealers.concat(addDealer.response.data.items)
          )
        );
        dispatch(reset());
      } else {
        if (
          addDealer.response.status > 200 &&
          addDealer.response.status < 500
        ) {
          dispatch(setAddDealer({ status: 0 }));
        }

        if (addDealer.response.status === 500) {
          dispatch(setAddDealer({ status: 0 }));
        }
      }
    }
  }, [
    getPaht,
    dispatch,
    addDealer.response,
    dealers,
    addDealer
  ]);

  /* Reiniciando el estado del formulario cada ves que se desmontado el componente */
  useEffect(() => {
    if (getPaht().path === 'actualizar-distribuidor') {
      setDisabledInput(true);
    }
    dispatch(createBreadCrumb(rutas));

    return () => {
      if (getPaht().path === 'distribuidores') {
        dispatch(reset());
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPaht]);

  /*  */
  const onClickModal = (name) => {
    switch (name) {
      case 'PERMANECER EN ESTA PÁGINA':
        handleContinue();
        break;
      case 'ABANDONAR ESTA PÁGINA':
        handleCancel();
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (responseModal.click) {
      onClickModal(responseModal.click);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseModal]);

  /* Devolvemos todos los datos que consumiremos. */
  return {
    optionCountries,
    optionTypology,
    optionSociety,
    handleChange,
    handleAlert,
    handleSubmit,
    handleSave,
    handleOnBlur,
    error,
    addDealer,
    getTitle: getCurrentTitle(),
    getPaht,
    user,
    isLoading,
    disabledInput
  };
};
