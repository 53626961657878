import NewDistriView from '../components/NewDealerView/NewDealerView';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import { useLogicForm } from 'src/hooks/useLogicForm';
import Modal from 'src/utils/Modal/Modal';
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader';


/* Contenedor para actualizar un distribuidor */
const UpdateDealer = () => {
  const {
    optionCountries,
    optionTypology,
    optionSociety,
    handleChange,
    handleAlert,
    handleSubmit,
    handleSave,
    handleOnBlur,
    error,
    addDealer,
    getTitle,
    user,
    isLoading,
    disabledInput
  } = useLogicForm();

  return (
    <>
      <NewDistriView
        optionCountries={optionCountries}
        optionTypology={optionTypology}
        optionSociety={optionSociety}
        handleChange={handleChange}
        handleAlert={handleAlert}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        handleOnBlur={handleOnBlur}
        error={error}
        addDealer={addDealer}
        getTitle={getTitle}
        user={user}
        disabledInput={disabledInput}
      />
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
      <Modal />
    </>
  );
};

export default UpdateDealer;
