import {
  GET_DEALERS,
  SET_STATE_DEALER,
  GET_HISTORY_DEALER_HU1,
  GET_HISTORY_DEALER_HU2,
  GET_HISTORY_DEALER_HU3
} from '../constants/actionTypes';

export const getDealerAction = (payload) => ({
  type: GET_DEALERS,
  payload
});
export const setEstateDealer = (payload) => ({
  type: SET_STATE_DEALER,
  payload
});
export const getHistoryHU1 = (data) => {
  return {
    type: GET_HISTORY_DEALER_HU1,
    payload: data
  };
};
export const getHistoryHU2 = (data) => {
  return {
    type: GET_HISTORY_DEALER_HU2,
    payload: data
  };
};
export const getHistoryHU3 = (data) => {
  return {
    type: GET_HISTORY_DEALER_HU3,
    payload: data
  };
};
