import { SELECT_BREADCRUMB, CREATE_BREADCRUMB } from '../constants/actionTypes';

export const selectBreadCrumb = (payload) => {
  return {
    type: SELECT_BREADCRUMB,
    payload
  };
};

export const createBreadCrumb = (payload) => {
  return {
    type: CREATE_BREADCRUMB,
    payload
  };
};
