import Select from 'react-select';
import PropTypes from 'prop-types';
import { handleCustomStyles, selectTheme } from './Styles';

/* Componente ... */

/*
 * @param  {} option: opciones del selector
 * @param  {} handleChange: handle que cambia el estado
 * @param  {} placeholder
 * @param  {} error: booleano que valida campo vacio
 * @param  {} getValue: funcion que trae el valor seleccionado
 * @param  {} disabledInput=false
 */

export const SelectOptions = ({
  option,
  handleChange,
  placeholder,
  error,
  getValue,
  disabledInput = false
}) => {
  return (
    <Select
      onChange={handleChange}
      options={option}
      placeholder={placeholder}
      theme={selectTheme}
      value={getValue}
      styles={handleCustomStyles({ error })}
      isDisabled={disabledInput}
    />
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  option: PropTypes.array,
  placeholder: PropTypes.string
};
