import axios from 'axios';
import { setAddDealer } from '../actions/addDealerActions';
import { getPaginate, getPaginateHU2, getPaginateHU3, resetPaginate } from '../actions/paginatorActions';
import { getRulesItems, getRulesHU1, getRulesHU2, getRulesHU3, setParameterRule, resetRules } from '../actions/rulesActions';
import { getSchemeItems, resetScheme, getSchemeResponse } from '../actions/schemaActions';
import { getUserGoogle, getToken, logout } from '../actions/loginActions';
import { getDealerAction, getHistoryHU1, getHistoryHU2, getHistoryHU3 } from '../actions/dealersAction';
import { validateError } from './validateErrors';
import { validateSuccess } from './validateSuccess';
import { setLoading } from '../actions/loadingAction';
import { URL_API } from '../constants/apis';

const API_URL_CATALOGO = `${URL_API}catalogo/`;
const onlyNumbers = new RegExp('^[0-9]*$');

let TOKEN = '';
let axiosAuth;

/* Inicializa configuracion para peticiones con header Authorization */
const initAxiosAuth = () => {
  TOKEN = window.localStorage.getItem('token');
  axiosAuth = axios.create({
    withCredentials: true,
    headers: { Authorization: `Bearer ${TOKEN}` }
  });
};

/* Validar el funcionamiento -> Valida que exista el token para iniciar la configuracion del axios */
if (window.localStorage.getItem('token')) {
  initAxiosAuth();
}

/* obtiene lista de distribuidores */
export const getDealers = () => {
  return (dispatch, getState) => {
    const { paginator, sortDealer } = getState();
    dispatch(setLoading(true));
    axiosAuth
      .get(
        `${API_URL_CATALOGO}distribuidores?page=${paginator.page}&size=${paginator.size}&order=${sortDealer}`
      )
      .then(function (response) {
        const { data } = response;
        const paginate = {
          total: data.total,
          page: data.page,
          size: data.size
        };
        dispatch(getPaginate(paginate));
        dispatch(getDealerAction(data.items));
      })
      .catch((error) => {
        dispatch(validateError(error, '/distribuidores'));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* obtiene lista de distribuidores filtrada por entrada de texto */
/*
 * @param  {} id: id del distribuidor
 */
export const getDealersByID = ({ id }) => {
  return (dispatch, getState) => {
    const VALIDATE_SEARCH = onlyNumbers.test(id)
      ? `?codigo=${id}`
      : `?nombre=${id}`;
    const { paginator, sortDealer } = getState();
    dispatch(setLoading(true));
    axiosAuth
      .get(
        `${API_URL_CATALOGO}distribuidores${VALIDATE_SEARCH}&page=1&size=${paginator.size}&order=${sortDealer}`
      )
      .then(function (response) {
        const { data } = response;
        const paginate = {
          total: data.total,
          page: data.page,
          size: data.size
        };
        dispatch(getPaginate(paginate));
        dispatch(getDealerAction(data.items));
      })
      .catch(function (error) {
        dispatch(validateError(error, '/distribuidores'));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Actualiza un distribuidor */
/*
 * @param  {} dealer: distribuidor
 * @param  {} search: busqueda
 * @param  {} valueSearch: valor de busqueda
 */
export const putDealer = ({ dealer, search, valueSearch }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .put(
        `${API_URL_CATALOGO}distribuidores/${dealer.pais}/${dealer.codigo_extraccion}`,
        {
          codigo_extraccion: dealer.codigo_extraccion,
          nombre: dealer.nombre,
          pais: dealer.pais,
          tipologia: dealer.tipologia,
          sociedad: dealer.sociedad,
          estado: dealer.estado
        }
      )
      .then(function (response) {
        if (search) {
          dispatch(getDealersByID(valueSearch));
        } else {
          dispatch(getDealers());
        }
        dispatch(validateSuccess(response, '/distribuidores'));
      })
      .catch(function (error) {
        dispatch(validateError(error, '/distribuidores'));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Crea un nuevo distribuidor */
/*
 * @param  {} data: contiene la data para crear un distribuidor
 */
export const postDealer = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${API_URL_CATALOGO}distribuidores`, { ...data, estado: false })
      .then(function (response) {
        dispatch(validateSuccess(response, '/distribuidores'));
        dispatch(setAddDealer(response));
      })

      .catch(function (error) {
        dispatch(validateError(error, '/distribuidores'));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Actualiza un distribuidor */
/*
 * @param  {} id: id del distribuidor
 * @param  {} data: data para actualizar el distribuidor
 */
export const setUpdateDealer = ({ id, data }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .put(`${API_URL_CATALOGO}distribuidores/${data.pais}/${id}`, data)
      .then(function (response) {
        dispatch(validateSuccess(response, '/distribuidores'));
        dispatch(setAddDealer(response));
      })

      .catch(function (error) {
        dispatch(validateError(error, '/distribuidores'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Lista actualizaciones del distribuidor por historia de usuario */
/*
 * @param  {} id: id del distribuidor
 * @param  {} country: pais del distribuidor
 */
export const getHistoryDealersHU1 = ({ id, country }) => {
  return (dispatch, getState) => {
    const { paginator } = getState();
    dispatch(setLoading(true));
    axiosAuth
      .get(
        `${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/historico/1?page=${paginator.page}&size=${paginator.size}`
      )
      .then(function (response) {
        const { data } = response;
        const paginate = {
          total: data.total,
          page: data.page,
          size: data.size
        };
        dispatch(getPaginate(paginate));
        dispatch(getHistoryHU1(data.items));
      })

      .catch(function (error) {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Lista actualizaciones del distribuidor por historia de usuario hu2*/
/*
 * @param  {} id
 * @param  {} country: pais del distribuidor
 */
export const getHistoryDealersHU2 = ({ id, country }) => {
  return (dispatch, getState) => {
    const { paginatorHU } = getState();
    dispatch(setLoading(true));
    axiosAuth
      .get(
        `${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/historico/2?page=${paginatorHU.paginatorHU2.page}&size=${paginatorHU.paginatorHU2.size}`
      )
      .then(function (response) {
        const { data } = response;
        const paginate = {
          total: data.total,
          page: data.page,
          size: data.size
        };
        dispatch(getPaginateHU2(paginate));
        dispatch(getHistoryHU2(data.items));
      })

      .catch(function (error) {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Lista actualizaciones del distribuidor por historia de usuario hu3*/
/*
 * @param  {} id
 * @param  {} country: pais del distribuidor
 */
export const getHistoryDealersHU3 = ({ id, country }) => {
  return (dispatch, getState) => {
    const { paginatorHU } = getState();
    dispatch(setLoading(true));
    axiosAuth
      .get(
        `${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/historico/3?page=${paginatorHU.paginatorHU3.page}&size=${paginatorHU.paginatorHU3.size}`
      )
      .then(function (response) {
        const { data } = response;
        const paginate = {
          total: data.total,
          page: data.page,
          size: data.size
        };
        dispatch(getPaginateHU3(paginate));
        dispatch(getHistoryHU3(data.items));
      })

      .catch(function (error) {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Obtiene las reglas */
export const getRules = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${API_URL_CATALOGO}reglas`)
      .then(function (response) {
        dispatch(getRulesItems(response));
      })
      .catch((error) => {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Busca las reglas de una historia de usuario para un distribuidor hu1 */
export const getRulesByDealerHU1 = ({ id, country }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/1`)
      .then(function (response) {
        const { data } = response;
        dispatch(getRulesHU1(data.items));
      })
      .catch((error) => {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Busca las reglas de una historia de usuario para un distribuidor hu2 */
export const getRulesByDealerHU2 = ({ id, country }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/2`)
      .then(function (response) {
        const { data } = response;
        dispatch(getRulesHU2(data.items));
      })
      .catch((error) => {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Busca las reglas de una historia de usuario para un distribuidor hu3 */
export const getRulesByDealerHU3 = ({ id, country }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/3`)
      .then(function (response) {
        const { data } = response;
        dispatch(getRulesHU3(data.items));
      })
      .catch((error) => {
        dispatch(validateError(error));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Ejectua las reglas del historico HU1 */
/*
 * @param  {} {data}: data es un objeto que contiene "dealer_name": "{pais}-{sociedad}-{tipologia}-{codigo_extraccion}"
 */
export const postRuleExecutionHU1 = ({ data }) => {
  return (dispatch) => {
    axiosAuth
      .post(`${URL_API}procesamiento/hu-1/bundle`, {
        dealer_name: data
      })
      .then(function (response) {
        dispatch(validateSuccess(response))
      })
      .catch((error) => {
        dispatch(validateError(error))
      })
  }
}

/* Ejectua las reglas del historico HU2 */
/*
 * @param  {} {data}: data es un objeto que contiene "dealer_name": "{pais}-{sociedad}-{tipologia}-{codigo_extraccion}"
 */
export const postRuleExecutionHU2 = ({ data }) => {
  return (dispatch) => {
    axiosAuth
      .post(`${URL_API}procesamiento/hu-2`, {
        dealer_name: data
      })
      .then(function (response) {
        dispatch(validateSuccess(response))
      })
      .catch((error) => {
        dispatch(validateError(error))
      })

  }
}

/* Ejectua las reglas del historico HU2 */
/*
 * @param  {} {data}: data es un objeto que contiene "dealer_name": "{pais}-{sociedad}-{tipologia}-{codigo_extraccion}"
 */
export const postRuleExecutionHU3 = ({ data }) => {
  return (dispatch) => {
    axiosAuth
    .post(`${URL_API}procesamiento/hu-3`, {
      dealer_name: data
    })
    .then(function (response) {
      dispatch(validateSuccess(response))
    })
    .catch((error) => {
      dispatch(validateError(error))
    })
  }
}

/* Guarda reglas de un distribuidor */
/*
 * @param  {} id
 * @param  {} hu: historia de usuario
 * @param  {} data: la data del distribuidor
 * @param  {} country: pais del distribuidor
 */
export const postRulesByDealer = ({ id, hu, data, country }) => {
  const paramHU = hu.split('');
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${API_URL_CATALOGO}distribuidores/${country}/${id}/reglas/${paramHU[2]}`, {
        rules: [...data]
      })
      .then(function (response) {
        const { data } = response;
        dispatch(validateSuccess(response, `/distribuidor/${id}`));
        dispatch(getRulesHU1(data.items)); // esta parte toca hablarla con dav
        dispatch(resetRules());
      })
      .catch((error) => {
        dispatch(validateError(error));
      })
      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Obtiene los esquemas */
/*
 * @param  {} {all}=false: trae todos los esquemas
 * @param  {} {all}=true: trae lo que tenga configurado el paginador
 */
export const getScheme = ({ all } = false) => {
  return (dispatch, getState) => {
    let paginator = {};
    if (all) {
      paginator = {
        page: 1,
        size: 100
      };
    } else {
      paginator = getState().paginator;
    }
    dispatch(setLoading(true));
    axiosAuth
      .get(
        `${URL_API}esquemas/tablas?page=${paginator.page}&size=${paginator.size}`
      )
      .then(function (response) {
        const { data } = response;
        const paginate = {
          total: data.total,
          page: data.page,
          size: data.size
        };
        if (all) {
          dispatch(getPaginate());
        } else {
          dispatch(getPaginate(paginate));
        }

        dispatch(getSchemeItems(data.items));
      })

      .catch(function (error) {
        dispatch(validateError(error, '/esquemas'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};


/* Lista un esquema por nombre */
/*
 * @param  {} scheme: recibe el esquema
 */
export const getSchemeByName = (scheme) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}esquemas/tablas/${scheme}`)
      .then(function (response) {
        const { data } = response;
        if (data.items.length > 0) {
          dispatch(getSchemeResponse(data.items[0]));
        } else {
          dispatch(resetScheme());
        }
      })

      .catch(function (error) {
        dispatch(validateError(error, '/esquemas'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Lista un esquema por nombre */
/*
 * @param  {} name: nombre del campo
 * @param  {} value: nombre del esquema
 * @param  {} id: id del campo
 */
export const getSchemeByNameParams = ({ name, value, id }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .get(`${URL_API}esquemas/tablas/${value}?page=1&size=50`)
      .then(function (response) {
        const { data } = response;
        if (data.items.length > 0) {
          dispatch(
            setParameterRule({
              name: 'schema',
              value: { fields: data.items[0].fields },
              id
            })
          );
          dispatch(setParameterRule({ name, value, id }));
        }
      })

      .catch(function (error) {
        dispatch(validateError(error, '/esquemas'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};

/* Crea un nuevo esquema */
/*
 * @param  {} scheme: recibe el esquema
 */
export const postScheme = (scheme) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .post(`${URL_API}esquemas/tablas`, scheme)
      .then(function (response) {
        // const { data } = response;
        dispatch(validateSuccess(response, '/esquemas'));
        dispatch(resetScheme());
      })

      .catch(function (error) {
        dispatch(validateError(error, '/esquemas'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};


/* Actualiza Esquema */
/*
 * @param  {} name: nombre del esquema
 * @param  {} scheme: esquema
 */
export const putScheme = (name, scheme) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .put(`${URL_API}esquemas/tablas/${name}`, scheme)
      .then(function (response) {
        // const { data } = response;
        dispatch(validateSuccess(response, '/esquemas'));
        dispatch(resetScheme());
      })

      .catch(function (error) {
        dispatch(validateError(error, '/esquemas'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};


/* Elimina Esquema */
/*
 * @param  {} name: nombre del esquema a eliminar
 */
export const deleteScheme = ({ name }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    axiosAuth
      .delete(`${URL_API}esquemas/tablas/${name}`)
      .then(function (response) {
        dispatch(validateSuccess(response, '/esquemas'));
        dispatch(resetPaginate());
        dispatch(getScheme());
      })

      .catch(function (error) {
        dispatch(validateError(error, '/esquemas'));
      })

      .finally(function () {
        dispatch(setLoading(false));
        // always executed
      });
  };
};


/* Valida el usario de google */
export const validateLogin = (bodyData, userData) => {
  return (dispatch) => {
    axios
      .post(`${URL_API}auth`, bodyData, { withCredentials: true })
      .then(function (response) {
        const { data } = response;
        dispatch(getUserGoogle(userData));
        dispatch(getToken(data.access_token));
        initAxiosAuth();
      })
      .catch(function (error) {
        // handle error
        dispatch(validateError(error));
      })
      .then(function () {
        // always executed
      });
  };
};
export const setLogout = (bodyData, userData) => {
  return (dispatch) => {
    axios
      .post(`${URL_API}logout`, { withCredentials: true })
      .then(function (response) {
        dispatch(logout());
        /* history.push("/") */
      })
      .catch(function (error) {
        // handle error
        dispatch(validateError(error));
      })
      .then(function () {
        // always executed
      });
  };
};
