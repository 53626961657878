import { GET_PAGINATE_HU2, GET_PAGINATE_HU3 } from '../constants/actionTypes';

const initialState = {
  paginatorHU2: {
    size: 10,
    page: 0,
    total: 0
  },
  paginatorHU3: {
    size: 10,
    page: 0,
    total: 0
  }
};
export const paginatorHuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PAGINATE_HU2: {
      return { ...state, paginatorHU2: { ...state.paginatorHU2, ...Object.assign({}, payload) } };
    }
    case GET_PAGINATE_HU3: {
      return { ...state, paginatorHU3: { ...state.paginatorHU3, ...Object.assign({}, payload) } };
    }

    default:
      return state;
  }
};
