import { SELECT_BREADCRUMB, CREATE_BREADCRUMB } from '../constants/actionTypes';

const initialState = {
  breadCrumb: []
};

export const breadcrumbReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SELECT_BREADCRUMB:
      return {
        ...state,
        payload
      };
    case CREATE_BREADCRUMB:
      return {
        breadCrumb: payload
      };
    default:
      return state;
  }
};
