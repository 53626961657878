import styled from 'styled-components';

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8AD;
`;
const Div = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
`;
export { LoaderContainer, Div };