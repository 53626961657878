import { useSelector, useDispatch } from 'react-redux';
import { resetResponseModal, clickResponseModal } from '../actions/responseModalActions';


const useModal = () => {
  const dispatch = useDispatch();
  const responseModal = useSelector((state) => state.responseModal);

  /* Cierra el modal */
  const handleClose = () => {
    dispatch(resetResponseModal());
  };
  
  /* Accion que permite cerrar el modal */
  const modalOnClick = ({ buttonName, fnClose }) => {
    if (fnClose) {
      return handleClose();
    }
    dispatch(clickResponseModal(buttonName));
  };

  return {
    handleClose,
    responseModal,
    modalOnClick
  };
};

export default useModal;
