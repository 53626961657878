import {
  GET_RULES_ITEMS,
  GET_SELECT_DEALER,
  GET_SELECT_CHECKED,
  GET_RULES_DEALER_HU1,
  GET_RULES_DEALER_HU2,
  GET_RULES_DEALER_HU3,
  SET_RULES_ORDER,
  SET_PARAMETER_RULE,
  RESET_RULES,
  LOAD_RULES_EDIT
} from '../constants/actionTypes'

export const getRulesItems = (data) => {
  return {
    type: GET_RULES_ITEMS,
    payload: data
  };
};

export const getRulesHU1 = (data) => {
  return {
    type: GET_RULES_DEALER_HU1,
    payload: data
  };
};

export const getRulesHU2 = (data) => {
  return {
    type: GET_RULES_DEALER_HU2,
    payload: data
  };
};

export const getRulesHU3 = (data) => {
  return {
    type: GET_RULES_DEALER_HU3,
    payload: data
  };
};

export const getSelectDealer = (data) => {
  return {
    type: GET_SELECT_DEALER,
    payload: data
  };
};

export const getSelectChecked = (data) => {
  return {
    type: GET_SELECT_CHECKED,
    payload: data
  };
};
export const setRulesOrder = (payload) => ({ type: SET_RULES_ORDER, payload });
export const setParameterRule = (payload) => ({
  type: SET_PARAMETER_RULE,
  payload
})
export const resetRules = (payload) => ({ type: RESET_RULES, payload })
export const loadRulesEdit = (payload) => ({type: LOAD_RULES_EDIT, payload})
