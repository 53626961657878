import React from 'react';
import SchemeElement from '../SchemeElement/SchemeElement';
import EmptySchemeElement from '../EmptySchemeElement/EmptySchemeElement';
import { Table } from './Styles';

/* Componente padre que contiene los esquemas */

/*
 * @param  {} scheme: Contiene el estado con los esquemas
 * @param  {} handleModal: handle que contiene el modal
 */
const TableScheme = ({ scheme, handleModal }) => {
  return (
    <Table>
      <tbody>
        {scheme.length > 0 ? (
          scheme.map((element) => (
            <SchemeElement
              key={element.schema_name}
              name={element.schema_name}
              handleModal={handleModal}
            />
          ))
        ) : (
          <EmptySchemeElement text='No se encontraron elementos' colspan={3} />
        )}
      </tbody>
    </Table>
  );
};

export default TableScheme;
