import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Counter from '../Counter/Counter';
import { Background, ModalWrapper, HeadModal, SdIoClose, ContainerButtons, Button } from './Styles';

/* Componente padre del contador */
const ModalCounter = ({
  handleClose,
  handleContinue,
  handleCancel,
  btCancel,
  btContinue,
  title
}) => {
  const [count, setCount] = useState(1);
  const handleAdd = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleMinus = () => {
    setCount((prevCount) => (prevCount > 1) ? prevCount - 1 : 1);
  };
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper>
        <HeadModal>
          <h2>{title}</h2>
          <SdIoClose onClick={handleClose} />
        </HeadModal>
        <Counter count={count} handleAdd={handleAdd} handleMinus={handleMinus} />
        <ContainerButtons>
          <Button type='normal' onClick={handleCancel}>
            {' '}
            {btCancel}{' '}
          </Button>
          <Button type='danger' onClick={() => handleContinue(count)}>
            {' '}
            {btContinue}{' '}
          </Button>

        </ContainerButtons>
      </ModalWrapper>
    </Background>,
    document.getElementById('root')
  );
};
export default ModalCounter;
