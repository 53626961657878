import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Head from '../components/Distributor/Head/Head';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import TableScheme from '../components/Schemas/TableScheme/TableScheme';
import Paginator from '../components/Paginator/Paginator';
import Modal from 'src/utils/Modal/Modal';
import useSchemas from '../hooks/useSchemas';
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader';


/* Contenedor de la lista de esquemas */
const Schemas = () => {
  const {
    user,
    hasButton,
    handleModal,
    schemas,
    paginate,
    changePaginate,
    isLoading
  } = useSchemas();
  return (
    <>
      <Header user={user} />
      <Container className='p-0'>
        <Head title='Esquema de tablas' subtitle='' button={hasButton} />
        <TableScheme scheme={schemas} handleModal={handleModal} />
        <Row className='justify-content-center'>
          <Col md={8}>
            <Paginator paginate={paginate} changePaginate={changePaginate} />
          </Col>
        </Row>
      </Container>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
      <Modal />
    </>
  );
};

export default Schemas;
