import React from 'react';
import ElementTable from '../ElementTable/ElementTable';
import SelectFilter from '../SelectFilter/SelectFilter';
import EmptyTable from '../EmptyTable/EmptyTable';
import { createDateAsUTCFromString } from '../../../../utils/formatting';
import { Table, Thead, Tbody } from './Styles';


/* Componente que contiene el filtro y la lista de distribuidores  */

/*
 * @param  {} dealerList: Lista de distribuidores
 * @param  {} handleChangeStatus: handle para el cambio de estado
 * @param  {} configEdit
 * @param  {} setOrderTable: funcion que envia el dispatch de setSortDealer al estado
 * @param  {} sortDealer: contiene el estado del filtro de codigo o nombre
 */
const Tables = ({
  dealerList,
  handleChangeStatus,
  configEdit,
  setOrderTable,
  sortDealer
}) => {
  return (
    <>
      <Table>
        <Thead>
          <tr>
            <th colSpan='3'>
              <label>Ordernar por:</label>
              <SelectFilter
                setOrderTable={setOrderTable}
                sortDealer={sortDealer}
              />
            </th>
          </tr>
        </Thead>
        <Tbody>
          {dealerList.length > 0 ? (
            dealerList.map((dealer) => (
              <ElementTable
                key={`${dealer.codigo_extraccion}-${dealer.pais}`}
                id={dealer.codigo_extraccion}
                nameDistribuidor={`${dealer.codigo_extraccion} - ${dealer.nombre}`}
                lastModification={`Última modificación: ${createDateAsUTCFromString(
                  dealer.created_at
                ).toLocaleDateString()}`}
                status={dealer.estado}
                country={dealer.pais}
                handleChangeStatus={handleChangeStatus}
                configEdit={configEdit}
              />
            ))
          ) : (
            <EmptyTable text='No se encontraron resultados' colspan={3} />
          )}
        </Tbody>
      </Table>
    </>
  );
};

export default Tables;
