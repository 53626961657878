import styled from 'styled-components'

const Select = styled.select`
  appearance: none;
  background: #f2f6f9;
  border-radius: 0.3125rem;
  border: 0;
  color: #454545;
  font-size: 0.75rem;
  font-weight: 400;
  height: 2.25rem;
  margin: 0 6.0625rem 0 1.23rem;
  padding: 0.625rem 0.875rem;
  width: 10.8125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
  &:focus {
    outline: none;
  }
  & option {
    color: #454545;
    background-color: #f2f6f9;
  }
`

export { Select }