import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const Title = styled.p`
  color: #454545;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0.3125rem 0;
`;
/* const SubTitle = styled.p`
  margin: 0.3125rem 0;
  color: #8f8f8f;
  font-size: 0.75rem;
  font-style: italic;
` */
const Description = styled.p`
  color: #454545;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.5625rem;
`;
/* const Rule = styled.div`
  width: 100%;
  display: flex;
` */
const Input = styled(Form.Control)`
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  width: 100%;
  padding: 0.3125rem 0.4375rem;
  font-style: italic;
  font-family: roboto;
  font-size: 0.75rem;
  &::placeholder {
    color: #8f8f8f;
  }
  &:focus {
    border: 1px solid #c4c4c4;
    box-shadow: none;
  }
`;
const Label = styled(Form.Label)`
  color: #454545;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.5625rem;
`;

export { Title, Description, Input, Label };