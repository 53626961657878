import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateLogin } from '../services';
import { setResponse } from '../actions/responseActions';

const useLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) =>
    state.dataUser ? state.dataUser.token : null
  );

  /* Iniciar sesion */
  const handleLoginGoolge = (response) => {
    // Este proceso de destructuracion es caseSensitive
    // eslint-disable-next-line
    const { credential , clientId , select_by } =  response;
    // El access_token se pone vacio ya que no es necesario entregarle un valor
    // debido a que el backend sólo me exige el token_id y el access_token me lo permite vacio
    // luego el backend, me entrega un access_token para que el dllo me redireccione a los fronts
    // y poder usar las apis.
    const dataBody = {
      access_token: "",
      id_token: credential
    };
        // El nombre anterior de select_by era profileObj, como este tampoco exige un valor especifico
    // se le entrega select_by de manera indiferente.
    dispatch(validateLogin(dataBody, select_by));
  };

  /* Error al iniciar sesion */
  const handleLoginGoolgeFail = (response) => {
    console.error(response);
    dispatch(
      setResponse({
        status: 404,
        message: 'Ocurrio un problema con la autenticación de Google',
        show: true,
        redirect: '/login'
      })
    );
  };

  /* valida si el token existe al cargar el componente */
  useEffect(() => {
    if (token) {
      history.push('/');
    }
  }, [token, history]);

  return { handleLoginGoolge, handleLoginGoolgeFail };
};

export default useLogin;
