import React from 'react';
import LoginGoogle from '../components/Login/LoginGoogle/LoginGoogle';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import useLogin from '../hooks/useLogin';

/* Pantalla de login */
const Login = () => {
  const { handleLoginGoolge, handleLoginGoolgeFail } = useLogin();
  return (
    <>
      <LoginGoogle
        handleLoginGoolge={handleLoginGoolge}
        handleLoginGoolgeFail={handleLoginGoolgeFail}
      />
      <ModalApprove />
    </>
  );
};

export default Login;
