import {
  GET_LOGIN_GOOGLE, GET_TOKEN, SET_LOGOUT
} from '../constants/actionTypes';

export const getUserGoogle = (payload) => {
  return {
    type: GET_LOGIN_GOOGLE,
    payload
  };
};

export const getToken = (payload) => {
  return {
    type: GET_TOKEN,
    payload
  };
};
export const logout = (payload) => (
  {
    type: SET_LOGOUT,
    payload
  }
);
