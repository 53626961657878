import {
  SET_RESPONSE_MODAL,
  RESET_RESPONSE_MODAL,
  CLICK_RESPONSE_MODAL
} from '../constants/actionTypes';
const initial = {
  title: 'Modal',
  message: '',
  buttons: [],
  show: false,
  click: null
};
export const responseModalReducer = (state = initial, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_RESPONSE_MODAL:
      return { ...Object.assign({}, state, { ...payload }) };
    case RESET_RESPONSE_MODAL:
      return { ...Object.assign({}, state, { ...initial }) };
    case CLICK_RESPONSE_MODAL:
      return { ...Object.assign({}, state), click: payload };
    default:
      return state;
  }
};
