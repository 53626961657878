import React from 'react';
import PropTypes from 'prop-types';
import { Td } from './Styles';


/* Componente que muestra cuando no hay esquemas creados */
const EmptySchemeElement = ({ text, colspan }) => {
  return (
    <tr>
      <Td colSpan={colspan}>{text}</Td>
    </tr>
  );
};

EmptySchemeElement.propTypes = {
  text: PropTypes.string,
  colspan: PropTypes.number
};

export default EmptySchemeElement;
