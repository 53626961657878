import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const SwitchForm = styled(Form)`
  margin: 0 7px;
`;
const ForCheck = styled(Form.Check)`
  padding-left: 2.7rem;
  & label {
    ::before {
      background-color: var(--redPrimary) !important;
      box-shadow: 0px 2px 10px rgba(205, 98, 98, 0.35) !important;
      border: none;
    }
    ::after {
      background-color: #fff !important;
    }
  }
  & input:checked ~ label::before {
    background-color: var(--greenPrimary) !important;
    box-shadow: 0px 2px 10px rgba(64, 158, 73, 0.35) !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  & input {
    width:100%;
    z-index: 20;
    cursor: pointer;
  }
`;

export { SwitchForm, ForCheck };