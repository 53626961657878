import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { StyledBreadCrumb } from './Styles';


/* Componente miga de pan para la navegacion */

/*
 * @param  {} routes: recibe las rutas para la navegacion
*/
const BreadCrumb = ({ routes }) => {
  return (
    <StyledBreadCrumb>
      {
        routes.map((route, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              active={routes.length === index + 1}
              linkProps={{ to: route.path }}
              linkAs={Link}
            >
              {route.name}
            </Breadcrumb.Item>
          );
        })
      }
    </StyledBreadCrumb>
  );
};

BreadCrumb.propTypes = {
  route: PropTypes.array
};

export default BreadCrumb;
