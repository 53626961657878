import styled from 'styled-components';

const ContainerCuonter = styled.div`
  display: flex;
  margin: 2.5rem auto;
  align-items: center;
  justify-content: center;
`;
const Button = styled.button`
  background: var(--blueDark);
  color: #fff;
  margin: 0 0.625rem;
  border-radius: 50%;
  border: 0.0625rem solid;
  box-shadow: none;
  line-height: 0;
  padding: 0.625rem;
`;
const Count = styled.h3`
  font-size: 4.375rem;
  margin: 0.3125rem 0.625rem;
`;

export { ContainerCuonter, Button, Count };