import styled from 'styled-components';

const Background = styled.div`
  background: #ffffffc7;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 8;
  top: 0;
`;
const ModalWrapper = styled.div`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  width: 37.5rem;
  height: 16.4544rem;
  margin-top: 11.3125rem;
  padding: 6.0625rem 5.3125rem 5.9375rem 5.3125rem;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    line-height: 1.6875rem;
    font-weight: 700;
    font-size: 1.5rem;
    color: #454545;
    margin-bottom: 0;
    margin-left: 2.375rem;
  }

  & svg {
    width: 100px;
    height: 85px;
  }
`;
export { Background, ModalWrapper };
