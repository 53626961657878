import { setResponseModal } from 'src/actions/responseModalActions';
import { logout } from '../actions/loginActions';
import { setResponse } from '../actions/responseActions';

/* Valida los diferentes tipos de errores que arroja el Back y devuelve un mensaje personalizado */
export const validateError =
  (error, rute = '') =>
    (dispatch) => {
      const show = true;
      console.error(error);
      if (!error.response) {
        dispatch(
          setResponse({
            status: 500,
            message: 'Ocurrió un error inesperado...',
            show,
            redirect: '/distribuidores'
          })
        );
        return;
      }
      const { status, data } = error.response;
      switch (status) {
        case 401: {
          dispatch(
            setResponse({
              status: 401,
              message: data.msg
                ? data.msg
                : 'La sesion a expirado, intente ingresar nuevamente',
              show,
              redirect: ''
            })
          );
          setTimeout(() => {
            dispatch(logout());
          }, 3000);
          break;
        }
        case 400: {
          if (
            data.msg ===
            'El distribuidor no cuenta con reglas en todas sus Historias de Usuario. No sera activado por seguridad.'
          ) {
            dispatch(
              setResponseModal({
                title: 'No es posible activar al distribuidor',
                message:
                  'El distribuidor no cuenta con reglas válidas para la ejecución. Asegúrese de asignar, al menos, una regla por historia de usuario para poder activarlo.',
                show: true,
                buttons: [
                  {
                    text: 'ACEPTAR',
                    type: 'primary',
                    close: true
                  }
                ]
              })
            );
            break;
          }
          dispatch(
            setResponse({
              status: 400,
              message: data.msg
                ? data.msg
                : 'El distribuidor con código de extacción ingresado ya existe',
              show,
              redirect: ''
            })
          );
          break;
        }
        case 404: {
          dispatch(
            setResponse({
              status: 404,
              message: data.detail
                ? data.detail
                : 'El recurso que solicitó no se encuentra disponible',
              show,
              redirect: rute
            })
          );
          break;
        }
        case 422: {
          dispatch(
            setResponse({
              status: 422,
              message: data.msg
                ? data.msg
                : 'Error 422',
              show,
              redirect: rute
            })
          );
          break;
        }
        case 500: {
          dispatch(
            setResponse({
              status: 500,
              message: data.detail
                ? data.detail
                : 'Ocurrió un error en servidor, vuelva intentarlo mas tarde.',
              show,
              redirect: '/distribuidores'
            })
          );
          break;
        }
        default:
          break;
      }
    };
