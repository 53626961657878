import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Label, Input, handleCustomStyles, selectTheme, TextMessage } from './styles';
import { SchemeToSelectFormat, SchemeFlieldsToSelectFormat } from 'src/utils/formatting';
import { Box } from 'rebass';


/* Componente encargado de parametrizar la regla Storage */
/*
 * @param  {} options: opciones del esquema
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeSelect: handle para cambiar la seleccion
 * @param  {} handleChangeInput: handle para el input
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const Storage = ({
  options,
  regla,
  handleChangeSelect,
  handleChangeInput,
  error,
  mError,
  handleOnBlur
}) => {
  const { parameters } = regla;
  const optionsSchemas = SchemeToSelectFormat(options, 'esquema');
  const optionsCampos = parameters?.schema?.fields
    ? SchemeFlieldsToSelectFormat(parameters.schema.fields, 'campos')
    : [];
  let valuesMulti = [];
  if (typeof parameters.campos !== 'undefined' || parameters.campos !== '') {
    valuesMulti = parameters.campos;
  }
  return (
    <Row>
      <Col md={6} xs={12}>
        <Label>Esquema</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, dependentField: 'campos' })}
          options={optionsSchemas}
          placeholder='Seleccione un esquema'
          theme={selectTheme}
          onBlur={handleOnBlur}
          value={
            typeof parameters.esquema === 'undefined' ||
              parameters.esquema === ''
              ? []
              : [{ value: parameters.esquema, label: parameters.esquema }]
          }
          styles={handleCustomStyles({ error: error?.esquema })}
          isDisabled={false}
          error={error?.esquema}
        />
        {error?.esquema ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Headers</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, nameNull: 'campos' })}
          onBlur={handleOnBlur}
          options={optionsCampos}
          placeholder='Seleccione Headers'
          theme={selectTheme}
          value={valuesMulti}
          styles={handleCustomStyles({ error: error?.campos })}
          isDisabled={false}
          isMulti
          error={error?.campos}
        />
        {error?.campos ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Ruta del archivo</Label>
        <Label required bold>*</Label>
        <Input
          name='file_path_name'
          placeholder='Ingrese el valor'
          value={parameters.file_path_name}
          onBlur={handleOnBlur}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.file_path_name}
        />
        {error?.file_path_name ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default Storage;
