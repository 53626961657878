import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
export const Input = styled(Form.Control)`
  background: #ffffff;
  border-radius: 5px;
  border: ${({ error }) =>
    error === true ? '1px solid#ec5454' : '1px solid #c4c4c4'};
  box-sizing: border-box;
  width: 100%;
  padding: 1.0625rem 0.4375rem;
  font-style: italic;
  font-family: roboto;
  font-size: 0.75rem;
  &::placeholder {
    color: #8f8f8f;
  }
  &:focus {
    border: 1px solid #c4c4c4;
    box-shadow: none;
  }
`;
export const InputDate = styled(DatePicker)`
  background: #ffffff;
  border-radius: 5px;
  border: ${({ error }) =>
    error === true ? '1px solid#ec5454' : '1px solid #c4c4c4'};
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.4375rem;
  font-style: italic;
  color: #495057;
  font-family: roboto;
  font-size: 0.75rem;
  &::placeholder {
    color: #8f8f8f;
  }
  &:focus {
    border: 1px solid #c4c4c4;
    box-shadow: none;
  }
`;
export const Label = styled(Form.Label)`
  color: ${(props) => props.required ? '#EC5454' : '#454545'};
  font-size: 0.75rem;
  font-weight: ${(props) => props.bold ? '500' : '300'};
  margin-top: 0.5625rem;
  margin-right: 0.1625rem;
`;
export const TextMessage = styled.span`
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 600;
  color: #ec5454;
`;

export const selectTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '',
      primary: '#0c3967'
    }
  };
};

export const handleCustomStyles = (config) => {
  return {
    option: (style, state) => {
      return {
        ...style,
        padding: '9px',
        fontFamily: 'Roboto',
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
      };
    },

    control: (style, state) => {
      return {
        ...style,
        fontFamily: 'Roboto',
        backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
        minHeight: '30px',
        boxShadow: 'none',
        borderColor: config.error ? '#ec5454' : '#ced4da',
        ':active': {
          boxShadow: 'none'
        },
        ':hover': {
          borderColor: config.error === 1 ? '#ec5454' : '#ced4da'
        }
      };
    },

    container: (base, state) => {
      return {
        ...base
      };
    },

    menu: (style, state) => {
      return {
        ...style,
        fontFamily: 'Roboto',
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: `1px solid ${'#000'}`,
        fontSize: '0.75rem'
      };
    },

    placeholder: (styles) => {
      return {
        ...styles,
        fontFamily: 'Roboto',
        fontSize: '0.75rem',
        color: '#8f8f8f',
        fontStyle: 'italic'
      };
    },
    singleValue: (provided, state) => {
      const color = state.isDisabled ? '#495057' : '#495057';
      return { ...provided, color, fontSize: '0.75rem', fontStyle: 'italic' };
    }
  };
};
