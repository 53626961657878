export const selectTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '',
      primary: '#0c3967'
    }
  };
};

export const handleCustomStyles = (config) => {
  return {
    option: (style, state) => {
      return {
        ...style,
        padding: '9px',
        fontFamily: 'Roboto',
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
      };
    },

    control: (style, state) => {
      return {
        ...style,
        fontFamily: 'Roboto',
        backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
        height: '46px',
        boxShadow: 'none',
        borderColor: config.error ? '#ec5454' : '#ced4da',
        ':active': {
          boxShadow: 'none'
        },
        ':hover': {
          borderColor: config.error === 1 ? '#ec5454' : '#ced4da'
        }
      };
    },

    container: (base, state) => {
      return {
        ...base
      };
    },

    menu: (style, state) => {
      return {
        ...style,
        fontFamily: 'Roboto',
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: `1px solid ${'#000'}`
      };
    },

    placeholder: (styles) => {
      return {
        ...styles,
        fontFamily: 'Roboto',
        fontSize: '13.5px',
        color: 'rgba(0, 0, 0, 0.3)'
      };
    },
    singleValue: (provided, state) => {
      const color = state.isDisabled ? '#495057' : '#495057';
      return { ...provided, color };
    }
  };
};
