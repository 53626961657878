import {
  SET_INPUT_DATA,
  SET_INPUT_SELECT,
  SET_ADD_DEALER,
  RESET,
  GET_INPUT_DATA,
  GET_CURRENT_ID,
  SET_UPDATE_DEALER
} from '../constants/actionTypes';

const initialState = {
  nameDist: '',
  codeDist: '',
  country: '',
  typology: '',
  society: '',
  currentId: 0,
  response: { status: 0 }
};

export const addDealerReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_INPUT_DATA:
      return {
        ...state,
        [payload.name]: payload.value
      };

    case SET_INPUT_SELECT:
      return {
        ...state,
        [payload.name]: payload.value
      };

    case SET_ADD_DEALER:
      return {
        ...state,
        ...Object.assign({}, { response: { ...state.response, ...payload } })
      };

    case RESET:
      return {
        ...initialState
      };

    case GET_INPUT_DATA:
      return {
        ...state,
        ...Object.assign({}, payload.data)
      };

    case GET_CURRENT_ID:
      return {
        ...state,
        ...Object.assign({}, { currentId: payload.id })
      };

    case SET_UPDATE_DEALER:
      return {
        ...state,
        ...Object.assign({}, payload)
      };

    default:
      return state;
  }
};
