import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./containers/Login";
import Dealers from "./containers/Dealers";
import Dealer from "./containers/Dealer";
import DetailsRule from "./containers/DetailsRules";
import History from "./containers/History";
import NewDealer from "./containers/NewDealer";
import Rules from "./containers/Rules";
import EditRules from "./containers/EditRules";
import Schemas from "./containers/Schemas";
import UpdateDealer from "./containers/UpdateDealer";
import CreateSchema from "./containers/CreateSchma";
import PrivateRoute from "./components/PrivateRoutes/PrivateRoutes";

const App = () => {
  return (
    <main>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute
            path="/distribuidor/:id/historial/:hu/detalle-regla/:date"
            component={DetailsRule}
          />
          <PrivateRoute
            path="/distribuidor/:id/historial"
            component={History}
          />
          <PrivateRoute
            path="/distribuidor/:id/reglas/:fase/editar"
            component={EditRules}
          />
          <PrivateRoute
            path="/distribuidor/:id/reglas/:fase"
            component={Rules}
          />

          <PrivateRoute path="/distribuidor/:id" component={Dealer} />
          <PrivateRoute
            path="/distribuidores"
            component={Dealers}
            breadcrumb={<p>home</p>}
          />
          <PrivateRoute path="/nuevo-distribuidor" component={NewDealer} />
          <PrivateRoute
            path="/actualizar-distribuidor"
            component={UpdateDealer}
          />
          <PrivateRoute path="/esquemas/crear" component={CreateSchema} />
          <PrivateRoute
            path="/esquemas/editar/:nameScheme"
            component={CreateSchema}
          />
          <PrivateRoute
            path="/esquemas/ver/:nameScheme"
            component={CreateSchema}
          />
          <PrivateRoute path="/esquemas" component={Schemas} />
          <Route path="/">
            <Redirect to="/distribuidores" />
          </Route>
          {/* <Route path="*">
            <NoMatch />
          </Route> */}
        </Switch>
      </Router>
    </main>
  );
};

export default App;
