import styled from 'styled-components';

const Table = styled.table`
width: 100%;
`;

const Thead = styled.thead`
height: 3.375rem;
margin-top: 1.375rem;
background: var(--blueDark);
width: 100%;
border-radius: 5px 5px 0px 0px;
color: #fff;
display: flex;
justify-content: flex-end;
align-items: center;

tr th label {
  font-weight: 700;
  font-size: 0.75rem;
  color: #fff;
  margin: 0;
}
tr th {
  display: flex;
  align-items: center;
}
`;

const Tbody = styled.tbody`
display: flex;
justify-content: space-between;
flex-direction: column;
align-items: center;
& tr {
  border-top: 0px;
  border-bottom: 1px solid var(--greyLight);
  border-left: 1px solid var(--greyLight);
  border-right: 1px solid var(--greyLight);
}
tr:first-child {
  border-top: 1px solid var(--greyLight);
}
tr:last-child {
  border-radius: 0px 0px 5px 5px;
}
`;

export { Table, Thead, Tbody };