import {
  ADD_ROW_SCHEME,
  REMOVE_ROW_SCHEME,
  SET_NAME_SCHEME,
  RESET,
  SET_FIELD_SCHEME,
  GET_SCHEME
} from '../constants/actionTypes';
const initialRow = { name: '', type: '', mode: '' };
const initialState = {
  schema_name: '',
  fields: [initialRow]
};

export const schemaReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_ROW_SCHEME:
      return {
        ...state,
        fields: [...state.fields, initialRow]
      };
    case REMOVE_ROW_SCHEME:
      const newData = [...state.fields];
      newData.splice(payload, 1);
      return { ...state, fields: newData };
    case SET_NAME_SCHEME:
      return {
        ...Object.assign({}, state, { [payload.name]: payload.value })
      };
    case SET_FIELD_SCHEME:
      return {
        ...Object.assign({}, state, {
          schema_name: state.schema_name,
          fields: state.fields.map((field, k) =>
            // eslint-disable-next-line eqeqeq
            k == payload.index
              ? Object.assign({}, field, { [payload.name]: payload.value })
              : field
          )
        })
      };
    case GET_SCHEME:
      return { ...payload };
    case RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
