/* Reemplaza espacios por _ */
const repleceSpacesToUnderlineString = (string) => {
  const newString = string.replace(' ', '_');
  return newString;
};

/* Limpia caracteres especiales de un string */
const removeSpecialChar = (string) => {
  const newString = string.replace(/[^a-zA-Z0-9_]/g, '');
  return newString;
};

/* Limpia letras y caracteres especiales de un string */
export const onlyNumbers = (string) => {
  const newString = string.replace(/[^0-9.]/g, '');
  return newString;
};

/* Limpia caracteres especiales y espacios de un string */
export const validateNameSchemes = (string) => {
  let newString = repleceSpacesToUnderlineString(string);
  newString = removeSpecialChar(newString);
  return newString;
};
