
import styled from 'styled-components';

const Tr = styled.tr`
  align-items: center;
  background: #fff;
  /* border: 1px solid #c4c4c4; */
  display: flex;
  padding: 20px;
  width: 100%;
  & td {
    width: 100%;
    text-align: center;
  }
`;
export { Tr };