import React from 'react';
import PropTypes from 'prop-types';
import { Td } from './Styles';


/* Componente que manda el mensaje cuando no hay datos en el historial */
const EmptyHistoryElement = ({ text, colspan }) => {
  return (
    <tr>
      <Td colSpan={colspan}>{text}</Td>
    </tr>
  );
};

EmptyHistoryElement.propTypes = {
  text: PropTypes.string,
  colspan: PropTypes.number
};

export default EmptyHistoryElement;
