import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { CLIENT_ID_GOOGLE } from '../../../constants/apis';
// import { Header, SvgLogo, HeaderText, MyCustomButton } from './Styles';
import { Header, SvgLogo, HeaderText } from './Styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

/* Componente Login para inicio de sesion */

/*
 * @param  {} handleLoginGoolge: handle para el inicio de sesion
 * @param  {} handleLoginGoolgeFail handle por si sucede un error al iniciar sesion
 */

const LoginGoogle = ({ handleLoginGoolge, handleLoginGoolgeFail }) => {
  return (
    <Container>
      <Row className='justify-content-center'>
        <Col md={5} sm={10} xs={11}>
          <Header>
            <SvgLogo />
            <HeaderText>
              App para asignación de reglas de transformación para
              distribuidores
            </HeaderText>
          </Header>
          <Row className='justify-content-center'>
          <GoogleOAuthProvider clientId={CLIENT_ID_GOOGLE} >
                <GoogleLogin 
                  // no es necesario entregar el access_token porque el auth del backen recibe
                  // un token_id y el backen devuelve un access_token.
                  onSuccess = {handleLoginGoolge}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
             </GoogleOAuthProvider>
             </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginGoogle;
