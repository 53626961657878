import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  HeadInfo,
  Container,
  ContainerTitle,
  ContainerText,
  ContainerButton,
  Button,
  Delimit,
  StyledHistory
} from './Styles';


/* Componente Header usado para mostrar la miga de pan y la informacion del distribuidor*/

/*
 * @param  {} subtitle: Subtitulo del head
 * @param  {} description: description del head
 * @param  {} title: titulo del head
 * @param  {} created: contiene la fecha y hora de la creacion del distribuidor
 * @param  {} updated: Contiene la ultima modificacion
 * @param  {} button: Botton del historial de modificaciones
 * @param  {} author: contiene el autor de la creacion del distribuidor
 */
const Head = ({ subtitle, description, title, created, updated, button, author }) => {
  const routes = useSelector(state => state.breadCrumb.breadCrumb);

  const history = useHistory();

  function handleClick() {
    history.push(button.rute);
  }

  return (
    <HeadInfo>
      <BreadCrumb routes={routes} />
      <Container flex={!!button}>
        <ContainerTitle text={!!subtitle}>
          <h4>
            {title}{' '}
            {subtitle && (
              <>
                <Delimit>|</Delimit>
                <span>{subtitle}</span>
              </>
            )}
          </h4>
        </ContainerTitle>
        {button && (
          <ContainerButton>
            <Button onClick={handleClick}>
              <StyledHistory />
              <p>{button.text}</p>
            </Button>
          </ContainerButton>
        )}
      </Container>
      <Container>
        <ContainerText>
          <>
            {created && (
              <p>
                Creación: {created} | {author}
              </p>
            )}
            {updated && (
              <p>
                Última modificación: {updated.date} | {updated.autor}
              </p>
            )}
            {description && <p>{description}</p>}
          </>
        </ContainerText>
      </Container>
    </HeadInfo>
  );
};

Head.propTypes = {
  data: PropTypes.array,
  text: PropTypes.string
};

export default Head;
