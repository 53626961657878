import { combineReducers } from 'redux';
import { dealersReducer } from './dealersReducer';
import { addDealerReducer } from './addDealerReducer';
import { emptyState } from '../store/index';
import { getData } from 'src/utils/data';
import { paginatorReducer } from './paginatorReducer';
import { paginatorHuReducer } from './paginatorHuReducer';
import { historyReducer } from './historyReducer';
import { rulesReducer } from './rulesReducer';
import { schemeReducer } from './schemeReducer';
import { loginReducer } from './loginReducer';
import { responseReducer } from './responseReducer';
import { breadcrumbReducer } from './breadCrumbReducer';
import { schemaReducer } from './schemaReducer';
import { sortDealerReducer } from './sortDealerReducer';
import { responseModalReducer } from './responseModalReducer';
import { loadingReducer } from './loadingReducer';

const appReducer = combineReducers({
  dealers: dealersReducer,
  addDealer: addDealerReducer,
  getDataForm: getData,
  paginator: paginatorReducer,
  paginatorHU: paginatorHuReducer,
  history: historyReducer,
  rules: rulesReducer,
  schemas: schemeReducer,
  dataUser: loginReducer,
  response: responseReducer,
  breadCrumb: breadcrumbReducer,
  schema: schemaReducer,
  sortDealer: sortDealerReducer,
  responseModal: responseModalReducer,
  loading: loadingReducer
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'SET_LOGOUT') {
    state = emptyState;
  }

  return appReducer(state, action);
};

export default rootReducer;
