
import styled from 'styled-components';
import { BsX } from 'react-icons/bs';


const Background = styled.div`
  background: #ffffffc7;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
`;
const ModalWrapper = styled.div`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  height: 23.3125rem;
  margin-top: 11.4375rem;
  padding: 60px;
  width: 37.5rem;
  z-index: 10;
  & p {
    margin-top: 1.9375rem;
    margin-bottom: 2.375rem;
    line-height: 1.6875rem;
    font-weight: 300;
    font-size: 1.125rem;
    color: #454545;
  }
`;

const HeadModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    color: #454545;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
  }
`;

const SdIoClose = styled(BsX)`
  width: 35px;
  height: 35px;
  color: #454545;
  margin-top: -60px;
  cursor: pointer;
`;

const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Button = styled.button.attrs((props) => {
  if (props.type === 'normal') {
    return {
      background: 'none',
      boxShadow: 'none',
      color: '#454545',
      hoverBackground: '#454545',
      hoverColor: '#FFFFFF'
    };
  } else if (props.type === 'primary') {
    return {
      background: 'var(--bluePrimary)',
      boxShadow: '0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35)',
      color: '#FFF',
      hoverBackground: '#077bb3',
      hoverColor: '#FFFFFF'
    };
  } else if (props.type === 'danger') {
    return {
      background: '#ec5454',
      boxShadow: '0rem 1.25rem 1.875rem -0.75rem rgba(205, 98, 98, 0.35)',
      color: '#FFF',
      hoverBackground: '#e72626',
      hoverColor: '#FFFFFF'
    };
  }
})`
  font-weight: 700;
  background: ${(props) => props.background};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: 0.3125rem;
  text-transform: uppercase;
  max-width: 390px;
  padding: 15px;
  border: none;
  margin: 0 1.875rem;
  color: ${(props) => props.color};
  font-size: 0.875rem;
  transition: background-color ease-in-out 250ms;
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.hoverBackground};
    color: ${(props) => props.hoverColor};
  }
`;

export { Background, ModalWrapper, HeadModal, SdIoClose, ContainerButtons, Button };
