import styled from 'styled-components';
import { Flex } from 'rebass';

export const Container = styled(Flex)`
  width: 100%;
  height: 46px;
  border-radius: 5px;
  box-sizing: content-box;
    *::-webkit-scrollbar {
      display: block;
      background:  transparent;
      width: 8px;
      border-radius: 10px;
    }
    
    *::-webkit-scrollbar-thumb {
      background: #D6DBDF;
      border-radius: 6px;
    }
`;
