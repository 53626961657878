import React from 'react';
import { Tr } from './Styles';

/* Componente que muestra un mensaje si no hay datos en la tabla */
/*
 * @param  {} colspan
 * @param  {} text: contiene el texto que se le asigne
 */
export default function EmptyTable({ colspan, text }) {
  return (
    <Tr>
      <td colSpan={colspan}>
        <p>{text}</p>
      </td>
    </Tr>
  );
}
