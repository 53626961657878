import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "../components/Ruless/Steps/Select";
import Edit from "../components/Ruless/Steps/Edit";
import Order from "src/components/Ruless/Steps/Order";
import { setResponse } from "../actions/responseActions";
import {
  setRulesOrder,
  setParameterRule,
  resetRules,
} from "../actions/rulesActions";
import SaveToBigquery from "src/components/Ruless/Parameterise/SaveToBigquery";
import ConcatFieldsFn from "src/components/Ruless/Parameterise/ConcatFieldsFn";
import {
  getScheme,
  getSchemeByNameParams,
  postRulesByDealer,
} from "src/services";
import SchemeAndFields from "src/components/Ruless/Parameterise/SchemeAndFields";
import Formatfieldsfn from "../components/Ruless/Parameterise/Formatfieldsfn";
import OpenField from "../components/Ruless/Parameterise/OpenField";
import { onlyNumbers } from "../utils/validate";
import Iva from "src/components/Ruless/Parameterise/Iva";
import Rankpm from "src/components/Ruless/Parameterise/Rankpm";
import Tolerance from "src/components/Ruless/Parameterise/Tolerance";
import Fillfieldfn from "src/components/Ruless/Parameterise/Fillfieldfn";
import Storage from "src/components/Ruless/Parameterise/Storage";
import { userStories } from "../constants/userStories";
import { createBreadCrumb } from "../actions/breadCrumbActions";
import { setResponseModal } from "../actions/responseModalActions";
import moment from 'moment'

const messageModal = {
  title: "¿Está seguro de que quiere abandonar esta página?",
  message:
    "Todos los cambios que no hayan sido guardados se perderán y no podrá recuperarlos.",
  buttons: [
    {
      text: "PERMANECER EN ESTA PÁGINA",
      type: "normal",
    },
    {
      text: "ABANDONAR ESTA PÁGINA",
      type: "danger",
    },
  ],
  show: false,
};

const validations = {
  selectable: {
    campos: true,
    esquema: true,
  },
  formatfieldsfn: {
    datetime_format: false,
    encoding: false,
    date_format: false,
    time_format: false,
    campos: true,
    esquema: true,
  },
  bigquery: {
    table: true,
    dataset: true,
    write_disposition: true,
    esquema: true,
    campos: true,
  },
  concatfields: {
    table: true,
    dataset: true,
    separator: true,
    esquema: true,
    campos: true,
    table2: true,
    dataset2: true,
  },
  open_field: {
    formula: true,
  },
  schema: {
    esquema: true,
    campos: true,
  },
  iva: {
    init_date: true,
    final_date: true,
  },
  rankpm: {
    selected_day: true,
  },
  tolerance: {
    tolerance: true,
  },
  fillfieldfn: {
    esquema: true,
    field: true,
    extra: false,
  },
  storage: {
    campos: true,
    esquema: true,
    file_path_name: true,
  },
};
const noValidate = [
  "extra",
  "datetime_format",
  "encoding",
  "date_format",
  "time_format",
];

const useRules = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector((state) => state.loading);
  const schemas = useSelector((state) => state.schemas);
  const optionsRankpm = useSelector((state) => state.getDataForm.rankpm);
  const responseModal = useSelector((state) => state.responseModal);

  const steps = getSteps();
  const { id, fase } = useParams();
  const descriptionStep = getDescriptionSteps();

  const [idsOrder, setIdsOrder] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const user = useSelector((state) =>
    state.dataUser ? state.dataUser.user : null
  );
  const { allRules, selectDealer, newRules, selectedRules } = useSelector(
    (state) => state.rules
  );
  const [itemsDrag, setItemsDrag] = useState({
    orden: newRules,
    reglas: selectedRules,
  });
  const [error, setError] = useState([]);
  const [countSave, setCountSave] = useState(0);
  const mError = "Campo obligatorio.";

  /* Array con las rutas para breadCrumb(miga de pan) */
  const routes = [
    {
      id: 1,
      name: "Inicio",
      path: "/",
    },
    {
      id: 2,
      name: `${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`,
      path: `/distribuidor/${selectDealer.codigo_extraccion}`,
    },
    {
      id: 3,
      name: userStories[fase],
      path: `/distribuidor/${selectDealer.codigo_extraccion}/reglas/${fase}`,
    },
  ];

  useEffect(() => {
    setItemsDrag({ orden: [...newRules], reglas: [...selectedRules] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRules]);

  useEffect(() => {
    if (schemas.length === 0) dispatch(getScheme({ all: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemas]);

  useEffect(() => {
    dispatch(createBreadCrumb(routes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /* Cambia el estado del border de cada campo si los datos estan correctos. */
  const handleOnBlur = () => {
    if (countSave > 0) valideInput();
  };
  
  
  /* Componente validar el tipo de parametrizacion que tiene una regla */
  /*
   * @param  {} regla
   * @param  {} error
   */
  const getParamsComponent = ({ regla, error }) => {
    const validate = regla.type_of_parameterization;
    switch (validate) {
      case "selectable":
        return (
          <SchemeAndFields
            options={schemas}
            regla={regla}
            handleChangeSelect={handleChangeSelect}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "formatfieldsfn":
        return (
          <Formatfieldsfn
            options={schemas}
            regla={regla}
            handleChangeSelect={handleChangeSelect}
            handleChangeInput={handleChangeInput}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "bigquery":
        return (
          <SaveToBigquery
            options={schemas}
            regla={regla}
            handleChangeInput={handleChangeInput}
            handleChangeSelect={handleChangeSelect}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
        case "concatfields":
          return (
            <ConcatFieldsFn
              options={schemas}
              regla={regla}
              handleChangeInput={handleChangeInput}
              handleChangeSelect={handleChangeSelect}
              error={error.find((element) => element.id === regla.id)}
              mError={mError}
              handleOnBlur={handleOnBlur}
            />
          );
      case "open_field":
        return (
          <OpenField
            regla={regla}
            handleChangeInput={handleChangeInput}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "schema":
        return (
          <SchemeAndFields
            options={schemas}
            regla={regla}
            handleChangeSelect={handleChangeSelect}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "iva":
        return (
          <Iva
            regla={regla}
            handleChangeDate={handleChangeDate}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "rankpm":
        return (
          <Rankpm
            options={optionsRankpm}
            regla={regla}
            handleChangeSelect={handleChangeSelect}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "tolerance":
        return (
          <Tolerance
            regla={regla}
            handleChangeInput={handleChangeInput}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "fillfieldfn":
        return (
          <Fillfieldfn
            options={schemas}
            regla={regla}
            handleChangeSelect={handleChangeSelect}
            handleChangeInput={handleChangeInput}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      case "storage":
        return (
          <Storage
            options={schemas}
            regla={regla}
            handleChangeSelect={handleChangeSelect}
            handleChangeInput={handleChangeInput}
            error={error.find((element) => element.id === regla.id)}
            mError={mError}
            handleOnBlur={handleOnBlur}
          />
        );
      default:
        return null;
    }
  };

  /* Valida el componente a renderizar por cada paso */
  const getStepContent = ({ activeStep, error }) => {
    switch (activeStep) {
      case 0:
        return (
          <Select
            onClickModal={onClickModal}
            allRules={validateRuleHU(allRules)}
          />
        );
      case 1:
        return <Order items={itemsDrag} onChange={onChangeOrder} />;
      case 2:
        return (
          <Edit
            items={newRules}
            getParamsComponent={getParamsComponent}
            error={error}
            getDescriptionRule={getDescriptionRule}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  /*  */
  const validateRuleHU = (rules) => {
    const filterRules = rules.items.filter(
      // eslint-disable-next-line eqeqeq
      (element) => element.use_in == fase || element.use_in === "all"
    );
    return { items: filterRules };
  };

  /* handles para los cambios en inputs de editar reglas */
  const handleChangeInput = ({ e, id }) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "tolerance") {
      value = onlyNumbers(value);
      if (value > 100) {
        value = 100;
        // eslint-disable-next-line eqeqeq
      } else if (value < 1 && value != "") {
        value = 1;
      }
      if (value !== "" || value !== null) {
        value = value / 100;
      }
    }
    dispatch(setParameterRule({ name, value, id }));
  };

  /* Validar campos de las reglas en parametrizar reglas */
  const valideInput = () => {
    const copyRules = JSON.parse(JSON.stringify(newRules));
    const arrValitations = [];
    let validate = true;
    // eslint-disable-next-line array-callback-return
    copyRules.map((rule) => {
      const obj = {
        ...validations[rule.type_of_parameterization],
        id: rule.id,
      };
      Object.entries(rule.parameters).forEach(([key, value]) => {
        if (obj.hasOwnProperty(key) && !noValidate.includes(key)) {
          if (value === "" || value === null || value.length === 0) {
            validate = false;
            obj[key] = true;
          } else {
            obj[key] = false;
          }
        }
      });
      Object.entries(obj).forEach(([key, value]) => {
        if (key !== id && value === true) {
          validate = false;
        }
      });
      arrValitations.push(obj);
    });
    setError(arrValitations);
    return validate;
  };
  /* handle para el cambio de fecha */
  const handleChangeDate = ({ date, nameNull, id }) => {
    const value = date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
    dispatch(setParameterRule({ name: nameNull, value, id }));
  };

  const handleChangeSelect = ({
    e,
    id,
    nameNull = null,
    dependentField = null,
  }) => {
    let name;
    let value = "";
    if (Array.isArray(e)) {
      if (e.length > 0) {
        name = e[0].name;
        value = e;
      } else {
        name = nameNull;
        value = [];
      }
    } else {
      name = e.name;
      value = e.value;
    }
    if (name === "esquema") {
      dispatch(getSchemeByNameParams({ name, value, id }));
      dependentField &&
        dispatch(setParameterRule({ name: dependentField, value: "", id }));
      return;
    }
    if (name === "campos") {
      const arrFields = [];
      // eslint-disable-next-line array-callback-return
      value.map((element) => {
        const regla = newRules.find((rule) => rule.id === id);
        const campo = regla.parameters.schema.fields.find(
          (field) => field.name === element.value
        );
        arrFields.push(campo);
      });
      dispatch(setParameterRule({ name: "fields", value: arrFields, id }));
    }
    dispatch(setParameterRule({ name, value, id }));
  };

  /* Obtener nombre de cada paso */
  function getSteps() {
    return [
      "Seleccionar Reglas",
      "Ordenar ejecución de reglas",
      "Editar reglas parametrizables",
    ];
  }

  /* Obtener descripciones de cada paso */
  function getDescriptionSteps() {
    return [
      `Seleccione las reglas que desea aplicar a este distribuidor en la fase de ${userStories[fase]}.`,
      `Deslice las reglas y organice, de izquierda a derecha, el orden en el cual desea aplicarlas a este distribuidor en la fase de ${userStories[fase]}`,
      "Indique los campos y las rutas que aplican a las reglas parametrizables seleccionadas en el paso anterior.",
    ];
  }

  /* Mueve elementos del ordenamiento entre componentes (div) */
  function moveLocal(
    source,
    destination,
    droppableSource,
    droppableDestination,
    destinationId
  ) {
    let id;
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const _a = sourceClone.splice(droppableSource, 1);
    if (typeof idsOrder[_a[0].name] !== "undefined") {
      id = `${_a[0].name}-${idsOrder[_a[0].name] + 1}`;
    } else {
      id = `${_a[0].name}-1`;
    }
    const removed = { ..._a[0], id };
    if (destinationId === "reglas") {
      return [sourceClone, destClone];
    }
    destClone.splice(droppableDestination, 0, removed);
    setIdsOrder((prev) => ({
      ...prev,
      [removed.name]: prev[removed.name] ? prev[removed.name] + 1 : 1,
    }));
    return [source, destClone];
  }

  /* ordena los elementos de cada componente */
  function swapLocal(array, moveIndex, toIndex) {
    const item = array[moveIndex];
    const length = array.length;
    const diff = moveIndex - toIndex;
    if (diff > 0) {
      // move left
      if (moveIndex + 1 < length) {
        return [
          ...array.slice(0, toIndex),
          item,
          ...array.slice(toIndex, moveIndex),
          ...array.slice(moveIndex + 1, length),
        ];
      } else {
        return [
          ...array.slice(0, toIndex),
          item,
          ...array.slice(toIndex, moveIndex),
        ];
      }
    } else if (diff < 0) {
      // move right
      const targetIndex = toIndex + 1;
      return [
        ...array.slice(0, moveIndex),
        ...array.slice(moveIndex + 1, targetIndex),
        item,
        ...array.slice(targetIndex, length),
      ];
    }
    return array;
  }

  /* Funcion que maneja el cambio en el ordenamiento de las reglas */
  function onChangeOrder(sourceId, sourceIndex, targetIndex, targetId) {
    /* If que valida si el elemento esta declarado en el array source para validar el box de ejemplo */
    if (typeof itemsDrag[sourceId][sourceIndex] === "undefined") {
      return;
    }
    if (targetId) {
      const result = moveLocal(
        itemsDrag[sourceId],
        itemsDrag[targetId],
        sourceIndex,
        targetIndex,
        targetId
      );

      return setItemsDrag({
        ...itemsDrag,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }
    const result = swapLocal(itemsDrag[sourceId], sourceIndex, targetIndex);
    return setItemsDrag({
      ...itemsDrag,
      [sourceId]: result,
    });
  }

  /* Funcion para validar el sifuiente paso */
  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (selectedRules.length > 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          dispatch(
            setResponse({
              status: 1000,
              message: "Debe seleccionar al menos una regla para continuar",
              show: true,
              redirect: "",
            })
          );
        }
        break;
      case 1:
        if (itemsDrag.orden.length > 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          const rulesForBack = itemsDrag.orden.map((item) => ({
            name: item.name,
            id: item.id,
            type_of_parameterization: item.type_of_parameterization,
          }));
          dispatch(setRulesOrder(rulesForBack));
        } else {
          dispatch(
            setResponse({
              status: 1000,
              message: "Debe ordenar al menos una regla para continuar",
              show: true,
              redirect: "",
            })
          );
        }
        break;
      case 2:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        break;
    }
  };

  /* Funcion para guardar las reglas */
  const handleSave = () => {
    setCountSave((prev) => prev + 1);
    const validate = valideInput();
    if (validate) {
      dispatch(
        postRulesByDealer({
          data: newRules,
          id,
          hu: fase,
          country: selectDealer.pais,
        })
      );
    }
  };

  /* Funcion para devolverse en el paso a paso */
  const handleBack = () => {
    switch (activeStep) {
      case 0:
        dispatch(
          setResponseModal({
            ...messageModal,
            show: !responseModal.show,
            click: null,
          })
        );
        break;
      case 1:
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        const rulesForBack = itemsDrag.orden.map((item) => ({
          name: item.name,
          id: item.id,
          type_of_parameterization: item.type_of_parameterization,
        }));
        dispatch(setRulesOrder(rulesForBack));
        break;
      case 2:
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;

      default:
        break;
    }
  };

  /* Funcion para continuar */
  const handleContinue = () => {
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null,
      })
    );
  };

  /* Funcion para regresarse a la pantalla de distribuidor  */
  const returnDistribuidor = () => {
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null,
      })
    );
    dispatch(resetRules());
    history.push(`/distribuidor/${id}`);
  };

  /* Funcion para obtener la descripcion de una regla */
  const getDescriptionRule = ({ name }) => {
    const rule = allRules.items.find((regla) => regla.name === name);
    return rule.long_description;
  };

  /* Ejecucion de funciones del modal */
  const onClickModal = (name) => {
    switch (name) {
      case "PERMANECER EN ESTA PÁGINA":
        handleContinue();
        break;
      case "ABANDONAR ESTA PÁGINA":
        returnDistribuidor();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (responseModal.click) {
      onClickModal(responseModal.click);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseModal]);

  // Limpia estado de reglas
  useEffect(() => {
    return () => {
      dispatch(resetRules());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Devolvemos todos los datos que consumiremos. */
  return {
    getStepContent,
    getSteps,
    getDescriptionSteps,
    onChangeOrder,
    handleNext,
    handleBack,
    handleSave,
    user,
    allRules,
    selectDealer,
    newRules,
    steps,
    descriptionStep,
    activeStep,
    itemsDrag,
    userStories,
    fase,
    onClickModal,
    isLoading,
    error,
  };
};

export default useRules;
