
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border: 1px solid #8f8f8f;
  border-radius: 0.3125rem;
  border-spacing: 0;
  border-collapse: separate;
  background: #fff;
  tbody {
    display: flex;
    flex-direction: column;
  }
  tr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 97px;
  }
  tr:not(:last-child) {
    border-bottom: 1px solid #8f8f8f;
  }
  td {
    padding: 2.3125rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  td:nth-child(1) {
    font-weight: 700;
    font-size: 1.125rem;
    color: #454545;
  }
  td:nth-child(2) {
    font-weight: 400;
    font-size: 1.125rem;
    color: #454545;
  }
  td:nth-child(3) {
    font-weight: 300;
    font-size: 0.75rem;
    color: #454545;
    & span {
      margin-left: 0.5rem;
      font-weight: 400;
      font-size: 1.125rem;
      text-transform: capitalize;
    }
  }
`;
export { Table };