import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  color: #0c3967;
  margin-bottom: 3.625rem;
  margin-top: 3rem;
`;

export const TextMessage = styled.span`
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 600;
  color: #ec5454;
`;

export const StyledLabel = styled(Form.Label)`
  font-weight: 400;
  font-size: 0.875rem;
  color: #454545;
  margin-bottom: 0.75rem;
`;

export const StlyedInput = styled(Form.Control)`
  width: 100%;
  height: 46px;
  margin-bottom: 36px;
  box-sizing: border-box;
  border: solid 1px ${({ error }) => (error === true ? '#ec5454' : '#ced4da')};
  &::placeholder {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.3);
  }
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const Button = styled.button`
  font-weight: 700;
  background: ${(props) =>
    props.typeButton === 'normal' ? 'none' : 'var(--bluePrimary)'};
  box-shadow: ${(props) =>
    props.typeButton === 'normal'
      ? 'none'
      : '0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35);'};
  border-radius: 0.3125rem;
  text-transform: uppercase;
  width: 35rem;
  height: 2.875rem;
  border: none;
  color: ${(props) => (props.typeButton === 'normal' ? '#454545' : '#FFF')};
  font-size: 0.875rem;
  transition: background-color ease-in-out 250ms;
  &:hover {
    background: ${(props) =>
    props.typeButton === 'normal' ? '#454545' : 'var(--blueDark)'};
    color: ${(props) => (props.typeButton === 'normal' ? '#FFF' : '#FFF')};
  }
`;
export const ButtonAdd = styled.button`
  font-weight: 700;
  background: #0bc16d;
  box-shadow: 0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35);
  border-radius: 0.3125rem;
  text-transform: uppercase;
  width: 100%;
  height: 2.875rem;
  border: none;
  color: #fff;
  font-size: 0.875rem;
  transition: background-color ease-in-out 250ms;
  &:hover {
    background: #00904e;
    color: #fff;
  }
`;
export const ButtonLink = styled(Link)`
  font-weight: 700;
  background: none;
  box-shadow: none;
  border-radius: 0.3125rem;
  text-transform: uppercase;
  border: 1px solid #454545;
  padding: 15px 20px;
  color: #454545;
  font-size: 0.875rem;
  transition: background-color ease-in-out 250ms;
  text-decoration:none;
  &:hover {
    background: #454545;
    color: #fff;
    text-decoration:none;
  }
`;
export const ButtonDelete = styled.button`
  font-weight: 700;
  background: #ec5454;
  box-shadow: 0rem 1.25rem 1.875rem -0.75rem rgb(205 98 98 / 35%);
  border-radius: 0.3125rem;
  text-transform: uppercase;
  width: 100%;
  padding: 15px;
  border: none;
  color: #fff;
  font-size: 0.875rem;
  -webkit-transition: background-color ease-in-out 250ms;
  transition: background-color ease-in-out 250ms;
  &:hover {
    background: #e72626;
    color: #fff;
  }
`;
export const IndicatorNumber = styled.p`
  text-align: center;
  width: 100%;
  color: #454545;
`;
