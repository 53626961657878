import { useDispatch } from 'react-redux';
import { setPaginatePage, getPaginateHU2, getPaginateHU3 } from 'src/actions/paginatorActions';

const usePaginate = (paginador, getService) => {
  const dispatch = useDispatch();

  /* Cambia la pagina para la historia de usuario hu1 */
  const changePaginate = (data) => {
    if (paginador.page === data.selected) return;
    dispatch(setPaginatePage({ page: data.selected }));
    getService();
  };

  /* Cambia la pagina para la historia de usuario hu2 */
  const changePaginateHU2 = (data) => {
    if (paginador.page === data.selected) return;
    dispatch(getPaginateHU2({ page: data.selected }));
    getService();
  };

  /* Cambia la pagina para la historia de usuario hu3 */
  const changePaginateHU3 = (data) => {
    if (paginador.page === data.selected) return;
    dispatch(getPaginateHU3({ page: data.selected }));
    getService();
  };

  return {
    changePaginate,
    changePaginateHU2,
    changePaginateHU3
  };
};
export default usePaginate;
