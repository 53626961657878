import {
  GET_LOGIN_GOOGLE,
  GET_TOKEN,
  SET_LOGOUT
} from '../constants/actionTypes';

export const loginReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LOGIN_GOOGLE: {
      return { ...state, user: payload };
    }
    case GET_TOKEN: {
      window.localStorage.setItem('token', payload);
      return { ...state, token: payload };
    }
    case SET_LOGOUT: {
      window.localStorage.clear();
      return {};
    }
    default:
      return state;
  }
};
