import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Flex } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RulesGroup from './RulesGroup/RulesGroup';
import { useDispatch } from 'react-redux';
import { getSelectChecked } from 'src/actions/rulesActions';
import ModalCounter from '../../../utils/ModalCounter/ModalCounter';
import BeforeUnloadComponent from 'react-beforeunload-component';
import ModalConfirmation from 'src/utils/ModalConfirmation/ModalConfirmation';

const TitleText = styled.h4`
  color: #454545;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 1.125rem 0 2rem 0;
`;

/* Componente que se encarga de la parte de seleccion de reglas */
/*
 * @param  {} allRules: Contiene el estado con todas las reglas
 */
const Select = ({ allRules }) => {
  const [showModalCounter, setShowModalCounter] = useState(false);
  const [checked, setChecked] = useState({ id: '', check: false });
  const dispatch = useDispatch();

  const handleFnClick = (e, id) => {
    const check = e.target.checked;
    setChecked({ id, check });
    if (check) {
      dispatch(getSelectChecked({ id, check }));
      /* setShowModalCounter((prev) => !prev) */
    } else {
      dispatch(getSelectChecked({ id, check }));
      /* dispatch(getSelectChecked({ id, check, count: false })) */
    }
  };
  const handleContinue = (count) => {
    dispatch(getSelectChecked({ ...checked, count }));
    setShowModalCounter((prev) => !prev);
  };
  const handleCancel = () => {
    setShowModalCounter((prev) => !prev);
  };
  // Controlando el checkbox
  const handleSubmit = (e, id) => {
    const submit = { value: e.target.checked };
    dispatch(getSelectChecked({ id, check: submit }));
  };

  return (
    <>
      <Flex>
        <BeforeUnloadComponent
          blockRoute
          alertMessage='¿Está seguro de que quiere abandonar esta página?'
          modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
            return (
              <ModalConfirmation
                onClose={handleModalCancel}
                onSubmit={handleModalLeave}
              />
            );
          }}
        />
        {showModalCounter && (
          <ModalCounter
            title='Seleccione el numero de veces'
            btCancel='Cancelar'
            btContinue='Continuar'
            handleContinue={handleContinue}
            handleCancel={handleCancel}
            handleClose={handleCancel}
          />
        )}
      </Flex>
      {allRules.items ? (
        <div>
          <Row>
            <Col xs={12}>
              <TitleText>
                Reglas de transformación Equivalencias Iniciales
              </TitleText>
              <RulesGroup
                handleSubmit={handleSubmit}
                handleFnClick={handleFnClick}
                reglas={allRules.items}
              />
            </Col>
          </Row>
          <hr />
        </div>
      ) : null}
    </>
  );
};

Select.propTypes = {
  reglas: PropTypes.array
};

export default Select;
