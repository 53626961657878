import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';


/* Componente que valida la sesion de usuario mediante el token enviado desde del backend */
/*
 * @param  {Component} component: Recibe el componente
 * @param  {} ...rest: parametros de router-dom que vienen del App.js
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const getAuth = useSelector(state => (state.dataUser) ? state.dataUser.token : null);
  return (
    <Route
      {...rest}
      render={(props) =>
        getAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )}
    />
  );
};
export default PrivateRoute;
