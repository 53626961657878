import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import EditRulesGroup from './EditRulesGroup/EditRulesGroup';
import BeforeUnloadComponent from 'react-beforeunload-component';
import ModalConfirmation from 'src/utils/ModalConfirmation/ModalConfirmation';

const TitleText = styled.h4`
  color: #454545;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 1.125rem 0 2rem 0;
`;


/* Componente de parametrizacion de reglas */
/*
 * @param  {} items: reglas a parametrizar
 * @param  {} getParamsComponent: valida el tipo de parametrizacion que tiene una regla
 * @param  {} error
 * @param  {} getDescriptionRule: devuelve la descripcion de una regla seleccionada
 */
const Edit = ({ items, getParamsComponent, error, getDescriptionRule }) => {
  /* return items.map((group, index) => ( */
  return (
    <div>
      <BeforeUnloadComponent
        blockRoute
        alertMessage='¿Está seguro de que quiere abandonar esta página?'
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          return (
            <ModalConfirmation
              onClose={handleModalCancel}
              onSubmit={handleModalLeave}
            />
          );
        }}
      />
      <Row>
        <Col xs={12}>
          <TitleText>Parametrizar reglas</TitleText>
          <EditRulesGroup
            reglas={items}
            getParamsComponent={getParamsComponent}
            error={error}
            getDescriptionRule={getDescriptionRule}
          />
        </Col>
      </Row>
    </div>
  );
  /* )) */
};

Edit.propTypes = {
  reglas: PropTypes.array
};

export default Edit;
