import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Label, Input, handleCustomStyles, selectTheme, TextMessage } from './styles';
import {
  SchemeToSelectFormat,
  SchemeFlieldsToSelectFormat
} from 'src/utils/formatting';
import { Box } from 'rebass';

/* Componente encargado de parametrizar la regla Formatfieldsfn */
/*
 * @param  {} options: opciones del esquema
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeSelect: handle para cambiar la seleccion
 * @param  {} handleChangeInput: handle para el input
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const Formatfieldsfn = ({
  options,
  regla,
  handleChangeSelect,
  handleChangeInput,
  error,
  mError,
  handleOnBlur
}) => {
  const { parameters } = regla;
  const optionsSchemas = SchemeToSelectFormat(options, 'esquema');
  const optionsCampos = parameters?.schema?.fields
    ? SchemeFlieldsToSelectFormat(parameters.schema.fields, 'campos')
    : [];
  let valuesMulti = [];
  if (typeof parameters.campos !== 'undefined' || parameters.campos !== '') {
    valuesMulti = parameters.campos;
  }
  return (
    <Row>
      <Col md={6} xs={12}>
        <Label>Datetime Format</Label>
        <Input
          name='datetime_format'
          placeholder='Ingrese el valor'
          value={parameters?.datetime_format}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.datetime_format}
          onBlur={handleOnBlur}
        />
        {error?.datetime_format ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Encoding</Label>
        <Input
          name='encoding'
          placeholder='Ingrese el valor'
          value={parameters?.encoding}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.encoding}
          onBlur={handleOnBlur}
        />
        {error?.encoding ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Date Format</Label>
        <Input
          name='date_format'
          placeholder='Ingrese el valor'
          value={parameters?.date_format}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.date_format}
          onBlur={handleOnBlur}
        />
        {error?.date_format ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Time Format</Label>
        <Input
          name='time_format'
          placeholder='Ingrese el valor'
          value={parameters?.time_format}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.time_format}
          onBlur={handleOnBlur}
        />
        {error?.time_format ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Esquema</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, dependentField: 'campos' })}
          options={optionsSchemas}
          placeholder='Seleccione un esquema'
          theme={selectTheme}
          value={
            typeof parameters.esquema === 'undefined' ||
              parameters.esquema === ''
              ? []
              : [{ value: parameters.esquema, label: parameters.esquema }]
          }
          styles={handleCustomStyles({ error: error?.esquema })}
          isDisabled={false}
          onBlur={handleOnBlur}
        />
        {error?.esquema ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Campos  </Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, nameNull: 'campos' })}
          options={optionsCampos}
          placeholder='Seleccione los campos'
          theme={selectTheme}
          value={valuesMulti}
          styles={handleCustomStyles({ error: error?.campos })}
          isDisabled={false}
          isMulti
          onBlur={handleOnBlur}
        />
        {error?.campos ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default Formatfieldsfn;
