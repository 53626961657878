import React from 'react';
import { Row, Col } from 'react-bootstrap';
import HistoryElement from '../HistoryElement/HistoryElement';
import EmptyHistoryElement from '../EmptyHistoryElement/EmptyHistoryElement';
import { createDateAsUTCFromString } from '../../../utils/formatting';
import { userStories } from '../../../constants/userStories';
import Paginator from 'src/components/Paginator/Paginator';
import { CardTitle, Table } from './Styles';


/* Componente Tabla del historial de modificaciones por historia de usuario */

/*
 * @param  {} paginate: Contiene el paginador de la historia de usuario HU1
 * @param  {} paginateHU2: Contiene el paginador de la historia de usuario HU2
 * @param  {} paginateHU3: Contiene el paginador de la historia de usuario HU3
 * @param  {} changePaginate: Accion que actualiza el estado del paginador de la historia de usuario HU1
 * @param  {} changePaginateHU2: Accion que actualiza el estado del paginador de la historia de usuario HU2
 * @param  {} changePaginateHU3: Accion que actualiza el estado del paginador de la historia de usuario HU3
 * @param  {} history: Contiene el estado del historial
 * @param  {} id:
 */
const TableHistory = ({ paginate, paginateHU2, paginateHU3, changePaginate, changePaginateHU2, changePaginateHU3, history, id }) => {
  return (
    <>
      <CardTitle>
        <h5>{userStories?.hu1}</h5>
      </CardTitle>
      <Table>
        <tbody>
          {history.history_hu1.length > 0 ? (
            history.history_hu1.map((element, index) => (
              <HistoryElement
                key={index}
                id={id}
                date={createDateAsUTCFromString(
                  element.created_at
                ).toLocaleDateString([], {})}
                hour={createDateAsUTCFromString(
                  element.created_at
                ).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
                idElement={element.created_at}
                by={element.created_by}
                historiasUsuario='hu1'
              />
            ))
          ) : (
            <EmptyHistoryElement
              text='No se encontraron elementos'
              colspan={3}
            />
          )}
          <Row className='justify-content-center'>
            <Col md={8}>
              {
                paginate?.total
                  ? <Paginator paginate={paginate} changePaginate={changePaginate} />
                  : null
              }
            </Col>
          </Row>
        </tbody>
      </Table>
      <hr />
      <CardTitle>
        <h5>{userStories?.hu2}</h5>
      </CardTitle>
      <Table>
        <tbody>
          {history.history_hu2.length > 0 ? (
            history.history_hu2.map((element, index) => (
              <HistoryElement
                key={index}
                id={id}
                date={createDateAsUTCFromString(
                  element.created_at
                ).toLocaleDateString([], {})}
                hour={createDateAsUTCFromString(
                  element.created_at
                ).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
                idElement={element.created_at}
                by={element.created_by}
                historiasUsuario='hu2'
              />
            ))
          ) : (
            <EmptyHistoryElement
              text='No se encontraron elementos'
              colspan={3}
            />
          )}
          <Row className='justify-content-center'>
            <Col md={8}>
              {
                paginateHU2?.total
                  ? <Paginator paginate={paginateHU2} changePaginate={changePaginateHU2} />
                  : null
              }
            </Col>
          </Row>
        </tbody>
      </Table>
      <hr />
      <CardTitle>
        <h5>{userStories?.hu3}</h5>
      </CardTitle>
      <Table>
        <tbody>
          {history.history_hu3.length > 0 ? (
            history.history_hu3.map((element, index) => (
              <HistoryElement
                key={index}
                id={id}
                date={createDateAsUTCFromString(
                  element.created_at
                ).toLocaleDateString([], {})}
                hour={createDateAsUTCFromString(
                  element.created_at
                ).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
                idElement={element.created_at}
                by={element.created_by}
                historiasUsuario='hu3'
              />
            ))
          ) : (
            <EmptyHistoryElement
              text='No se encontraron elementos'
              colspan={3}
            />
          )}
          <Row className='justify-content-center'>
            <Col md={8}>
              {
                paginateHU3?.total
                  ? <Paginator paginate={paginateHU3} changePaginate={changePaginateHU3} />
                  : null
              }
            </Col>
          </Row>
        </tbody>
      </Table>
    </>
  );
};

export default TableHistory;
