import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Label, Input, TextMessage } from './styles';
import { Box } from 'rebass';


/* Componente encargado de parametrizar la regla Storage */
/*
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeInput: handle para el input
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const Tolerance = ({ regla, handleChangeInput, error, mError, handleOnBlur }) => {
  const { parameters } = regla;
  return (
    <Row>
      <Col md={12} xs={12}>
        <Label>Digite el procentaje</Label>
        <Label required bold>*</Label>
        <Input
          name='tolerance'
          placeholder='Ingrese el valor'
          value={
            parameters?.tolerance !== '' ? parameters?.tolerance * 100 : ''
          }
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.tolerance}
          onBlur={handleOnBlur}
        />
        {error?.tolerance ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default Tolerance;
