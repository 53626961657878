import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Label, Input, TextMessage } from './styles';
import { Box } from 'rebass';


/* Componente encargado de parametrizar la regla OpenField */
/*
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeInput: handle para el input
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const OpenField = ({
  regla,
  handleChangeInput,
  error,
  mError,
  handleOnBlur
}) => {
  const { parameters } = regla;
  return (
    <Row>
      <Col md={12} xs={12}>
        <Label>Formula</Label>
        <Label required bold>*</Label>
        <Input
          name='formula'
          placeholder='Ingrese el valor'
          value={parameters?.formula}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.formula}
          onBlur={handleOnBlur}
        />
        {error?.formula ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default OpenField;
