import React from 'react';
import Header from '../components/Header/Header';
import { Container } from 'react-bootstrap';
import Head from '../components/Distributor/Head/Head';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import Card from '../components/Distributor/Card/Card';
import { userStories } from '../constants/userStories';
import { createDateAsUTCFromString } from '../utils/formatting';
import useDealer from '../hooks/useDealer';
import Modal from 'src/utils/Modal/Modal';
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader';

/* Contenedor del distribuidor */
const Dealer = () => {
  const {
    user,
    selectDealer,
    rulesDealerHU2,
    rulesDealerHU3,
    rulesDealer,
    hasButton,
    id,
    lastUpdate,
    openModal,
    isLoading,
    redirectToRules,
    redirectToRulesEdit,
    handleClickExecutionHU1,
    handleClickExecutionHU2,
    handleClickExecutionHU3
  } = useDealer()
  return (
    <>
      <Header user={user} />
      <Container className='p-0'>
        <Head
          title={`${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`}
          created={
            lastUpdate
              ? createDateAsUTCFromString(lastUpdate.created_at).toLocaleString(
                [],
                {
                  hour12: true
                }
              )
              : '-'
          }
          updated=''
          author={lastUpdate ? lastUpdate.created_by : '-'}
          button={hasButton}
        />
        <Card
          id={id}
          rulesDealer={
            rulesDealer.length > 0
              ? rulesDealer[0]
              : { hu1_rules: [], hu2_rules: [], hu3_rules: [] }
          }
          userStories={userStories}
          rulesDealerHU2={rulesDealerHU2?.length > 0 ? rulesDealerHU2[0] : {}}
          rulesDealerHU3={rulesDealerHU3?.length > 0 ? rulesDealerHU3[0] : {}}
          openModal={openModal}
          redirectToRules={redirectToRules}
          redirectToRulesEdit={redirectToRulesEdit}
          handleClickExecutionHU1={handleClickExecutionHU1}
          handleClickExecutionHU2={handleClickExecutionHU2}
          handleClickExecutionHU3={handleClickExecutionHU3}
        />
      </Container>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
      <Modal />
    </>
  );
};

export default Dealer;
