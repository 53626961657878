import React from 'react';
import { ReactComponent as Error } from '../../../images/Logo_error.svg';
import { Div, Message } from './Styles';

/* Componente con mensaje cuando no hay reglas por historia de usuario */
function EmptyRulesMessage() {
  return (
    <Div>
      <Error />
      <Message>
        El distribuidor no cuenta con reglas asignadas para esta historia de usuario, por lo cual no podrá ser activado. Debe asignar una regla para cada una de las historias para que pueda activarlo.
      </Message>
    </Div>
  );
}

export default EmptyRulesMessage;
