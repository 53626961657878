import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
  ContainerButtons,
  StyledForm,
  Input,
  ButtonSearch,
  ButtonAddDistribuidor,
  StyledPlus,
  StyledIconClose
} from './Styles';

/* Este componente es el conjunto de botones para buscar distribuidores, limpiar busqueda, ver esquemas y añadir distribuidor */
/*
 * @param  {} setSearchDealer: Accion actualiza el estado que Limpia el input
 * @param  {} onclickSearch: Accion que realiza la busqueda
 * @param  {} searchDealer: Contiene los distribuidores
 * @param  {} cleanSearch: Limpia la busqueda
 * @param  {} onclickClean:  Accion que actualiza el estado que limpia la busqueda
 */

const Buttons = ({
  setSearchDealer,
  onclickSearch,
  searchDealer,
  cleanSearch,
  onclickClean
}) => {
  return (
    <ContainerButtons>
      <Row>
        <Col md={6}>
          <StyledForm>
            <Input
              placeholder='Código o nombre de distribuidor'
              value={searchDealer}
              onChange={(e) => setSearchDealer(e.target.value)}
              disabled={cleanSearch}
            />
            {cleanSearch ? (
              <ButtonSearch onClick={onclickClean}>
                <span><StyledIconClose className='far fa-times-circle' /></span> Limpiar
              </ButtonSearch>
            ) : (
              <ButtonSearch
                type='submit'
                onClick={onclickSearch}
                disabled={!(searchDealer.length > 2)}
              >
                Buscar
              </ButtonSearch>
            )}
          </StyledForm>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6}>
              <Link to='/esquemas'>
                <ButtonAddDistribuidor>
                  Esquemas
                </ButtonAddDistribuidor>
              </Link>
            </Col>
            <Col md={6}>
              <Link to='/nuevo-distribuidor'>
                <ButtonAddDistribuidor>
                  <StyledPlus />
                  añadir distribuidor
                </ButtonAddDistribuidor>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerButtons>
  );
};

export default Buttons;
