import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import usePaginate from './usePaginate';
import { getHistoryDealersHU1, getHistoryDealersHU2, getHistoryDealersHU3 } from '../services';
import { createBreadCrumb } from '../actions/breadCrumbActions';

const useHistory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useSelector((state) => state.history);
  const paginate = useSelector((state) => state.paginator);
  const paginateHU2 = useSelector((state) => state.paginatorHU.paginatorHU2);
  const paginateHU3 = useSelector((state) => state.paginatorHU.paginatorHU3);
  const user = useSelector((state) =>
    state.dataUser ? state.dataUser.user : null
  );
  const isLoading = useSelector((state) => state.loading);
  const { selectDealer } = useSelector((state) => state.rules);

  /* Busca un distribuidorpor id */
  const findDistribuidor = (stado, id) => {
    return stado.find((element) => element.codigo_extraccion === id);
  };

  const dealer = useSelector((state) =>
  findDistribuidor(state.dealers, id)
  );

  /* Permite cambiar de pagina hu1 */
  const { changePaginate } = usePaginate(paginate, () =>
    dispatch(getHistoryDealersHU1({ id, country: selectDealer.pais }))
  );

  /* Permite cambiar de pagina hu2 */
  const { changePaginateHU2 } = usePaginate(paginateHU2, () =>
    dispatch(getHistoryDealersHU2({ id, country: selectDealer.pais }))
  );

  /* Permite cambiar de pagina hu3 */
  const { changePaginateHU3 } = usePaginate(paginateHU3, () =>
    dispatch(getHistoryDealersHU3({ id, country: selectDealer.pais }))
  );

  /* Array con las rutas para breadCrumb(miga de pan) */
  const routes = [
    {
      id: 1,
      name: 'Inicio',
      path: '/'
    },
    {
      id: 2,
      name: `${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`,
      path: `/distribuidor/${selectDealer.codigo_extraccion}`
    },
    {
      id: 3,
      name: 'Historial',
      path: `/distribuidor/${selectDealer.codigo_extraccion}/historial`
    }
  ];

  /* Obtiene el historial por historia de usuario al cargar el componente asi mismo como la miga de pan */
  useEffect(() => {
    dispatch(getHistoryDealersHU1({ id, country: selectDealer.pais }));
    dispatch(getHistoryDealersHU2({ id, country: selectDealer.pais }));
    dispatch(getHistoryDealersHU3({ id, country: selectDealer.pais }));
    dispatch(createBreadCrumb(routes));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  return { user, dealer, id, history, paginate, paginateHU2, paginateHU3, changePaginate, changePaginateHU2, changePaginateHU3, isLoading };
};

export default useHistory;
