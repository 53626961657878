import styled from 'styled-components';
import { GoogleLogin } from 'react-google-login';
import { ReactComponent as SvgLogin } from '../../../images/Logo_Familia.svg';

const StyledLogin = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px -12px rgba(196, 196, 196, 0.35);
  border-radius: 5px;
  width: 100%;
  display: flex;
  padding: 3.125rem;
  flex-direction: column;
  height: 15.625rem;
  justify-content: center;
  align-items: center;
`;
const Header = styled.div`
  margin: 2.5rem 0;
  display: flex;
  position: relative;
  width: 100%;
`;

const SvgLogo = styled(SvgLogin)`
  height: 100%;
`;

const HeaderText = styled.label`
  font-size: 0.9rem;
  align-items: center;
  color: var(--greyDark);
  display: flex;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.05em;
  text-align: inherit;
  text-transform: uppercase;
  width: 100%;
  padding-left: 1.3rem;
  margin: 0;
`;
const ButtonGoogle = styled(GoogleLogin)`
background-color: var(--bluePrimary) !important;
color: #fff !important;
`;

const MyCustomButton = styled(GoogleLogin)`
background-color: var(--bluePrimary) !important;
color: #fff !important;
`;

export { StyledLogin, Header, SvgLogo, HeaderText, ButtonGoogle,MyCustomButton };
