export const data = {
  optionTypology: [
    { value: 'DTR', label: 'DTR', name: 'typology' },
    { value: 'CAD', label: 'CAD', name: 'typology' },
    { value: 'ECO', label: 'ECO', name: 'typology' }
  ],
  optionSociety: [
    { value: 'ALAC', label: 'ALAC', name: 'society' },
    { value: 'BOLV', label: 'BOLV', name: 'society' },
    { value: 'SANB', label: 'SANB', name: 'society' },
    { value: 'CHIL', label: 'CHIL', name: 'society' },
    { value: 'PFCL', label: 'PFCL', name: 'society' },
    { value: 'CBIA', label: 'CBIA', name: 'society' },
    { value: 'DIAM', label: 'DIAM', name: 'society' },
    { value: 'PFCO', label: 'PFCO', name: 'society' },
    { value: 'FACJ', label: 'FACJ', name: 'society' },
    { value: 'INPA', label: 'INPA', name: 'society' },
    { value: 'ECUA', label: 'ECUA', name: 'society' },
    { value: 'ECEF', label: 'ECEF', name: 'society' },
    { value: 'ECIP', label: 'ECIP', name: 'society' },
    { value: 'ELIM', label: 'ELIM', name: 'society' },
    { value: 'PERU', label: 'PERU', name: 'society' },
    { value: 'PFPE', label: 'PFPE', name: 'society' },
    { value: 'SANP', label: 'SANP', name: 'society' },
    { value: 'PRIC', label: 'PRIC', name: 'society' },
    { value: 'REDM', label: 'REDM', name: 'society' },
    { value: 'CONT', label: 'CONT', name: 'society' },
    { value: 'VENZ', label: 'VENZ', name: 'society' }
  ],
  schemeTypes: [
    { value: 'STRING', label: 'STRING', name: 'type' },
    { value: 'BYTES', label: 'BYTES', name: 'type' },
    { value: 'INTEGER', label: 'INTEGER', name: 'type' },
    { value: 'FLOAT', label: 'FLOAT', name: 'type' },
    { value: 'NUMERIC', label: 'NUMERIC', name: 'type' },
    { value: 'BIGNUMERIC', label: 'BIGNUMERIC', name: 'type' },
    { value: 'BOOLEAN', label: 'BOOLEAN', name: 'type' },
    { value: 'TIMESTAMP', label: 'TIMESTAMP', name: 'type' },
    { value: 'DATE', label: 'DATE', name: 'type' },
    { value: 'TIME', label: 'TIME', name: 'type' },
    { value: 'DATETIME', label: 'DATETIME', name: 'type' },
    { value: 'GEOGRAPHY', label: 'GEOGRAPHY', name: 'type' },
    { value: 'RECORD', label: 'RECORD', name: 'type' },
    { value: 'JSON', label: 'JSON', name: 'type' }
  ],
  schemeModes: [
    { value: 'NULLABLE', label: 'NULLABLE', name: 'mode' },
    { value: 'REQUIRED', label: 'REQUIRED', name: 'mode' },
    { value: 'REPEATED', label: 'REPEATED', name: 'mode' }
  ],
  rankpm: [
    { value: 0, label: 'Último día', name: 'selected_day' },
    { value: 1, label: 'Penúltimo día', name: 'selected_day' },
    { value: 2, label: 'Antepenúltimo día', name: 'selected_day' }
  ],
  optionCountries: [
    { value: 'AF', label: 'AF', name: 'country' },
    { value: 'AL', label: 'AL', name: 'country' },
    { value: 'DZ', label: 'DZ', name: 'country' },
    { value: 'AS', label: 'AS', name: 'country' },
    { value: 'AD', label: 'AD', name: 'country' },
    { value: 'AO', label: 'AO', name: 'country' },
    { value: 'AI', label: 'AI', name: 'country' },
    { value: 'AQ', label: 'AQ', name: 'country' },
    { value: 'AG', label: 'AG', name: 'country' },
    { value: 'AR', label: 'AR', name: 'country' },
    { value: 'AM', label: 'AM', name: 'country' },
    { value: 'AW', label: 'AW', name: 'country' },
    { value: 'AU', label: 'AU', name: 'country' },
    { value: 'AT', label: 'AT', name: 'country' },
    { value: 'AZ', label: 'AZ', name: 'country' },
    { value: 'BS', label: 'BS', name: 'country' },
    { value: 'BH', label: 'BH', name: 'country' },
    { value: 'BD', label: 'BD', name: 'country' },
    { value: 'BB', label: 'BB', name: 'country' },
    { value: 'BY', label: 'BY', name: 'country' },
    { value: 'BE', label: 'BE', name: 'country' },
    { value: 'BZ', label: 'BZ', name: 'country' },
    { value: 'BJ', label: 'BJ', name: 'country' },
    { value: 'BM', label: 'BM', name: 'country' },
    { value: 'BT', label: 'BT', name: 'country' },
    { value: 'BO', label: 'BO', name: 'country' },
    { value: 'BQ', label: 'BQ', name: 'country' },
    { value: 'BA', label: 'BA', name: 'country' },
    { value: 'BW', label: 'BW', name: 'country' },
    { value: 'BV', label: 'BV', name: 'country' },
    { value: 'BR', label: 'BR', name: 'country' },
    { value: 'IO', label: 'IO', name: 'country' },
    { value: 'BN', label: 'BN', name: 'country' },
    { value: 'BG', label: 'BG', name: 'country' },
    { value: 'BF', label: 'BF', name: 'country' },
    { value: 'BI', label: 'BI', name: 'country' },
    { value: 'CV', label: 'CV', name: 'country' },
    { value: 'KH', label: 'KH', name: 'country' },
    { value: 'CM', label: 'CM', name: 'country' },
    { value: 'CA', label: 'CA', name: 'country' },
    { value: 'KY', label: 'KY', name: 'country' },
    { value: 'CF', label: 'CF', name: 'country' },
    { value: 'TD', label: 'TD', name: 'country' },
    { value: 'CL', label: 'CL', name: 'country' },
    { value: 'CN', label: 'CN', name: 'country' },
    { value: 'CX', label: 'CX', name: 'country' },
    { value: 'CC', label: 'CC', name: 'country' },
    { value: 'CO', label: 'CO', name: 'country' },
    { value: 'KM', label: 'KM', name: 'country' },
    { value: 'CD', label: 'CD', name: 'country' },
    { value: 'CG', label: 'CG', name: 'country' },
    { value: 'CK', label: 'CK', name: 'country' },
    { value: 'CR', label: 'CR', name: 'country' },
    { value: 'HR', label: 'HR', name: 'country' },
    { value: 'CU', label: 'CU', name: 'country' },
    { value: 'CW', label: 'CW', name: 'country' },
    { value: 'CY', label: 'CY', name: 'country' },
    { value: 'CZ', label: 'CZ', name: 'country' },
    { value: 'CI', label: 'CI', name: 'country' },
    { value: 'DK', label: 'DK', name: 'country' },
    { value: 'DJ', label: 'DJ', name: 'country' },
    { value: 'DM', label: 'DM', name: 'country' },
    { value: 'DO', label: 'DO', name: 'country' },
    { value: 'EC', label: 'EC', name: 'country' },
    { value: 'EG', label: 'EG', name: 'country' },
    { value: 'SV', label: 'SV', name: 'country' },
    { value: 'GQ', label: 'GQ', name: 'country' },
    { value: 'ER', label: 'ER', name: 'country' },
    { value: 'EE', label: 'EE', name: 'country' },
    { value: 'SZ', label: 'SZ', name: 'country' },
    { value: 'ET', label: 'ET', name: 'country' },
    { value: 'FK', label: 'FK', name: 'country' },
    { value: 'FO', label: 'FO', name: 'country' },
    { value: 'FJ', label: 'FJ', name: 'country' },
    { value: 'FI', label: 'FI', name: 'country' },
    { value: 'FR', label: 'FR', name: 'country' },
    { value: 'GF', label: 'GF', name: 'country' },
    { value: 'PF', label: 'PF', name: 'country' },
    { value: 'TF', label: 'TF', name: 'country' },
    { value: 'GA', label: 'GA', name: 'country' },
    { value: 'GM', label: 'GM', name: 'country' },
    { value: 'GE', label: 'GE', name: 'country' },
    { value: 'DE', label: 'DE', name: 'country' },
    { value: 'GH', label: 'GH', name: 'country' },
    { value: 'GI', label: 'GI', name: 'country' },
    { value: 'GR', label: 'GR', name: 'country' },
    { value: 'GL', label: 'GL', name: 'country' },
    { value: 'GD', label: 'GD', name: 'country' },
    { value: 'GP', label: 'GP', name: 'country' },
    { value: 'GU', label: 'GU', name: 'country' },
    { value: 'GT', label: 'GT', name: 'country' },
    { value: 'GG', label: 'GG', name: 'country' },
    { value: 'GN', label: 'GN', name: 'country' },
    { value: 'GW', label: 'GW', name: 'country' },
    { value: 'GY', label: 'GY', name: 'country' },
    { value: 'HT', label: 'HT', name: 'country' },
    { value: 'HM', label: 'HM', name: 'country' },
    { value: 'VA', label: 'VA', name: 'country' },
    { value: 'HN', label: 'HN', name: 'country' },
    { value: 'HK', label: 'HK', name: 'country' },
    { value: 'HU', label: 'HU', name: 'country' },
    { value: 'IS', label: 'IS', name: 'country' },
    { value: 'IN', label: 'IN', name: 'country' },
    { value: 'ID', label: 'ID', name: 'country' },
    { value: 'IR', label: 'IR', name: 'country' },
    { value: 'IQ', label: 'IQ', name: 'country' },
    { value: 'IE', label: 'IE', name: 'country' },
    { value: 'IM', label: 'IM', name: 'country' },
    { value: 'IL', label: 'IL', name: 'country' },
    { value: 'IT', label: 'IT', name: 'country' },
    { value: 'JM', label: 'JM', name: 'country' },
    { value: 'JP', label: 'JP', name: 'country' },
    { value: 'JE', label: 'JE', name: 'country' },
    { value: 'JO', label: 'JO', name: 'country' },
    { value: 'KZ', label: 'KZ', name: 'country' },
    { value: 'KE', label: 'KE', name: 'country' },
    { value: 'KI', label: 'KI', name: 'country' },
    { value: 'KP', label: 'KP', name: 'country' },
    { value: 'KR', label: 'KR', name: 'country' },
    { value: 'KW', label: 'KW', name: 'country' },
    { value: 'KG', label: 'KG', name: 'country' },
    { value: 'LA', label: 'LA', name: 'country' },
    { value: 'LV', label: 'LV', name: 'country' },
    { value: 'LB', label: 'LB', name: 'country' },
    { value: 'LS', label: 'LS', name: 'country' },
    { value: 'LR', label: 'LR', name: 'country' },
    { value: 'LY', label: 'LY', name: 'country' },
    { value: 'LI', label: 'LI', name: 'country' },
    { value: 'LT', label: 'LT', name: 'country' },
    { value: 'LU', label: 'LU', name: 'country' },
    { value: 'MO', label: 'MO', name: 'country' },
    { value: 'MG', label: 'MG', name: 'country' },
    { value: 'MW', label: 'MW', name: 'country' },
    { value: 'MY', label: 'MY', name: 'country' },
    { value: 'MV', label: 'MV', name: 'country' },
    { value: 'ML', label: 'ML', name: 'country' },
    { value: 'MT', label: 'MT', name: 'country' },
    { value: 'MH', label: 'MH', name: 'country' },
    { value: 'MQ', label: 'MQ', name: 'country' },
    { value: 'MR', label: 'MR', name: 'country' },
    { value: 'MU', label: 'MU', name: 'country' },
    { value: 'YT', label: 'YT', name: 'country' },
    { value: 'MX', label: 'MX', name: 'country' },
    { value: 'FM', label: 'FM', name: 'country' },
    { value: 'MD', label: 'MD', name: 'country' },
    { value: 'MC', label: 'MC', name: 'country' },
    { value: 'MN', label: 'MN', name: 'country' },
    { value: 'ME', label: 'ME', name: 'country' },
    { value: 'MS', label: 'MS', name: 'country' },
    { value: 'MA', label: 'MA', name: 'country' },
    { value: 'MZ', label: 'MZ', name: 'country' },
    { value: 'MM', label: 'MM', name: 'country' },
    { value: 'NA', label: 'NA', name: 'country' },
    { value: 'NR', label: 'NR', name: 'country' },
    { value: 'NP', label: 'NP', name: 'country' },
    { value: 'NL', label: 'NL', name: 'country' },
    { value: 'NC', label: 'NC', name: 'country' },
    { value: 'NZ', label: 'NZ', name: 'country' },
    { value: 'NI', label: 'NI', name: 'country' },
    { value: 'NE', label: 'NE', name: 'country' },
    { value: 'NG', label: 'NG', name: 'country' },
    { value: 'NU', label: 'NU', name: 'country' },
    { value: 'NF', label: 'NF', name: 'country' },
    { value: 'MK', label: 'MK', name: 'country' },
    { value: 'MP', label: 'MP', name: 'country' },
    { value: 'NO', label: 'NO', name: 'country' },
    { value: 'OM', label: 'OM', name: 'country' },
    { value: 'PK', label: 'PK', name: 'country' },
    { value: 'PW', label: 'PW', name: 'country' },
    { value: 'PS', label: 'PS', name: 'country' },
    { value: 'PA', label: 'PA', name: 'country' },
    { value: 'PG', label: 'PG', name: 'country' },
    { value: 'PY', label: 'PY', name: 'country' },
    { value: 'PE', label: 'PE', name: 'country' },
    { value: 'PH', label: 'PH', name: 'country' },
    { value: 'PN', label: 'PN', name: 'country' },
    { value: 'PL', label: 'PL', name: 'country' },
    { value: 'PT', label: 'PT', name: 'country' },
    { value: 'PR', label: 'PR', name: 'country' },
    { value: 'QA', label: 'QA', name: 'country' },
    { value: 'RO', label: 'RO', name: 'country' },
    { value: 'RU', label: 'RU', name: 'country' },
    { value: 'RW', label: 'RW', name: 'country' },
    { value: 'RE', label: 'RE', name: 'country' },
    { value: 'BL', label: 'BL', name: 'country' },
    { value: 'SH', label: 'SH', name: 'country' },
    { value: 'KN', label: 'KN', name: 'country' },
    { value: 'LC', label: 'LC', name: 'country' },
    { value: 'MF', label: 'MF', name: 'country' },
    { value: 'PM', label: 'PM', name: 'country' },
    { value: 'VC', label: 'VC', name: 'country' },
    { value: 'WS', label: 'WS', name: 'country' },
    { value: 'SM', label: 'SM', name: 'country' },
    { value: 'ST', label: 'ST', name: 'country' },
    { value: 'SA', label: 'SA', name: 'country' },
    { value: 'SN', label: 'SN', name: 'country' },
    { value: 'RS', label: 'RS', name: 'country' },
    { value: 'SC', label: 'SC', name: 'country' },
    { value: 'SL', label: 'SL', name: 'country' },
    { value: 'SG', label: 'SG', name: 'country' },
    { value: 'SX', label: 'SX', name: 'country' },
    { value: 'SK', label: 'SK', name: 'country' },
    { value: 'SI', label: 'SI', name: 'country' },
    { value: 'SB', label: 'SB', name: 'country' },
    { value: 'SO', label: 'SO', name: 'country' },
    { value: 'ZA', label: 'ZA', name: 'country' },
    { value: 'GS', label: 'GS', name: 'country' },
    { value: 'SS', label: 'SS', name: 'country' },
    { value: 'ES', label: 'ES', name: 'country' },
    { value: 'LK', label: 'LK', name: 'country' },
    { value: 'SD', label: 'SD', name: 'country' },
    { value: 'SR', label: 'SR', name: 'country' },
    { value: 'SJ', label: 'SJ', name: 'country' },
    { value: 'SE', label: 'SE', name: 'country' },
    { value: 'CH', label: 'CH', name: 'country' },
    { value: 'SY', label: 'SY', name: 'country' },
    { value: 'TW', label: 'TW', name: 'country' },
    { value: 'TJ', label: 'TJ', name: 'country' },
    { value: 'TZ', label: 'TZ', name: 'country' },
    { value: 'TH', label: 'TH', name: 'country' },
    { value: 'TL', label: 'TL', name: 'country' },
    { value: 'TG', label: 'TG', name: 'country' },
    { value: 'TK', label: 'TK', name: 'country' },
    { value: 'TO', label: 'TO', name: 'country' },
    { value: 'TT', label: 'TT', name: 'country' },
    { value: 'TN', label: 'TN', name: 'country' },
    { value: 'TR', label: 'TR', name: 'country' },
    { value: 'TM', label: 'TM', name: 'country' },
    { value: 'TC', label: 'TC', name: 'country' },
    { value: 'TV', label: 'TV', name: 'country' },
    { value: 'UG', label: 'UG', name: 'country' },
    { value: 'UA', label: 'UA', name: 'country' },
    { value: 'AE', label: 'AE', name: 'country' },
    { value: 'GB', label: 'GB', name: 'country' },
    { value: 'UM', label: 'UM', name: 'country' },
    { value: 'US', label: 'US', name: 'country' },
    { value: 'UY', label: 'UY', name: 'country' },
    { value: 'UZ', label: 'UZ', name: 'country' },
    { value: 'VU', label: 'VU', name: 'country' },
    { value: 'VE', label: 'VE', name: 'country' },
    { value: 'VN', label: 'VN', name: 'country' },
    { value: 'VG', label: 'VG', name: 'country' },
    { value: 'VI', label: 'VI', name: 'country' },
    { value: 'WF', label: 'WF', name: 'country' },
    { value: 'EH', label: 'EH', name: 'country' },
    { value: 'YE', label: 'YE', name: 'country' },
    { value: 'ZM', label: 'ZM', name: 'country' },
    { value: 'ZW', label: 'ZW', name: 'country' },
    { value: 'AX', label: 'AX', name: 'country' }
  ]
};

export const getData = () => data;
