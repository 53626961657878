import { ContainerCuonter, Button, Count } from './Styles';

/* Componente contador para añadir o eliminar */
/*
 * @param  {} count: contiene el contador
 * @param  {} handleAdd: handle para agregar +1
 * @param  {} handleMinus: handle para eliminar -1
 */
const Counter = ({ count, handleAdd, handleMinus }) => {
  return (
    <ContainerCuonter>
      <Button onClick={handleMinus}>
        <span>
          <i className='fas fa-minus' />
        </span>
      </Button>
      <Count>{count}</Count>
      <Button onClick={handleAdd}>
        <span>
          <i className='fas fa-plus' />
        </span>
      </Button>
    </ContainerCuonter>
  );
};

export default Counter;
