import React from "react";
import EmptyRulesMessage from "../../Ruless/EmptyRulesMessage/EmptyRulesMessage";
import CardRule from "../CardRule/CardRule";
import {
  ContainerCard,
  CardTitle,
  StyledButton,
  GridCards,
  StyledButtonEdit,
  StyledButtonRun,
  CardButtons,
} from "./Styles";

/* Componente padre que contiene las 3 historias de usuario  */

/*
 * @param  {} rulesDealer: contiene reglas por historia de usuario HU1
 * @param  {} rulesDealerHU2: contiene reglas por historia de usuario HU2
 * @param  {} rulesDealerHU3: contiene reglas por historia de usuario HU3
 * @param  {} id
 * @param  {} userStories: contiene el nombre de las historias de usuario
 * @param  {} openModal: funcion que abre el modal al querer reasignar reglas y estas estan ya asignadas
 * @param  {} redirectToRules: accion que te redirecciona al contenedor de asignacion de reglas
 */
const Card = ({
  rulesDealer,
  rulesDealerHU2,
  rulesDealerHU3,
  id,
  userStories,
  openModal,
  redirectToRules,
  redirectToRulesEdit,
  handleClickExecutionHU1,
  handleClickExecutionHU2,
  handleClickExecutionHU3
}) => {
  return (
    <>
      <ContainerCard id='containerCard' error={!rulesDealer.rules?.length}>
        <CardTitle>
          <h5>{userStories.hu1}</h5>
          <CardButtons>
            {rulesDealer.rules?.length > 0 ? (
              <>
                <StyledButtonRun
                  onClick={() => handleClickExecutionHU1()}
                >
                  EJECUTAR REGLAS
                </StyledButtonRun>
                <StyledButtonEdit
                  onClick={() => redirectToRulesEdit({ id, hu: "hu1" })}
                >
                  EDITAR REGLAS
                </StyledButtonEdit>
              </>
            ) : (
              <></>
            )}
            <StyledButton
              onClick={() =>
                !rulesDealer.rules?.length > 0
                  ? redirectToRules(id, "hu1")
                  : openModal({ id, hu: "hu1" })
              }
            >
              ASIGNAR REGLAS
            </StyledButton>
          </CardButtons>
        </CardTitle>
        {rulesDealer.rules?.length > 0 ? (
          <GridCards>
            {rulesDealer.rules.map((item, index) => {
              return (
                <CardRule
                  key={item.name + index}
                  name={item.name}
                  position={index}
                />
              );
            })}
          </GridCards>
        ) : (
          <EmptyRulesMessage />
        )}
      </ContainerCard>
      <ContainerCard error={!rulesDealerHU2.rules?.length}>
        <CardTitle>
          <h5>{userStories.hu2}</h5>
          <CardButtons>
            {rulesDealerHU2.rules?.length > 0 ? (
              <>
                <StyledButtonRun
                  onClick={() => handleClickExecutionHU2()}
                >EJECUTAR REGLAS</StyledButtonRun>
                <StyledButtonEdit
                  onClick={() => redirectToRulesEdit({ id, hu: "hu2" })}
                >
                  EDITAR REGLAS
                </StyledButtonEdit>
              </>
            ) : (
              <></>
            )}
            <StyledButton
              onClick={() =>
                !rulesDealerHU2.rules?.length > 0
                  ? redirectToRules(id, "hu2")
                  : openModal({ id, hu: "hu2" })
              }
            >
              {" "}
              ASIGNAR REGLAS{" "}
            </StyledButton>
          </CardButtons>
        </CardTitle>
        {rulesDealerHU2.rules?.length > 0 ? (
          <GridCards>
            {rulesDealerHU2.rules.map((item, index) => {
              return (
                <CardRule
                  key={item.name + index}
                  name={item.name}
                  position={index}
                />
              );
            })}
          </GridCards>
        ) : (
          <EmptyRulesMessage />
        )}
      </ContainerCard>
      <ContainerCard error={!rulesDealerHU3.rules?.length}>
        <CardTitle>
          <h5>{userStories.hu3}</h5>
          <CardButtons>
            {rulesDealerHU3.rules?.length > 0 ? (
              <>
                <StyledButtonRun
                  onClick={() => handleClickExecutionHU3()}
                >EJECUTAR REGLAS</StyledButtonRun>
                <StyledButtonEdit
                  onClick={() => redirectToRulesEdit({ id, hu: "hu3" })}
                >
                  EDITAR REGLAS
                </StyledButtonEdit>
              </>
            ) : (
              <></>
            )}
            <StyledButton
              onClick={() =>
                !rulesDealerHU3.rules?.length > 0
                  ? redirectToRules(id, "hu3")
                  : openModal({ id, hu: "hu3" })
              }
            >
              ASIGNAR REGLAS
            </StyledButton>
          </CardButtons>
        </CardTitle>
        {rulesDealerHU3.rules?.length > 0 ? (
          <GridCards>
            {rulesDealerHU3.rules?.length > 0
              ? rulesDealerHU3.rules.map((item, index) => {
                  return (
                    <CardRule
                      key={item.name + index}
                      name={item.name}
                      position={index}
                    />
                  );
                })
              : null}
          </GridCards>
        ) : (
          <EmptyRulesMessage />
        )}
      </ContainerCard>
    </>
  );
};

export default Card;
