import React from 'react';
import PropTypes from 'prop-types';
import SwitchToggle from '../SwitchToggle/SwitchToggle';
import { Link } from 'react-router-dom';
import { Tr, ButtonEditar } from './Styles';


/* elemento de la tabla de distribuidores que tiene nombre, modificacion, editar, pais, estatus */
/*
 * @param  {} id
 * @param  {} nameDistribuidor: contiene el nombre del distribuidor
 * @param  {} lastModification: contiene la ultima modificacion
 * @param  {} rules: contiene las reglas de ese distribuidor (deprecado)
 * @param  {} status: contiene si el estado del distribuidor es activo o inactivo
 * @param  {} country: contiene el pais del distribuidor
 * @param  {} handleChangeStatus: handle que cambia el estado activo o inactivo
 * @param  {} configEdit: 
 */
const ElementTable = ({
  id,
  nameDistribuidor,
  lastModification,
  rules,
  status,
  country,
  handleChangeStatus,
  configEdit
}) => {
  const { handleEditItem, dispatch, getCurrentId, handleSeletRule } =
    configEdit;
  return (
    <Tr>
      <td>
        <Link
          to={`/distribuidor/${id}`}
          onClick={() => handleSeletRule({ id, country })}
        >
          <p>{nameDistribuidor}</p>
        </Link>
        <span>{lastModification}</span>
        <ButtonEditar
          id={id}
          to='actualizar-distribuidor'
          onClick={() => {
            dispatch(getCurrentId({ id }));
            handleEditItem({ id, country });
          }}
        >
          Editar
        </ButtonEditar>
      </td>
      <td>{country}</td>
      <td>{rules}</td>
      <td>
        <SwitchToggle
          status={status}
          id={id}
          country={country}
          handleChangeStatus={handleChangeStatus}
        />
      </td>
    </Tr>
  );
};

ElementTable.propTypes = {
  id: PropTypes.string,
  nameDistribuidor: PropTypes.string,
  lastModification: PropTypes.string,
  rules: PropTypes.string,
  state: PropTypes.bool
};

export default ElementTable;
