import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createBreadCrumb } from '../actions/breadCrumbActions';

const useDetailsRule = () => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.history);
  const isLoading = useSelector((state) => state.loading);
  const { selectDealer } = useSelector((state) => state.rules);
  const { id, date, hu } = useParams();
  const user = useSelector((state) =>
    state.dataUser ? state.dataUser.user : null
  );

  /* Array con las rutas para breadCrumb(miga de pan) */
  const routes = [
    {
      id: 1,
      name: 'Inicio',
      path: '/'
    },
    {
      id: 2,
      name: `${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`,
      path: `/distribuidor/${selectDealer.codigo_extraccion}`
    },
    {
      id: 3,
      name: 'Historial',
      path: `/distribuidor/${selectDealer.codigo_extraccion}/historial`
    },
    {
      id: 4,
      name: 'Detalle',
      path: `/distribuidor/${selectDealer.codigo_extraccion}/historial/${hu}/detalle-regla/${date}`
    }
  ];

  /* Encuentra la fecha por cada historia de usuario */
  const singleHistory = history[`history_${hu}`].find(
    (element) => element.created_at === date
  );

  /* Ejecuta la miga de pan al cargar el componente */
  useEffect(() => {
    dispatch(createBreadCrumb(routes));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /* Devolvemos todos los datos que consumiremos. */
  return {
    user,
    selectDealer,
    id,
    history,
    singleHistory,
    hu,
    isLoading
  };
};

export default useDetailsRule;
