import styled from 'styled-components';

const TitleText = styled.h4`
  color: #454545;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 1.125rem 0 2rem 0;
`;
const TaskNull = styled.div`
  background: #f5f5f5;
  border-radius: 0.3125rem;
  border: 0.125rem dashed #c4c4c4;
  box-sizing: border-box;
  height: 8.0625rem;
  padding: 0.9375rem 1.0831rem 1.1875rem 1.1469rem;
  width: 100%;
`;
const List = styled.div``;

export { TitleText, TaskNull, List };