import { SET_RESPONSE, RESET_RESPONSE } from '../constants/actionTypes';
const initial = {
  status: '',
  message: '',
  show: false,
  redirect: ''
};
export const responseReducer = (state = initial, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_RESPONSE:
      return { ...Object.assign({}, state, { ...payload }) };
    case RESET_RESPONSE:
      return { ...Object.assign({}, state, { ...initial }) };
    default:
      return state;
  }
};
