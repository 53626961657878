import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { loadState, saveState } from './localstorage';
import reducers from '../reducers';
import { data } from 'src/utils/data';
export const emptyState = {
  dealers: [],
  addDealer: {
    nameDist: '',
    codeDist: '',
    country: '',
    typology: '',
    society: '',
    response: { status: 0 }
  },
  getDataForm: {
    optionCountries: [...data.optionCountries],
    optionTypology: [...data.optionTypology],
    schemeTypes: [...data.schemeTypes],
    schemeModes: [...data.schemeModes],
    optionSociety: [...data.optionSociety],
    rankPm: [...data.rankpm]
  },
  paginator: {
    size: 10,
    page: 1,
    total: 0
  },
  rules: {
    allRules: [],
    selectDealer: {},
    newRules: [],
    response: {},
    rulesDealer: [],
    rulesDealerHU2: [],
    rulesDealerHU3: [],
    selectedRules: []
  },
  schemas: [],
  schema: {
    shema_name: '',
    fields: [{ name: '', type: '', mode: '' }]
  },
  dataUser: {},
  response: {
    status: '',
    message: '',
    show: false,
    redirect: ''
  },
  responseModal: {
    title: 'Modal',
    message: '',
    buttons: [],
    show: false,
    click: ''
  },
  sortDealer: 'codigo_ascendente'
};
const initialState = loadState() || emptyState;
const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);
store.subscribe(() => saveState(store.getState()));
