import styled from 'styled-components';

const ContainerCard = styled.div`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #0c3967;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 2.125rem 2.4375rem 2.5625rem 2.5rem;
  width: 100%;
  margin-bottom: 1.375rem;
`;

const CardTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  h5 {
    margin: 0;
  }
`;

const GridCards = styled.div`
  column-gap: 0.4375rem;
  display: grid;
  grid-template-columns: repeat(4, 16.1875rem);
  margin: 2.25rem 0 0 0;
  row-gap: 0.4375rem;
`;

export { ContainerCard, CardTitle, GridCards };