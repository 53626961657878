import React from 'react';
import PropTypes from 'prop-types';
import { Checkmark, Label } from './Styles';


/*
 * @param  {} checked: Contiene el estado si esta o no seleccionado
 * @param  {} regla: contiene el estado de la regla
 * @param  {} handleSubmit: handle del formulario 
 * @param  {} handleFnClick: handle del click
 */
const Checkbox = ({ checked, regla, handleSubmit, handleFnClick }) => {
  return (
    <form>
      <Label onSubmit={(e) => handleSubmit(e)}>
        <input
          onChange={(e) => handleFnClick(e, regla.name)}
          value='checkbox'
          type='checkbox'
          checked={checked || false}
        />
        <Checkmark />
      </Label>
    </form>

  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool
};

export default Checkbox;
