import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Head from '../components/Distributor/Head/Head';
import CreateScheme from '../components/Schemas/CreateScheme/CreateScheme';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import Modal from '../utils/Modal/Modal';
import { useCreteSchema } from '../hooks/useCreateSchema';
import FullPageLoader from '../components/FullPageLoader/FullPageLoader';


/* Contenedor para la creacion de esquemas */
const CreateSchema = () => {
  const {
    showAlert,
    optionCountries,
    optionTypology,
    handleChange,
    handleAlert,
    handleSubmit,
    handleSave,
    handleOnBlur,
    error,
    addScheme,
    getTitle,
    messagError,
    schemeTypes,
    schemeModes,
    handleAdd,
    removeRow,
    disabledInput,
    user,
    isLoading
  } = useCreteSchema();
  return (
    <>
      <Header user={user} />
      <Container className='p-0'>
        <Head title={getTitle.name} subtitle='' />
        <CreateScheme
          showAlert={showAlert}
          optionCountries={optionCountries}
          optionTypology={optionTypology}
          handleChange={handleChange}
          handleAlert={handleAlert}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleOnBlur={handleOnBlur}
          error={error}
          addScheme={addScheme}
          getTitle={getTitle}
          messagError={messagError}
          schemeTypes={schemeTypes}
          schemeModes={schemeModes}
          handleAdd={handleAdd}
          removeRow={removeRow}
          disabledInput={disabledInput}
        />
      </Container>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
      <Modal />
    </>
  );
};

export default CreateSchema;
