import {
  GET_DEALERS,
  SET_STATE_DEALER
} from '../constants/actionTypes';

export const dealersReducer = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DEALERS: {
      return payload;
    }
    case SET_STATE_DEALER: {
      const copyDealers = [...state];
      copyDealers.map((element) =>
      (element.codigo_extraccion === payload.id
        ? element.estado = payload.estado
        : element.estado)
      );
      return copyDealers;
    }
    default:
      return state;
  }
};
