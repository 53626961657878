import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox/Checkbox';
import { Title, SubTitle, Description, Rule } from './Styles';

/* Componente que contiene el checkbox, el nombre y una breve descripcion de la seleccion de regla  */
/* 
 * @param  {} reglas: Contiene el estado con las reglas
 * @param  {} handleSubmit: handle del formulario
 * @param  {} handleFnClick: handle del click
 */
const RulesGroup = ({ reglas, handleSubmit, handleFnClick }) => {
  return (
    <Row xs={1} sm={2} md={4}>
      {reglas.map((regla, index) => (
        <Col key={regla.name + index}>
          <Rule>
            <div>
              <Checkbox
                handleFnClick={handleFnClick}
                handleSubmit={handleSubmit}
                regla={regla}
                /* checked={regla.selected} */
                checked={regla?.check}
              />
            </div>
            <div>
              <Title>{regla.name}</Title>
              <SubTitle>
                Regla{regla.parametrizable ? ' ' : ' NO '}parametrizable
              </SubTitle>
              <Description>{regla.short_description}</Description>
            </div>
          </Rule>
        </Col>
      ))}
    </Row>
  );
};

RulesGroup.propTypes = {
  reglas: PropTypes.array
};

export default RulesGroup;
