import React from 'react';
import ReactDOM from 'react-dom';
import useModal from '../../hooks/useModal';
import { Background, ModalWrapper, HeadModal, SdIoClose, ContainerButtons, Button } from './Styles';

/* Componente modal */
const Modal = () => {
  const { responseModal, handleClose, modalOnClick } = useModal();
  return ReactDOM.createPortal(
    responseModal.show ? (
      <Background>
        <ModalWrapper>
          <HeadModal>
            <h2>{responseModal.title}</h2>
            <SdIoClose onClick={handleClose} />
          </HeadModal>
          <p>{responseModal.message}</p>
          <ContainerButtons>
            {responseModal.buttons.map((button) => (
              <Button
                key={button.text}
                type={button.type}
                onClick={() =>
                  modalOnClick({
                    buttonName: button.text,
                    fnClose: button?.close
                  })}
              >
                {' '}
                {button.text}{' '}
              </Button>
            ))}
          </ContainerButtons>
        </ModalWrapper>
      </Background>
    ) : (
      <div />
    ),
    document.getElementById('root')
  );
};

export default Modal;
