import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ButtonLink = styled(Link)`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #009ce7;
  box-sizing: border-box;
  margin: 0 0.625rem;
  padding: 0.625rem 0;
  width: 5.3rem;
  font-weight: 700;
  font-size: 14px;
  color: var(--blueDark);
  transition: color ease-in-out 150ms;
  &:hover {
    background: var(--blueDark);
    border-color: var(--blueDark);
    color: #fff;
    text-decoration: none;
  }
`;
const Button = styled.button`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #009ce7;
  box-sizing: border-box;
  margin: 0 0.625rem;
  height: 2.875rem;
  width: 5.3rem;
  font-weight: 700;
  font-size: 14px;
  color: var(--blueDark);
  transition: color ease-in-out 150ms;
  &:hover {
    background: var(--blueDark);
    border-color: var(--blueDark);
    color: #fff;
  }
`;
export { ButtonLink, Button };