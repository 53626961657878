import React from 'react';
import { Col } from 'react-bootstrap';
import CardRule from '../../../Distributor/CardRule/CardRule';
import { GridDropZone, GridItem } from 'react-grid-dnd';
import { TitleText, TaskNull, List } from './Styles';

/* Componente renderiza cada una de las reglas en un elemento draggable */
/*
 * @param  {} items: listado de las reglas
 * @param  {} id: orden o reglas
 */
function ListingRules({ items, id }) {
  const validateOrden = id === 'orden' ? 1 : 0;
  const heightDrop = Math.ceil((items.length + validateOrden) / 4) * 150;
  return (
    <>
      <TitleText>
        {/* eslint-disable-next-line eqeqeq */}
        {id == 'orden' ? 'Reglas ordenadas' : 'Reglas seleccionadas' } 
      </TitleText>
      <List>
        <GridDropZone
          className={'dropzone ' + id}
          id={id}
          boxesPerRow={4}
          rowHeight={150}
          style={{ minHeight: heightDrop, transition: 'width 2s' }}
        >
          {items.map((item, k) => (
            <GridItem key={item.id ? item.id : item.name}>
              <Col>
                <CardRule
                  name={item.name}
                  position={k}
                  description={item.short_description}
                />
              </Col>
            </GridItem>
          ))}
          {id === 'orden' && (
            <GridItem key='orden'>
              <Col>
                <TaskNull />
              </Col>
            </GridItem>
          )}
        </GridDropZone>
      </List>
    </>
  );
}

ListingRules.propTypes = {};

export default ListingRules;
