import React from 'react';
import PropTypes from 'prop-types';
import { SwitchForm, ForCheck } from './Styles';

/* componente para cambiar a activo o inactivo el estado del distribuidor */
/*
 * @param  {} checked=false: booleano si esta checkeado o no
 * @param  {} id
 * @param  {} handleChangeStatus: handle para cambiar el estatus
 * @param  {} country: contiene el pais del distribidor
 */
const Switch = ({ checked = false, id, handleChangeStatus, country }) => {
  return (
    <SwitchForm>
      <ForCheck
        type='switch'
        id={`${id}-${country}`}
        checked={checked}
        onChange={() =>
          handleChangeStatus({
            country,
            id,
            estado: !checked
          })}
      />
    </SwitchForm>
  );
};

Switch.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool
};

export default Switch;
