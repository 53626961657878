import { GET_PAGINATE, SET_PAGINATE_PAGE, RESET_PAGINATE } from '../constants/actionTypes';
const initialState = {
  size: 10,
  page: 0,
  total: 0
};
export const paginatorReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PAGINATE: {
      return { ...state, ...Object.assign({}, payload) };
    }
    case SET_PAGINATE_PAGE: {
      return { ...state, ...Object.assign({}, payload) };
    }
    case RESET_PAGINATE: {
      return { ...state, ...Object.assign({}, initialState) };
    }

    default:
      return state;
  }
};
