export const GET_DEALERS = "GET_DEALERS";
// export const GET_DISTRIBUIDOR_BY_ID = 'GET_DISTRIBUIDOR_BY_ID'
export const SET_STATE_DEALER = "SET_STATE_DEALER";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_INPUT_DATA = "SET_INPUT_DATA";
export const SET_INPUT_SELECT = "SET_INPUT_SELECT";
export const SET_ADD_DEALER = "SET_ADD_DEALER";
export const RESET = "RESET";
export const GET_INPUT_DATA = "GET_INPUT_DATA";
export const SET_UPDATE_DEALER = "SET_UPDATE_DEALER";
export const GET_CURRENT_ID = "GET_CURRENT_ID";
export const GET_PAGINATE = "GET_PAGINATE";
export const SET_PAGINATE_PAGE = "SET_PAGINATE_PAGE";
export const GET_HISTORY_DEALER_HU1 = "GET_HISTORY_DEALER_HU1";
export const GET_RULES_ITEMS = "GET_RULES_ITEMS";
export const GET_SELECT_DEALER = "GET_SELECT_DEALER";
export const GET_SCHEME_ITEMS = "GET_SCHEME_ITEMS";
export const GET_LOGIN_GOOGLE = "GET_LOGIN_GOOGLE";
export const GET_TOKEN = "GET_TOKEN";
export const SET_RESPONSE = "SET_RESPONSE";
export const RESET_RESPONSE = "RESET_RESPONSE";
export const GET_SELECT_CHECKED = "GET_SELECT_CHECKED";
export const RESET_PAGINATE = "RESET_PAGINATE";
export const CREATE_BREADCRUMB = "CREATE_BREADCRUMB";
export const SELECT_BREADCRUMB = "SELECT_BREADCRUMB";
export const GO_BREADCRUMB_ROUTE = "GO_BREADCRUMB_ROUTE";
export const ADD_ROW_SCHEME = "ADD_ROW_SCHEME";
export const REMOVE_ROW_SCHEME = "REMOVE_ROW_SCHEME";
export const SET_NAME_SCHEME = "SET_NAME_SCHEME";
export const SET_FIELD_SCHEME = "SET_FIELD_SCHEME";
export const GET_SCHEME = "GET_SCHEME";
export const GET_RULES_DEALER_HU1 = "GET_RULES_DEALER_HU1";
export const SET_SORT_DEALER = "SET_SORT_DEALER";
export const SET_RULES_ORDER = "SET_RULES_ORDER";
export const SET_PARAMETER_RULE = "SET_PARAMETER_RULE";
export const RESET_RULES = "RESET_RULES";
export const SET_RESPONSE_MODAL = "SET_RESPONSE_MODAL";
export const RESET_RESPONSE_MODAL = "RESET_RESPONSE_MODAL";
export const CLICK_RESPONSE_MODAL = "CLICK_RESPONSE_MODAL";
export const GET_RULES_DEALER_HU2 = "GET_RULES_DEALER_HU2";
export const GET_RULES_DEALER_HU3 = "GET_RULES_DEALER_HU3";
export const GET_HISTORY_DEALER_HU2 = "GET_HISTORY_DEALER_HU2";
export const GET_HISTORY_DEALER_HU3 = "GET_HISTORY_DEALER_HU3";
export const SET_LOADING = "SET_LOADING";
export const SET_PAGINATE_PAGE_HU2 = "SET_PAGINATE_PAGE_HU2";
export const SET_PAGINATE_PAGE_HU3 = "SET_PAGINATE_PAGE_HU3";
export const GET_PAGINATE_HU2 = "GET_PAGINATE_HU2";
export const GET_PAGINATE_HU3 = "GET_PAGINATE_HU3";
export const LOAD_RULES_EDIT = "LOAD_RULES_EDIT";
