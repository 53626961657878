import { GET_PAGINATE, GET_PAGINATE_HU2, GET_PAGINATE_HU3, SET_PAGINATE_PAGE, SET_PAGINATE_PAGE_HU2, SET_PAGINATE_PAGE_HU3, RESET_PAGINATE } from '../constants/actionTypes';

export const getPaginate = (payload) => ({
  type: GET_PAGINATE,
  payload
});
export const getPaginateHU2 = (payload) => ({
  type: GET_PAGINATE_HU2,
  payload
});
export const getPaginateHU3 = (payload) => ({
  type: GET_PAGINATE_HU3,
  payload
});
export const setPaginatePage = (payload) => ({
  type: SET_PAGINATE_PAGE,
  payload
});
export const setPaginatePageHU2 = (payload) => ({
  type: SET_PAGINATE_PAGE_HU2,
  payload
});
export const setPaginatePageHU3 = (payload) => ({
  type: SET_PAGINATE_PAGE_HU3,
  payload
});
export const resetPaginate = (payload) => ({
  type: RESET_PAGINATE,
  payload
});
