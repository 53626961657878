import {
  GET_HISTORY_DEALER_HU1, GET_HISTORY_DEALER_HU2, GET_HISTORY_DEALER_HU3
} from '../constants/actionTypes';

const initialState = {
  history_hu1: [],
  history_hu2: [],
  history_hu3: []
};

export const historyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_HISTORY_DEALER_HU1: {
      return {
        ...state,
        history_hu1: [...payload]
      };
    }
    case GET_HISTORY_DEALER_HU2: {
      return {
        ...state,
        history_hu2: [...payload]
      };
    }
    case GET_HISTORY_DEALER_HU3: {
      return {
        ...state,
        history_hu3: [...payload]
      };
    }
    default:
      return state;
  }
};
