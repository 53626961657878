import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScheme, deleteScheme } from '../services';
import usePaginate from './usePaginate';
import { resetPaginate } from '../actions/paginatorActions';
import { createBreadCrumb } from '../actions/breadCrumbActions';
import { setResponseModal } from 'src/actions/responseModalActions';

const messageModal = {
  title: 'Eliminar esquema',
  message:
    '¿Está seguro de que desea eliminar este esquema? Al hacer esto no se podran recuperar los datos.',
  buttons: [
    {
      text: 'CANCELAR',
      type: 'normal'
    },
    {
      text: 'ELIMINAR',
      type: 'danger'
    }
  ],
  show: false
};

const useSchemas = () => {
  const dispatch = useDispatch();
  const schemas = useSelector((state) => state.schemas);
  const paginate = useSelector((state) => state.paginator);
  const responseModal = useSelector((state) => state.responseModal);
  const isLoading = useSelector((state) => state.loading);
  const user = useSelector((state) =>
  state.dataUser ? state.dataUser.user : null
  );
  const [, setShowModal] = useState(false);
  const [schemeName, setSchemeName] = useState(null);
  const { changePaginate } = usePaginate(paginate, () => dispatch(getScheme()));
  
  /* Array con las rutas para breadCrumb(miga de pan) */
  const rutas = [
    {
      id: 1,
      name: 'Inicio',
      path: '/',
      exact: true
    },
    {
      id: 2,
      name: 'Esquema de tablas',
      path: '/esquemas',
      exact: true
    }
  ];

  const hasButton = {
    text: 'Crear Esquema',
    rute: 'esquemas/crear'
  };

  /* Activa el modal */
  const handleModal = (name = null) => {
    setSchemeName(name);
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
    setShowModal((prev) => !prev);
  };

  /* te confirma si quieres borrar el esquema */
  const confirmDelete = () => {
    if (schemeName) {
      dispatch(deleteScheme({ name: schemeName.name }));
    }
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
    setShowModal((prev) => !prev);
    setSchemeName(null);
  };

  /* Al cargar el componente obtiene el esquema y la miga de pan */
  useEffect(() => {
    dispatch(resetPaginate());
    dispatch(getScheme());
    dispatch(createBreadCrumb(rutas));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  
  /* Funcion del modal que permite eliminar o cancelar */
  const onClickModal = (name) => {
    switch (name) {
      case 'ELIMINAR':
        confirmDelete();
        break;
      case 'CANCELAR':
        handleModal();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (responseModal.click) {
      onClickModal(responseModal.click);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseModal]);
  
  /* Devolvemos todos los datos que consumiremos. */
  return {
    user,
    hasButton,
    handleModal,
    schemas,
    paginate,
    changePaginate,
    isLoading
  };
};
export default useSchemas;
