import styled from 'styled-components';

const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  p,
  span {
    font-weight: 300;
    margin: 0;
    font-size: 0.625rem;
  }
  span {
    text-decoration: uppercase;
  }
`;
export { ContainerSwitch };