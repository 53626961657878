import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRulesByDealerHU1,
  getRulesByDealerHU2,
  getRulesByDealerHU3,
  postRuleExecutionHU1,
  postRuleExecutionHU2,
  postRuleExecutionHU3
} from "../services/index";
import { useHistory, useParams } from "react-router-dom";
import { createBreadCrumb } from "../actions/breadCrumbActions";
import { resetPaginate } from "../actions/paginatorActions";
import { setResponseModal } from "../actions/responseModalActions";
const messages = {
  title:
    "¿Está seguro de que quiere asignar nuevamente reglas a este distribuidor?",
  message:
    "Al hacer esto deberá repetir el proceso de selección, ordenamiento y parametrización de las reglas asignadas al distribuidor.",
  buttons: [
    {
      text: "CANCELAR",
      type: "normal",
    },
    {
      text: "ASIGNAR REGLAS",
      type: "primary",
    },
  ],
};
const useDealer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [lastUpdate, setLastUpdate] = useState({});
  const [ruteCreateRules, setRuteCreateRules] = useState({ id: "", hu: "" });
  const { selectDealer, rulesDealerHU2, rulesDealerHU3, rulesDealer } =
    useSelector((state) => state.rules);
  const user = useSelector((state) =>
    state.dataUser ? state.dataUser.user : null
  );
  const isLoading = useSelector((state) => state.loading);
  const responseModal = useSelector((state) => state.responseModal);
  const string = history.location.pathname;
  const path = string.slice(1, string.length);
  /*  Array con las rutas para breadCrumb(miga de pan) */
  const routes = [
    {
      id: 1,
      name: "Inicio",
      path: "/",
    },
    {
      id: 2,
      name: `${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`,
      path: `/distribuidor/${selectDealer.codigo_extraccion}`,
    },
  ];

  const sortDesc = (a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  };
  /* Funcion que obtiene la ultima fecha */
  const getLastDate = () => {
    const huList = [...rulesDealer, ...rulesDealerHU2, ...rulesDealerHU3];
    const last = huList.sort(sortDesc);
    setLastUpdate(last[0]);
  };
  /* Funcion que abre el modal */
  const openModal = ({ id = null, hu = null }) => {
    setRuteCreateRules((prev) => ({ ...prev, id, hu }));
    dispatch(
      setResponseModal({
        ...messages,
        show: !responseModal.show,
        click: null,
      })
    );
  };

  /* Funcion que te lleva a la asignacion de reglas por historia de usuario */
  const goToCreateRules = () => {
    const { id, hu } = ruteCreateRules;
    dispatch(
      setResponseModal({
        ...messages,
        show: !responseModal.show,
        click: null,
      })
    );
    history.push(`/distribuidor/${id}/reglas/${hu}`);
  };

  /* Funcion que te redirecciona a la asignacion de reglas por historia de usuario */
  const redirectToRules = (id, hu) => {
    history.push(`/distribuidor/${id}/reglas/${hu}`);
  };
  /* Funcion para redireccionar a edicion de reglas */
  const redirectToRulesEdit = ({id, hu}) => {
    history.push(`/distribuidor/${id}/reglas/${hu}/editar`);
  };

  /* Funcion que activa el modal para mostrar el mensaje al ejecutar las reglas del HU1 */
  const handleClickExecutionHU1 = () => {
    dispatch(postRuleExecutionHU1({
      data:`${selectDealer.pais}-${selectDealer.sociedad}-${selectDealer.tipologia}-${selectDealer.codigo_extraccion}`
    }));
  }

  /* Funcion que activa el modal para mostrar el mensaje al ejecutar las reglas del HU2 */
  const handleClickExecutionHU2 = () => {
    dispatch(postRuleExecutionHU2({
      data:`${selectDealer.pais}-${selectDealer.sociedad}-${selectDealer.tipologia}-${selectDealer.codigo_extraccion}`
    }));
  }

  /* Funcion que activa el modal para mostrar el mensaje al ejecutar las reglas del HU3 */
  const handleClickExecutionHU3 = () => {
    dispatch(postRuleExecutionHU3({
      data:`${selectDealer.pais}-${selectDealer.sociedad}-${selectDealer.tipologia}-${selectDealer.codigo_extraccion}`
    }));
  }

  /* Obtiene la miga de pan y hace el dispatch de obtener las reglas de usuario por historia de usuario */
  useEffect(() => {
    dispatch(resetPaginate());
    dispatch(createBreadCrumb(routes));
    dispatch(getRulesByDealerHU1({ id, country: selectDealer.pais }));
    dispatch(getRulesByDealerHU2({ id, country: selectDealer.pais }));
    dispatch(getRulesByDealerHU3({ id, country: selectDealer.pais }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  /* Obtiene la ultima fecha al cargar el componente  */
  useEffect(() => {
    getLastDate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rulesDealer, rulesDealerHU2, rulesDealerHU3]);

  const hasButton = {
    text: "Historial de modificaciones",
    rute: `${path.split("/")[1]}/historial`,
  };
  /* funcionalidad de botones en modal */
  const onClickModal = (name) => {
    switch (name) {
      case "ASIGNAR REGLAS":
        goToCreateRules();
        break;
      case "CANCELAR":
        openModal({ id: "", hu: "" });
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (responseModal.click) {
      onClickModal(responseModal.click);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseModal]);
  /* Devolvemos todos los datos que consumiremos. */
  return {
    hasButton,
    selectDealer,
    rulesDealer,
    rulesDealerHU2,
    rulesDealerHU3,
    user,
    id,
    lastUpdate,
    openModal,
    isLoading,
    redirectToRules,
    redirectToRulesEdit,
    handleClickExecutionHU1,
    handleClickExecutionHU2,
    handleClickExecutionHU3
  };
};
export default useDealer;
