import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  width: 60%;
  margin: 47px auto 0px auto;

  & svg {
    width: 120px;
    height: 85px;
  }
`;

const Message = styled.p`
  color: #454545;
  font-size: 18px;
  font-weight: 400;
  margin-left: 31px;
`;

export { Div, Message };