import {
  ADD_ROW_SCHEME,
  REMOVE_ROW_SCHEME,
  SET_NAME_SCHEME,
  SET_FIELD_SCHEME,
  RESET,
  GET_SCHEME,
  GET_SCHEME_ITEMS
} from '../constants/actionTypes';

export const getSchemeItems = (data) => ({
  type: GET_SCHEME_ITEMS,
  payload: data
});
export const addRowScheme = (payload) => {
  return {
    type: ADD_ROW_SCHEME,
    payload
  };
};
export const removeRowScheme = (payload) => {
  return { type: REMOVE_ROW_SCHEME, payload };
};
export const setNameScheme = (payload) => {
  return { type: SET_NAME_SCHEME, payload };
};
export const setFieldScheme = (payload) => {
  return { type: SET_FIELD_SCHEME, payload };
};
export const getSchemeResponse = (payload) => {
  return {
    type: GET_SCHEME,
    payload
  };
};
export const resetScheme = () => {
  return {
    type: RESET
  };
};
