import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from 'rebass';
import { setInputSelect } from 'src/actions/addDealerActions';
import { SelectOptions } from '../SelectOptions/SelectOptions';
import { Container } from './Styles';

/* Componente que maneja el selector en el formulario de creacion del distribuidor */
/*
 * @param  {} option: opciones del selector
 * @param  {} error: booleano que valida campo vacio
 * @param  {} placeholder
 * @param  {} getValue: funcion que trae el valor seleccionado
 * @param  {} onBlur
 * @param  {} disabledInput=false
 */
const SelectInput = ({ option, error, placeholder, getValue, onBlur, disabledInput = false }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  const selectData = (value, state) => {
    dispatch(
      setInputSelect({
        name: value.name,
        value: value.label
      })
    );
  };

  useEffect(() => setValue(getValue), [getValue]);

  const handleChange = (value, state) => {
    if (value) {
      if (value.name === 'country') {
        setValue(value.label);
        selectData(value, state);
        onBlur('country');
      }

      if (value.name === 'typology') {
        setValue(value.label);
        selectData(value, state);
        onBlur('typology');
      }
      if (value.name === 'society') {
        setValue(value.label);
        selectData(value, state);
        onBlur('society');
      }
    } else {
      const { name } = Object.assign({}, ...state.removedValues);
      setValue('');
      if (name === 'country') {
        selectData({ name, label: '' }, state);
      }

      if (name === 'typology') {
        selectData({ name, label: '' }, state);
      }
      if (name === 'society') {
        selectData({ name, label: '' }, state);
      }
    }
  };

  return (
    <Container>
      <Box width={1}>
        <SelectOptions
          placeholder={placeholder}
          option={option}
          handleChange={handleChange}
          error={error}
          getValue={value === '' ? [] : [{ label: value }]}
          disabledInput={disabledInput}
        />
      </Box>
    </Container>
  );
};

export default SelectInput;
