import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { ReactComponent as SvgPlus } from '../../../images/Logo_Plus.svg';

const ContainerButtons = styled.div`
  margin-top: 3.875rem;
  & a {
    height: 100%;
    display: block;
    @media (max-width: 768px) {
      height: auto;
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
`;
const Input = styled(Form.Control)`
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  height: 100%;
  width: 22.93rem;
  padding: 0.875rem 3.4375rem 1rem 2.375rem;
  font-style: italic;
  font-family: roboto;
  &::placeholder {
    color: #8f8f8f;
  }
  &:focus {
    border: 1px solid #c4c4c4;
    box-shadow: none;
  }
`;
const ButtonSearch = styled(Button)`
  font-family: "Roboto", sans-serif;
  margin-left: 1.25rem;
  background: var(--bluePrimary);
  border-color: var(--bluePrimary);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  width: 10.8rem;
  &:active:hover:not([disabled]) {
    background: #007ab4;
    border-color: #007ab4;
  }
  &:disabled {
    background-color: #c0dcf5;
    border-color: #c0dcf5;
    opacity: 1;
  }
`;

const ButtonAddDistribuidor = styled.div`
  width: 16.81rem;
  height: 100%;
  min-height: 2.875rem;
  background: #ffffff;
  border: 1px solid var(--blueDark);
  margin: 0 0 0 auto;
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--blueDark);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 0.875rem;
  cursor: pointer;
  transition: color ease-in-out 150ms;
  @media (max-width: 768px) {
    width: 100%;
    margin: 15px 0;
  }
  &:hover {
    background: var(--blueDark);
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  &:hover > svg > path {
    fill: #fff;
  }
`;

const StyledPlus = styled(SvgPlus)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.375rem;
`;
const StyledIconClose = styled.i`
  font-size: 1.5em;
  vertical-align: middle;
  padding: 0 5px 0 0;
`;

export {
  ContainerButtons,
  StyledForm,
  Input,
  ButtonSearch,
  ButtonAddDistribuidor,
  StyledPlus,
  StyledIconClose
};
