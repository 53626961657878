// import SelectInput from '@material-ui/core/Select/SelectInput'
import { Flex } from 'rebass';
import SelectInput from '../SelectInput/SelectInput';

/* Componente para el manejo de selectores en inputs */
const SelectFilter = (props) => {
  return (
    <Flex width={1} mb={36}>
      <SelectInput {...props} />
    </Flex>
  );
};

export default SelectFilter;
