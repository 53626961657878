import styled from 'styled-components';

import { Breadcrumb } from 'react-bootstrap';

const StyledBreadCrumb = styled(Breadcrumb)`
  margin-bottom:3.625rem;
  ol {
    border: 0;
    background: none;
    padding: 0;
    font-weight: 300;
    font-size: 0.875rem;
    & > li {
      text-decoration: underline;
      color: #454545 !important;
    }
    & > li:not(:first-child)::before {
      content: '>';
      color: #454545;
    }
    & > li.active {
      text-decoration: none;
    }
  }
`;
export { StyledBreadCrumb };