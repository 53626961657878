import { SET_SORT_DEALER } from '../constants/actionTypes';
export const sortDealerReducer = (
  state = 'codigo_ascendente',
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SORT_DEALER: {
      return payload;
    }
    default:
      return state;
  }
};
