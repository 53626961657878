import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSchemeByName, putScheme, postScheme } from 'src/services';
import { addRowScheme, removeRowScheme, setNameScheme, setFieldScheme, resetScheme } from 'src/actions/schemaActions';
import { validateNameSchemes } from 'src/utils/validate';
import { createBreadCrumb } from 'src/actions/breadCrumbActions';
import { setResponseModal } from 'src/actions/responseModalActions';

const messageModal = {
  title: '¿Está seguro de que quiere abandonar esta página?',
  message:
    'Todos los cambios que no hayan sido guardados se perderán y no podrá recuperarlos.',
  buttons: [
    {
      text: 'PERMANECER EN ESTA PÁGINA',
      type: 'normal'
    },
    {
      text: 'ABANDONAR ESTA PÁGINA',
      type: 'danger'
    }
  ],
  show: false
};

export const useCreteSchema = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getDataForm = useSelector((state) => state.getDataForm);
  const responseModal = useSelector((state) => state.responseModal);
  const user = useSelector((state) =>
    state.dataUser ? state.dataUser.user : null
  );
  const isLoading = useSelector((state) => state.loading);
  const addScheme = useSelector((state) => state.schema);
  const { schemeTypes, schemeModes } = getDataForm;
  const { nameScheme } = useParams();
  const [disabledInput, setDisabledInput] = useState(false);
  const [error, setError] = useState({
    schema_name: false,
    fields: [{ name: false, type: false, mode: false }]
  });
  const inputsValidateByName = ['schema_name', 'name'];

  /* Cambia el estado del border de cada campo si los datos estan correctos. */
  const handleOnBlur = (key) => {
    setError((prevError) => ({ ...prevError, [key]: 0 }));
  };

  /* Array con las rutas para breadCrumb(miga de pan) */
  const routes = [
    {
      id: 1,
      name: 'Inicio',
      path: '/',
      exact: true
    },
    {
      id: 2,
      name: 'Esquema de tablas',
      path: '/esquemas',
      exact: true
    },
    {
      id: 3,
      name: 'crear',
      path: '/esquemas/crear',
      exact: true
    }
  ];
  const routesWatch = [
    {
      id: 1,
      name: 'Inicio',
      path: '/',
      exact: true
    },
    {
      id: 2,
      name: 'Esquema de tablas',
      path: '/esquemas',
      exact: true
    },
    {
      id: 3,
      name: `Ver ${nameScheme}`,
      path: '/esquemas/ver/:nameScheme',
      exact: true
    }
  ];
  const routesEdit = [
    {
      id: 1,
      name: 'Inicio',
      path: '/',
      exact: true
    },
    {
      id: 2,
      name: 'Esquema de tablas',
      path: '/esquemas',
      exact: true
    },
    {
      id: 3,
      name: `Editar ${nameScheme}`,
      path: '/esquemas/editar',
      exact: true
    }
  ];


  // Obtener ruta para difinir el titulo para edidat o actualizar.
  const getCurrentTitle = (name = 'Defina el titulo') => {
    const path = getPath().path.split('/');
    const title = { name: name };
    if (path[1] === 'crear') {
      Object.assign(title, { name: 'Crear esquema de tabla' });
    }
    if (path[1] === 'editar') {
      Object.assign(title, { name: 'Editar esquema de tabla' });
    }
    if (path[1] === 'ver') {
      Object.assign(title, { name: 'Ver esquema de tabla' });
    }
    return title;
  };

  // Itera sobre cada campo que sea obligatorio y cambia el estado del border.
  const valideInput = () => {
    const arrValidate = JSON.parse(JSON.stringify(addScheme));
    let validate = true;
    if (addScheme.schema_name === '') {
      validate = false;
      arrValidate.schema_name = true;
    } else {
      arrValidate.schema_name = false;
    }
    // eslint-disable-next-line array-callback-return
    addScheme.fields.map((element, k) => {
      Object.entries(element).forEach(([key, value]) => {
        if (value === '') {
          arrValidate.fields[k][key] = true;
          validate = false;
        } else {
          arrValidate.fields[k][key] = false;
        }
      });
    });
    setError(arrValidate);
    return validate;
  };

  // Obtener la ruta actual
  const getPath = useCallback(() => {
    const string = history.location.pathname;
    const path = string.slice(1, string.length);

    return {
      path
    };
  }, [history]);

  /* Cancela el evento por defecto del formulario */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  /* Crea una nueva fila para campo */
  const handleAdd = () => {
    dispatch(addRowScheme());
  };

  /* Elimina una fila para campo */
  const removeRow = (index) => {
    dispatch(removeRowScheme(index));
  };

  /* Selecionamos los datos que el usuario escriba para luego guardarlos en el Store de redux */
  const handleChange = (e, valIndex = null) => {
    let index, name, value;
    if (valIndex === null) {
      index = e.target.getAttribute('data-index');
      name = e.target.name;
      value = e.target.value;
    } else {
      index = valIndex;
      name = e.name;
      value = e.value;
    }
    if (inputsValidateByName.indexOf(name) !== -1) {
      value = validateNameSchemes(value);
    }
    if (name === 'schema_name') {
      dispatch(setNameScheme({ name, value }));
      return;
    }
    dispatch(setFieldScheme({ index, name, value }));
  };

  /* Activa el modal para avisar de una acción. */
  const handleAlert = () => {
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
  };

  /* Desactiva el modal para continuar en la pagina. */
  const handleContinue = () => {
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
  };

  /* Desactiva el modal para abandonar la pagina. */
  const handleCancel = () => {
    dispatch(resetScheme());
    dispatch(
      setResponseModal({
        ...messageModal,
        show: !responseModal.show,
        click: null
      })
    );
    setTimeout(() => history.push('/esquemas'), 1000);
  };

  /* Primero vadida que todo los datos obligarios esten completado
  Para luego guardar en la base de datos. */
  const handleSave = () => {
    const validate = valideInput();
    const path = getPath().path.split('/');
    if (validate) {
      if (path[1] === 'crear') {
        dispatch(postScheme(addScheme));
      } else if (path[1] === 'editar') {
        dispatch(putScheme(nameScheme, addScheme));
      }
    }
  };

  /* Advertencia ==>> esta logica cambiara con el tiempo ya que depende de la respuesta del servidor. */
  /* Si la respuesta del servidor fue exitosa actualizamos el estado de la tabla con el titen actualizado */
  useEffect(() => {
    const path = getPath().path.split('/');
    if (path[1] === 'editar') {
      dispatch(createBreadCrumb(routesEdit));
      dispatch(getSchemeByName(nameScheme));
    }
    if (path[1] === 'ver') {
      setDisabledInput(true);
      dispatch(createBreadCrumb(routesWatch));
      dispatch(getSchemeByName(nameScheme));
    }
    if (path[1] === 'crear') {
      dispatch(createBreadCrumb(routes));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameScheme]);

  /* Reiniciando el estado del formulario cada ves que se desmontado el componente */
  useEffect(() => {
    if (getPath().path === 'esquemas/crear') {
      dispatch(resetScheme());
    }
    return () => {
      if (getPath().path === 'esquemas/crear') {
        dispatch(resetScheme());
      }
    };
  }, [dispatch, getPath]);

  /* Funcion del modal que permite continunar en la pagina o abandonar */
  const onClickModal = (name) => {
    switch (name) {
      case 'PERMANECER EN ESTA PÁGINA':
        handleContinue();
        break;
      case 'ABANDONAR ESTA PÁGINA':
        handleCancel();
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (responseModal.click) {
      onClickModal(responseModal.click);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseModal]);
  
  /* Devolvemos todos los datos que consumiremos. */
  return {
    handleChange,
    handleAlert,
    handleSubmit,
    handleSave,
    handleOnBlur,
    error,
    getTitle: getCurrentTitle(),
    getPath,
    addScheme,
    user,
    schemeTypes,
    schemeModes,
    handleAdd,
    removeRow,
    disabledInput,
    isLoading
  };
};
