import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const Title = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  color: #0c3967;
  margin-bottom: 3.625rem;
  margin-top: 3rem;
`;

const TextMessage = styled.span`
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 600;
  color: #ec5454;
`;

const StyledLabel = styled(Form.Label)`
  font-weight: 400;
  font-size: 0.875rem;
  color: #454545;
  margin-bottom: 0.75rem;
`;

const StlyedInput = styled(Form.Control)`
  width: 100%;
  height: 46px;
  margin-bottom: 36px;
  box-sizing: border-box;
  border: solid 1px ${({ error }) => (error === 1 ? '#ec5454' : '#ced4da')};
  &::placeholder {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.3);
  }
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const Button = styled.button`
  font-weight: 700;
  background: ${(props) =>
    props.typeButton === 'normal' ? 'none' : 'var(--bluePrimary)'};
  box-shadow: ${(props) =>
    props.typeButton === 'normal'
      ? 'none'
      : '0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35);'};
  border-radius: 0.3125rem;
  text-transform: uppercase;
  width: 35rem;
  height: 2.875rem;
  border: none;
  color: ${(props) => (props.typeButton === 'normal' ? '#454545' : '#FFF')};
  font-size: 0.875rem;
  transition: background-color ease-in-out 250ms;
  &:hover {
    background: ${(props) =>
    props.typeButton === 'normal' ? '#454545' : 'var(--blueDark)'};
    color: ${(props) => (props.typeButton === 'normal' ? '#FFF' : '#FFF')};
  }
`;
export { Title, TextMessage, StyledLabel, StlyedInput, Button };
