import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: 'var(--blueDark)'
    }
  },
  completed: {
    '& $line': {
      borderColor: 'var(--blueDark)'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const styles = () => ({
  icon: {
    color: '#D9D9D9',
    '&$activeIcon': {
      color: '#0C3967'
    },
    '&$completedIcon': {
      color: '#0C3967'
    }
  },
  activeIcon: {},
  completedIcon: {}
});

const StepperFamilia = withStyles({
  root: {
    backgroundColor: 'var(--blueHighLight)'
  }
})(Stepper);

/* Componente encargado de mostrar el paso a paso en la seccion de reglas*/

/*
 * @param  {} steps: Contiene el estado del paso a paso
 * @param  {} activeStep: muestra en que paso estas ubicado
 * @param  {} classes
 */
const Steps = ({ steps, activeStep, classes }) => {
  return (
    <div>
      <StepperFamilia
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                classes: { root: classes.icon, active: classes.activeIcon, completed: classes.completedIcon }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </StepperFamilia>
    </div>
  );
};
export default withStyles(styles)(Steps);
