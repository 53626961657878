import React from 'react';
import { Card, CardTitle, Circle, CardDescription } from './Styles';


/* Componente Card de las reglas */
/*
 * @param  {} name='': nombre de la regla
 * @param  {} position='': posicion de la regla
 * @param  {} description='': descripcion de la regla
 */
const CardRule = ({ name = '', position = '', description = '' }) => {
  return (
    <Card>
      <CardTitle>
        <p>{name}</p>
        <Circle>{position + 1}</Circle>
      </CardTitle>
      <CardDescription>{description}</CardDescription>
    </Card>
  );
};

export default CardRule;
