import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Button = styled(Link)`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #009ce7;
  box-sizing: border-box;
  height: 2.875rem;
  width: 10.8125rem;
  font-weight: 700;
  font-size: 14px;
  color: var(--blueDark);
  transition: color ease-in-out 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--blueDark);
    border-color: var(--blueDark);
    color: #fff;
  }
`;

const P = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 0px;
`;

export { Button, P };