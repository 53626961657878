import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Head from '../components/Distributor/Head/Head';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import TableHistory from '../components/History/TableHistory/TableHistory';
import useHistory from '../hooks/useHistory';
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader';

/* Contenedor del historial*/
const History = () => {
  const { user, dealer, id, history, paginate, paginateHU2, paginateHU3, changePaginate, changePaginateHU2, changePaginateHU3, isLoading } =
    useHistory();
  return (
    <>
      <Header user={user} />
      <Container className='p-0'>
        <Head
          title={`${dealer.codigo_extraccion} - ${dealer.nombre}`}
          subtitle='Historial de modificaciones'
        />
        <TableHistory
          paginate={paginate}
          paginateHU2={paginateHU2}
          paginateHU3={paginateHU3}
          changePaginate={changePaginate}
          changePaginateHU2={changePaginateHU2}
          changePaginateHU3={changePaginateHU3}
          id={id}
          history={history}
        />
      </Container>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
    </>
  );
};

export default History;
