import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLink, Button } from './Styles';

/* Componente elemento de la tabla de esquemas */

/*
 * @param  {} name: Contiene nombre del modal
 * @param  {} handleModal: handle que contiene el modal
 */
const SchemeElement = ({ name, handleModal }) => {
  return (
    <tr>
      <td>
        {name}
      </td>
      <td>
        <ButtonLink to={`esquemas/ver/${name}`}>Ver Detalle</ButtonLink>
        <ButtonLink to={`esquemas/editar/${name}`}>Editar</ButtonLink>
        <Button onClick={() => handleModal({ name })}>Eliminar</Button>
      </td>
    </tr>
  );
};

SchemeElement.propTypes = {
  date: PropTypes.string,
  hour: PropTypes.string,
  by: PropTypes.string
};

export default SchemeElement;
