import { Col, Row } from 'react-bootstrap';
import {
  StyledLabel,
  StlyedInput,
  TextMessage,
  IndicatorNumber,
  ButtonDelete
} from './Styles';
import SelectScheme from '../../SelectInput/SelectScheme';
import { Box } from 'rebass';

/* Componente que añade una fila para la creacion del esquema nombre, tipo, modo */
/*
 * @param  {} index: identificador de la fila
 * @param  {} error: contiene el error en los inputs 
 * @param  {} handleChange: handle que cambia el estado de los inputs
 * @param  {} handleOnBlur: handle que activa el input al hacer click
 * @param  {} mError
 * @param  {} fields: array de filas de campos
 * @param  {} removeRow: Remueve una fila 
 * @param  {} schemeTypes: contiene los tipos del esquema
 * @param  {} schemeModes: contiene los modos del esquema
 * @param  {} disabledInput: Deshabilita el input
 */

const FieldsGroups = ({
  index,
  error,
  handleChange,
  handleOnBlur,
  mError,
  fields,
  removeRow,
  schemeTypes,
  schemeModes,
  disabledInput
}) => (
  <Row>
    <Col lg={1} xl={1} className='d-flex align-items-center'>
      <IndicatorNumber>{`${index + 1}.`}</IndicatorNumber>
    </Col>
    <Col lg={3} xl={3}>
      <StyledLabel>Nombre del campo</StyledLabel>
      <StlyedInput
        name='name'
        type='text'
        placeholder='nombre'
        onChange={handleChange}
        onBlur={() => handleOnBlur('name')}
        error={error.fields[index] ? error.fields[index].name : false}
        value={fields[index].name}
        autoComplete='off'
        data-index={index}
        disabled={disabledInput}
      />
      {error.fields[index] && error.fields[index].name ? (
        <Box mt={-36} mb={20}>
          <TextMessage> {mError} </TextMessage>
        </Box>
      ) : null}
    </Col>
    <Col lg={3} xl={3}>
      <StyledLabel>Tipo</StyledLabel>
      <SelectScheme
        option={schemeTypes}
        error={error.fields[index] ? error.fields[index].type : false}
        placeholder='Seleccione un tipo.'
        getValue={fields[index].type}
        onBlur={() => handleOnBlur('type')}
        handleChange={(e) => handleChange(e, index)}
        disabledInput={disabledInput}
      />
      {error.fields[index] && error.fields[index].type ? (
        <Box mt={-36} mb={20}>
          <TextMessage> {mError} </TextMessage>
        </Box>
      ) : null}
    </Col>
    <Col lg={3} xl={3}>
      <StyledLabel>Modo</StyledLabel>
      <SelectScheme
        option={schemeModes}
        error={error.fields[index] ? error.fields[index].mode : false}
        placeholder='Seleccione un modo.'
        getValue={fields[index].mode}
        onBlur={() => handleOnBlur('mode')}
        handleChange={(e) => handleChange(e, index)}
        disabledInput={disabledInput}
      />
      {error.fields[index] && error.fields[index].mode ? (
        <Box mt={-36} mb={20}>
          <TextMessage> {mError} </TextMessage>
        </Box>
      ) : null}
    </Col>
    {fields.length > 1 && !disabledInput ? (
      <Col lg={1} xl={1} className='d-flex align-items-center'>
        <ButtonDelete typeButton='primary' onClick={() => removeRow(index)}>
          <span>
            <i className='fas fa-trash-alt' />
          </span>
        </ButtonDelete>
      </Col>
    ) : null}
  </Row>
);
export default FieldsGroups;
