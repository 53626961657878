/* Convierte la estructura del esquema que devuelve el backend a estructura que recibe un selector */
export const SchemeToSelectFormat = (array, name) => {
  const formated = [];
  // eslint-disable-next-line array-callback-return
  array.map((element) => {
    formated.push({
      value: element.schema_name,
      label: element.schema_name,
      name
    });
  });
  return formated;
};

/* Convierte la estructura del esquema que devuelve el backend a estructura que recibe un selector */
export const SchemeFlieldsToSelectFormat = (array, name) => {
  const formated = [];
  // eslint-disable-next-line array-callback-return
  array.map((element) => {
    formated.push({
      value: element.name,
      label: element.name,
      name
    });
  });
  return formated;
};

/* Convierte una fecha en formato UTC */
export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

/* Crea una fecha en formato UTC */
export const createDateAsUTCFromString = (dateString) => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};
