import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Tr = styled.tr`
  align-items: center;
  background: #fff;
  /* border: 1px solid #c4c4c4; */
  display: flex;
  padding: 1.68rem 7.187rem 1.375rem 3.3125rem;
  width: 100%;
  td {
    display: flex;
    flex-direction: column;
    width: 35%;
  }
  & td:last-child {
    width: 10%;
  }
  & td > a:hover {
    color: var(--bluePrimary);
  }
  & td > a > p {
    font-weight: 700;
    font-size: 1.125rem;
    color: #454545;
    margin: 0;
    &:hover {
      color: var(--bluePrimary);
    }
  }
  & td > span {
    font-weight: 300;
    font-size: 0.875rem;
  }
  td:nth-of-type(2) {
    width: 20%;
    text-align: center;
  }
  td:nth-of-type(3) {
    margin: 0 auto;
    font-weight: 400;
    font-size: 0.875rem;
    color: #454545;
  }
`;

const ButtonEditar = styled(Link)`
  outline: none;
  text-decoration: none !important;
  display: inline;
  font-weight: 500;
  font-size: 1rem;
  color: #454545;
  margin: 0;
  &:hover {
    color: var(--bluePrimary);
  }
`;

export { Tr, ButtonEditar };