import styled from 'styled-components';

const Title = styled.p`
  color: #454545;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0.3125rem 0;
`;
const SubTitle = styled.p`
  margin: 0.3125rem 0;
  color: #8f8f8f;
  font-size: 0.75rem;
  font-style: italic;
`;
const Description = styled.p`
  color: #454545;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.5625rem;
  max-width: 100%;
  word-wrap: break-word;
`;
const Rule = styled.div`
  width: 100%;
  display: flex;
  word-break: break-word;
`;

export { Title, SubTitle, Description, Rule };