import { GET_SCHEME_ITEMS } from '../constants/actionTypes';

const initialState = [];

export const schemeReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_SCHEME_ITEMS:
      return payload;
    default:
      return state;
  }
};
