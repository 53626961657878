import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Label, InputDate, TextMessage } from "./styles";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { Box } from "rebass";

registerLocale("es", es);

/* Componente encargado de parametrizar la regla Iva */
/*
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeDate: handle para cambiar la fecha
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const Iva = ({ regla, handleChangeDate, error, mError, handleOnBlur }) => {
  const { parameters } = regla;
  const [time, setTime] = useState(setHours(setMinutes(new Date(), 59), 23));
  const [initDate, setInitDate] = useState(
    parameters?.init_date ? new Date(parameters?.init_date) : null
  );
  const [finalDate, setFinalDate] = useState(
    parameters?.final_date ? new Date(parameters?.final_date) : null
  );
  useEffect(() => {
    if (typeof initDate !== "undefined" && initDate !== null) {
      setTime(
        setHours(
          setMinutes(initDate, initDate.getMinutes() + 1),
          initDate.getHours()
        )
      );
    }
    if (
      typeof finalDate !== "undefined" &&
      finalDate !== null &&
      finalDate.getDate() +
        "-" +
        finalDate.getMonth() +
        "-" +
        finalDate.getYear() !==
        initDate.getDate() +
          "-" +
          initDate.getMonth() +
          "-" +
          initDate.getYear()
    ) {
      setTime(setHours(setMinutes(initDate, 0), 0));
    }
  }, [initDate, finalDate]);
  return (
    <Row>
      <Col md={6} xs={12}>
        <Label>Fecha de inicio</Label>
        <Label required bold>
          *
        </Label>
        <InputDate
          selected={
            // eslint-disable-next-line eqeqeq
            parameters?.init_date == "%Y-%m-%d %H:%M:%S" ? null : initDate
          }
          onChange={(date) => {
            handleChangeDate({ date, nameNull: "init_date", id: regla.id });
            setInitDate(date);
            setFinalDate(null);
            handleChangeDate({
              date: null,
              nameNull: "final_date",
              id: regla.id,
            });
          }}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          peekNextMonth
          locale="es"
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          timeIntervals={1}
          dropdownMode="select"
          error={error?.init_date}
          onBlur={handleOnBlur}
        />
        {error?.init_date ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Fecha Fin</Label>
        <Label required bold>
          *
        </Label>
        <InputDate
          selected={
            // eslint-disable-next-line eqeqeq
            parameters?.final_date == "%Y-%m-%d %H:%M:%S" ? null : finalDate
          }
          onChange={(date) => {
            handleChangeDate({ date, nameNull: "final_date", id: regla.id });
            setFinalDate(date);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          locale="es"
          timeIntervals={1}
          minDate={initDate}
          minTime={time}
          maxTime={setHours(setMinutes(initDate, 59), 23)}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          dropdownMode="select"
          disabled={!parameters?.init_date}
          error={error?.final_date}
          onBlur={handleOnBlur}
        />
        {error?.final_date ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default Iva;
