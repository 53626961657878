import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getCurrentId, getInputData } from 'src/actions/addDealerActions';
import { getSelectDealer } from 'src/actions/rulesActions';
import { setSortDealer } from 'src/actions/sortDealerActions';
import { resetPaginate } from '../actions/paginatorActions';
import usePaginate from './usePaginate';
import { putDealer, getDealersByID, getDealers, getRules } from '../services';
import { resetResponseModal, setResponseModal } from 'src/actions/responseModalActions';


const messages = {
  activar: {
    title: '¿Está seguro de que desea activar este distribuidor?',
    message:
      'Al hacer esto, las reglas asignadas al distribuidor en cada una de las historias de usuario empezarán a ejecutarse.',
    buttons: [
      {
        text: 'CANCELAR',
        type: 'normal'
      },
      {
        text: 'ACTIVAR',
        type: 'primary'
      }
    ]
  },
  desactivar: {
    title: 'Desactivar distribuidor',
    message:
      '¿Está seguro de que desea desactivar a este distribuidor? Al hacer esto se detendrán los procesos de transformación de las tablas suministradas por el mismo.',
    buttons: [
      {
        text: 'CANCELAR',
        type: 'normal'
      },
      {
        text: 'DESACTIVAR',
        type: 'danger'
      }
    ]
  }
};

const useDealers = () => {
  const dispatch = useDispatch();
  const dealerList = useSelector((state) => state.dealers, shallowEqual);
  const paginate = useSelector((state) => state.paginator, shallowEqual);
  const isLoading = useSelector((state) => state.loading);
  const sortDealer = useSelector((state) => state.sortDealer, shallowEqual);
  const responseModal = useSelector(
    (state) => state.responseModal,
    shallowEqual
  );
  const user = useSelector(
    (state) => (state.dataUser ? state.dataUser.user : null),
    shallowEqual
  );
  const [stateDealer, setStateDealer] = useState(null);
  const [searchDealer, setSearchDealer] = useState('');
  const [cleanSearch, setCleanSearch] = useState(false);

  /* Permite cambiar de pagina */
  const { changePaginate } = usePaginate(paginate, () =>
    dispatch(getDealers())
  );

  /* Desactiva el modal para continuar en la pagina. */
  const handleContinue = () => {
    dispatch(resetResponseModal());
  };

  /* Desactiva el modal para abandonar la pagina. */
  const handleCancel = () => {
    dispatch(resetResponseModal());
    updateDistribuidor();
  };

  /* Realiza la busqueda de distribuidor por codigo o nombre */
  const handleChangeSearch = (event) => {
    setSearchDealer(event.target.value);
  };

  /* Cambia el estado activo o inactivo del distribuidor */
  const handleChangeStatus = (status) => {
    setStateDealer(status);
  };

  /* Actualiza el distribuidor */
  const updateDistribuidor = useCallback(() => {
    const { id, estado, country } = stateDealer;
    const dealer = dealerList.find(
      (dealer) => dealer.codigo_extraccion === id && dealer.pais === country
    );
    if (cleanSearch) {
      dispatch(
        putDealer({
          dealer: { ...dealer, estado },
          search: true,
          valueSearch: searchDealer
        })
      );
    }
    dispatch(
      putDealer({
        dealer: { ...dealer, estado },
        search: false,
        valueSearch: ''
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, stateDealer, cleanSearch, dealerList]);

  /* Accion que realiza la busqueda */
  const onclickSearch = () => {
    setCleanSearch(!cleanSearch);
    dispatch(getDealersByID({ id: searchDealer }));
  };
  
  /* Limpia la busqueda */
  const onclickClean = () => {
    setSearchDealer('');
    setCleanSearch(!cleanSearch);
    dispatch(getDealers());
  };

  /* Por defecto al crear un distribuidor està desactivado, aca se valida la accion para lanzar el modal con el mensaje */
  useEffect(() => {
    if (stateDealer) {
      if (!stateDealer.estado) {
        dispatch(
          setResponseModal({
            ...messages.desactivar,
            show: !responseModal.show
          })
        );
      } else {
        dispatch(
          setResponseModal({
            ...messages.activar,
            show: !responseModal.show
          })
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDealer]);

  // Filtrado por codigo.
  const selectId = ({ id, country }) => {
    const resulData = dealerList;
    const resulFiter = resulData.find(
      (item) => item.codigo_extraccion === id && item.pais === country
    );
    return resulFiter;
  };

  // Toma el codigo(id) para filtrar y lugo editarlo.
  const handleEditItem = ({ id, country }) => {
    const resulFiter = selectId({ id, country });

    const { codigo_extraccion, pais, nombre, tipologia, sociedad } = Object.assign(
      {},
      { ...resulFiter }
    );

    const data = {
      codeDist: codigo_extraccion,
      nameDist: nombre,
      country: pais,
      typology: tipologia,
      society: sociedad
    };

    dispatch(getInputData({ data }));
  };

  // Selecciona los campos relevante de un distribuidor.
  const handleSeletRule = ({ id, country }) => {
    const resulFiter = selectId({ id, country });

    const distribuidor = Object.assign({}, resulFiter);

    dispatch(getSelectDealer(distribuidor));
  };
  const setOrderTable = (value) => {
    dispatch(setSortDealer(value));
  };

  // Se llama mientras se define su uso.
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (searchDealer != '') {
      dispatch(getDealersByID(searchDealer));
    } else {
      dispatch(getDealers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDealer]);


  useEffect(() => {
    dispatch(resetPaginate());
  }, [dispatch]);

  // Solicitando reglas.
  useEffect(() => dispatch(getRules()), [dispatch]);

  /* funcionalidad de botones en modal */
  const onClickModal = (name) => {
    switch (name) {
      case 'ACTIVAR':
        handleCancel();
        break;
      case 'DESACTIVAR':
        handleCancel();
        break;
      case 'CANCELAR':
        handleContinue();
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (responseModal.click) {
      onClickModal(responseModal.click);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseModal]);

  /* Devolvemos todos los datos que consumiremos. */
  return {
    handleChangeSearch,
    updateDistribuidor,
    handleChangeStatus,
    searchDealer,
    setSearchDealer,
    onclickSearch,
    cleanSearch,
    onclickClean,
    configEdit: {
      handleEditItem,
      dispatch,
      getCurrentId,
      handleSeletRule
    },
    setOrderTable,
    sortDealer,
    dealerList,
    paginate,
    user,
    changePaginate,
    isLoading
  };
};

export default useDealers;
