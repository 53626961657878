import Header from '../Header/Header';
import Proptypes from 'prop-types';
import { Container, Form, Col, Row } from 'react-bootstrap';
import { Box, Flex } from 'rebass';
import SelectFilter from '../SelectFilter/SelectFilter';
import Head from '../Distributor/Head/Head';
import ModalConfirmation from 'src/utils/ModalConfirmation/ModalConfirmation';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { Title, TextMessage, StyledLabel, StlyedInput, Button } from './Styles';


/* Componente para agregar un nuevo distribuidor */
/*
 * @param  {} handleSubmit: handle del formulario
 * @param  {} handleAlert: handle del modal
 * @param  {} handleChange: handle de los inputs
 * @param  {} handleSave: handle que guarda los datos
 * @param  {} handleOnBlur: handle que activa el input cuando estas ubicado sobre el
 * @param  {} optionCountries: opciones del selector de pais
 * @param  {} optionTypology: opciones del selector de tipologia
 * @param  {} optionSociety: opciones del selector de la sociedad
 * @param  {} error: booleano que valida si los campos estan diligenciados
 * @param  {} addDealer: objeto que trae los datos del formulario
 * @param  {} getTitle: funcion que trae el titulo de la pagina
 * @param  {} user: usuario loggeado
 * @param  {} disabledInput: booleano que inhabilita campos
 */
const NewDistriView = ({
  handleSubmit,
  handleAlert,
  handleChange,
  handleSave,
  handleOnBlur,
  optionCountries,
  optionTypology,
  optionSociety,
  error,
  addDealer,
  getTitle,
  user,
  disabledInput
}) => {
  const mError = 'Campo obligatorio.';
  const { nameDist, codeDist, country, typology, society } = addDealer;
  return (
    <>
      <BeforeUnloadComponent
        blockRoute
        alertMessage='¿Está seguro de que quiere abandonar esta página?'
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          return (
            <ModalConfirmation
              onClose={handleModalCancel}
              onSubmit={handleModalLeave}
            />
          );
        }}
      />
      <Header user={user} />
      <Flex width={1} mb={40}>
        <Container className='p-0'>
          <Head />
          <Title>{getTitle.name}</Title>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} xl={6}>
                <StyledLabel>Nombre del distribuidor</StyledLabel>
                <StlyedInput
                  name='nameDist'
                  type='text'
                  placeholder='Escribir nombre.'
                  onChange={handleChange}
                  onBlur={() => handleOnBlur('nameDist')}
                  error={error.nameDist}
                  value={nameDist}
                  autoComplete='off'
                />
                {error.nameDist === 1 ? (
                  <Box mt={-36} mb={20}>
                    <TextMessage> {mError} </TextMessage>
                  </Box>
                ) : null}
              </Col>

              <Col lg={6} xl={6}>
                <StyledLabel>Código de la distribuidora en Cosmos</StyledLabel>
                <StlyedInput
                  name='codeDist'
                  type='number'
                  placeholder='Escribir código'
                  onChange={handleChange}
                  onBlur={() => handleOnBlur('codeDist')}
                  error={error.codeDist}
                  value={codeDist}
                  autoComplete='off'
                  disabled={disabledInput}
                />
                {error.codeDist === 1 ? (
                  <Box mt={-36} mb={20}>
                    <TextMessage> {mError} </TextMessage>
                  </Box>
                ) : null}
              </Col>

              <Col lg={6} xl={6}>
                <StyledLabel>País de la extracción</StyledLabel>
                <SelectFilter
                  option={optionCountries}
                  name='countries'
                  error={error.country}
                  placeholder='Seleccione un país.'
                  getValue={country}
                  onBlur={handleOnBlur}
                  disabledInput={disabledInput}
                />
                {error.country === 1 ? (
                  <Box mt={-36} mb={20}>
                    <TextMessage> {mError} </TextMessage>
                  </Box>
                ) : null}
              </Col>

              <Col lg={6} xl={6}>
                <StyledLabel>Tipología de la extracción</StyledLabel>
                <SelectFilter
                  option={optionTypology}
                  name='typology'
                  placeholder='Seleccione una tipología.'
                  error={error.typology}
                  getValue={typology}
                  onBlur={handleOnBlur}
                  disabledInput={disabledInput}
                />
                {error.typology === 1 ? (
                  <Box mt={-36} mb={20}>
                    <TextMessage> {mError} </TextMessage>
                  </Box>
                ) : null}
              </Col>

              <Col lg={6} xl={6}>
                <StyledLabel>Sociedad</StyledLabel>
                <SelectFilter
                  option={optionSociety}
                  name='society'
                  placeholder='Seleccione una sociedad.'
                  error={error.society}
                  getValue={society}
                  onBlur={handleOnBlur}
                  disabledInput={disabledInput}
                />
                {error.society === 1 ? (
                  <Box mt={-36} mb={20}>
                    <TextMessage> {mError} </TextMessage>
                  </Box>
                ) : null}
              </Col>
              <Col
                lg={12}
                xl={12}
                className='d-flex justify-content-between mt-5'
              >
                <Button typeButton='normal' onClick={handleAlert}>
                  Cancelar
                </Button>
                <Button type='submit' typeButton='primary' onClick={handleSave}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Flex>
    </>
  );
};

NewDistriView.propTypes = {
  handleContinue: Proptypes.func,
  handleCancel: Proptypes.func,
  handleClose: Proptypes.func,
  handleSubmit: Proptypes.func,
  handleAlert: Proptypes.func,
  handleChange: Proptypes.func,
  handleSave: Proptypes.func,
  showModal: Proptypes.bool,
  showAlert: Proptypes.bool,
  btContinue: Proptypes.string,
  title: Proptypes.string,
  message: Proptypes.string,
  btCancel: Proptypes.string,
  optionCountries: Proptypes.array,
  optionTypology: Proptypes.array,
  error: Proptypes.object,
  messagError: Proptypes.object
};

export default NewDistriView;
