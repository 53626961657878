import React from 'react';
import PropTypes from 'prop-types';
import { Button, P } from './Styles';


/* Componente elemento de la tabla de historial de modificaciones */

/*
 * @param  {} historiasUsuario: contiene la historia de usuario
 * @param  {} date: contiene la fecha de la ultima modificacion
 * @param  {} hour: contiene la hora de la ultima modificacion
 * @param  {} by: contiene el nombre de la persona encargada de hacer la ultima modificacion
 * @param  {} id
 * @param  {} idElement
 */
const HistoryElement = ({ historiasUsuario, date, hour, by, id, idElement }) => {
  return (
    <tr>
      <td>{date}</td>
      <td>{hour}</td>
      <td><P>{historiasUsuario}</P></td>
      <td>
        Editado por: <span>{by}</span>
      </td>
      <td>
        <Button to={`/distribuidor/${id}/historial/${historiasUsuario}/detalle-regla/${idElement}`}>Ver Detalle</Button>
      </td>
    </tr>
  );
};

HistoryElement.propTypes = {
  date: PropTypes.string,
  hour: PropTypes.string,
  by: PropTypes.string
};

export default HistoryElement;
