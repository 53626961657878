import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Title, Description } from './Styles';

/* Componente que pinta cada una de las reglas para parametrizar con sus respectivos campos*/
/*
 * @param  {} reglas=[]
 * @param  {} getParamsComponent: valida el tipo de parametrizacion que tiene una regla
 * @param  {} error
 * @param  {} getDescriptionRule: devuelve la descripcion de una regla seleccionada
 */
const EditRulesGroup = ({ reglas = [], getParamsComponent, error, getDescriptionRule }) => {
  return reglas.map((regla, index) => (

    <div key={regla.id}>
      <Row>
        <Col md={4}>
          <Title>{regla.name}</Title>
          <Description>{getDescriptionRule({ name: regla.name })}</Description>
        </Col>

        <Col md={8}>{getParamsComponent({ regla, error })}</Col>
      </Row>
      <hr />
    </div>
  ));
};

EditRulesGroup.propTypes = {
  reglas: PropTypes.array
};

export default EditRulesGroup;
