import React from 'react';
import Proptypes from 'prop-types';
import { Container, Form, Col, Row } from 'react-bootstrap';
import { Box, Flex } from 'rebass';
import ModalConfirmation from 'src/utils/ModalConfirmation/ModalConfirmation';
import ModalApprove from 'src/utils/ModalApprove/ModalApprove';
import FieldsGroups from '../FieldsGroup/FieldsGroup';
import {
  StyledLabel,
  StlyedInput,
  TextMessage,
  ButtonAdd,
  Button,
  ButtonLink
} from './Styles';
import BeforeUnloadComponent from 'react-beforeunload-component';

/* Componente encargado de crear un esquema */
/*
 * @param  {} handleSubmit: handle del formulario
 * @param  {} handleAlert: handle del modal
 * @param  {} handleChange: handle de los inputs
 * @param  {} handleSave: Fhandle que guarda el esquema creado
 * @param  {} handleOnBlur
 * @param  {} showAlert: activa el modal
 * @param  {} error
 * @param  {} addScheme: objeto con los valores del formulario
 * @param  {} messagError
 * @param  {} schemeTypes: opciones del selector de tipos de esquemas
 * @param  {} schemeModes: opciones del selector de modos de esquemas
 * @param  {} handleAdd: handle que agrega una nueva fila de campos en el formulario
 * @param  {} removeRow: elimina una fila de campos en el formulario
 * @param  {} disabledInput: Desactiva el input
 */

const CreateScheme = ({
  handleSubmit,
  handleAlert,
  handleChange,
  handleSave,
  handleOnBlur,
  showAlert,
  error,
  addScheme,
  messagError,
  schemeTypes,
  schemeModes,
  handleAdd,
  removeRow,
  disabledInput
}) => {
  const mError = 'Campo obligatorio.';
  const { schema_name, fields } = addScheme;
  return (
    <>
      <BeforeUnloadComponent
        blockRoute
        alertMessage='¿Está seguro de que quiere abandonar esta página?'
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          return (
            <ModalConfirmation
              onClose={handleModalCancel}
              onSubmit={handleModalLeave}
            />
          );
        }}
      >
        <Flex>{showAlert && <ModalApprove messagError={messagError} />}</Flex>
        <Flex width={1} mb={40}>
          <Container className='p-0'>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={6} xl={6}>
                  <StyledLabel>Nombre del Esquema</StyledLabel>
                  <StlyedInput
                    name='schema_name'
                    type='text'
                    placeholder='Escribir nombre.'
                    onChange={handleChange}
                    onBlur={() => handleOnBlur(schema_name)}
                    error={error.schema_name}
                    value={schema_name}
                    autoComplete='off'
                    disabled={disabledInput}
                  />
                  {error.schema_name ? (
                    <Box mt={-36} mb={20}>
                      <TextMessage> {mError} </TextMessage>
                    </Box>
                  ) : null}
                </Col>
              </Row>
              {fields.map((element, index) => (
                <FieldsGroups
                  key={index}
                  index={index}
                  error={error}
                  handleChange={handleChange}
                  handleOnBlur={handleOnBlur}
                  fields={fields}
                  mError={mError}
                  removeRow={removeRow}
                  schemeTypes={schemeTypes}
                  schemeModes={schemeModes}
                  disabledInput={disabledInput}
                />
              ))}
              {!disabledInput ? (
                <>
                  <Row>
                    <Col lg={3} xl={3} className='d-flex align-items-center'>
                      <ButtonAdd typeButton='primary' onClick={handleAdd}>
                        <span>
                          <i className='fas fa-plus' />
                        </span>{' '}
                        Campo
                      </ButtonAdd>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={12}
                      xl={12}
                      className='d-flex justify-content-between mt-5'
                    >
                      <Button typeButton='normal' onClick={handleAlert}>
                        Cancelar
                      </Button>
                      <Button
                        type='submit'
                        typeButton='primary'
                        onClick={handleSave}
                      >
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row>
                  <Col
                    lg={12}
                    xl={12}
                    className='d-flex justify-content-between mt-5'
                  >
                    <ButtonLink to='/esquemas'>Volver</ButtonLink>
                  </Col>
                </Row>
              )}
            </Form>
          </Container>
        </Flex>
      </BeforeUnloadComponent>
    </>
  );
};

CreateScheme.propTypes = {
  handleContinue: Proptypes.func,
  handleCancel: Proptypes.func,
  handleClose: Proptypes.func,
  handleSubmit: Proptypes.func,
  handleAlert: Proptypes.func,
  handleChange: Proptypes.func,
  handleSave: Proptypes.func,
  showModal: Proptypes.bool,
  showAlert: Proptypes.bool,
  btContinue: Proptypes.string,
  title: Proptypes.string,
  message: Proptypes.string,
  btCancel: Proptypes.string,
  optionCountries: Proptypes.array,
  optionTypology: Proptypes.array,
  error: Proptypes.object,
  messagError: Proptypes.object
};

export default CreateScheme;
