import React from 'react';
import CardRule from '../CardRule/CardRule';
import { ContainerCard, CardTitle, GridCards } from './Styles';



/* Componente padre que contiene el detalle del historial por historia de usuario seleccionada */
/*
 * @param  {} history: Contiene el estado del historial
 * @param  {} userStories: Contiene el nombre de las historias de usuario
 * @param  {} hu:
 */
const CardDetailsRule = ({ history, userStories, hu }) => {
  return (
    <>
      <ContainerCard>
        <CardTitle>
          <h5>{userStories[hu]}</h5>
        </CardTitle>
        <GridCards>
          {history.rules.length > 0 ? (
            history.rules.map((item, index) => {
              return (
                <CardRule
                  key={item.name + index}
                  name={item.name}
                  position={index}
                />
              );
            })
          ) : (
            <p>No se encontraron resultados</p>
          )}
        </GridCards>
      </ContainerCard>
    </>
  );
};

export default CardDetailsRule;
