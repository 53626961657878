import React from 'react'
import Header from '../components/Header/Header'
import { Container, Col, Row, Button } from 'react-bootstrap'
import ModalApprove from '../utils/ModalApprove/ModalApprove'
import Modal from '../utils/Modal/Modal'
import Head from '../components/Distributor/Head/Head'
import Steps from '../components/Ruless/Steps/Steps'
import styled from 'styled-components'
import useRulesEdit from '../hooks/useRulesEdit'
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader'

const EditRules = () => {
  const {
    getStepContent,
    handleNext,
    handleBack,
    handleSave,
    user,
    selectDealer,
    steps,
    descriptionStep,
    activeStep,
    userStories,
    fase,
    isLoading,
    error
  } = useRulesEdit()
  return (
    <>
      <Header user={user} />
      <ContainerStyled className='px-0'>
        <Row className='align-items-end'>
          <Col xs={12} md={7}>
            <Head
              title={`${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`}
              subtitle={userStories[fase]}
              description={descriptionStep[activeStep]}
              userStories={userStories}
            />
          </Col>
          <Col xs={12} md={5}>
            <Steps steps={steps} activeStep={activeStep} />
          </Col>
        </Row>
        {activeStep === steps.length ? (
          <></>
        ) : (
          <div>{getStepContent({ activeStep, error })}</div>
        )}
        <Row className='justify-content-md-center'>
          <Col xs={6} md={4}>
            <StyledButton
              cancel={activeStep === 0 ? 1 : 0}
              onClick={handleBack}
              block
            >
              {activeStep === 0 ? 'Cancelar' : 'Atras'}
            </StyledButton>
          </Col>
          <Col xs={6} md={4}>
            <StyledButton
              onClick={
                activeStep === steps.length - 1 ? handleSave : handleNext
              }
              block
            >
              {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
            </StyledButton>
          </Col>
        </Row>
      </ContainerStyled>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
      <Modal />
    </>
  )
}
const StyledButton = styled(Button)`
  background: ${(props) => (props.cancel ? '#e5e5e5' : 'var(--bluePrimary)')};
  border-color: ${(props) => (props.cancel ? '#e5e5e5' : 'var(--bluePrimary)')};
  box-shadow: 0rem 1.25rem 1.875rem -0.75rem rgba(90, 149, 210, 0.35);
  font-size: 0.875rem;
  font-weight: 700;
  height: 2.875rem;
  text-transform: uppercase;
  color: ${(props) => (props.cancel ? '#454545' : '#fff')};
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => (props.cancel ? '#b1b1b1' : '#007ab4')} !important;
    border-color: ${(props) =>
      props.cancel ? '#b1b1b1' : '#007ab4'} !important;
    color: ${(props) => (props.cancel ? '#454545' : '#fff')} !important;
  }
  &:disabled {
    background: #e5e5e5;
    border-color: #e5e5e5;
    color: #454545;
  }
`
const ContainerStyled = styled(Container)`
  margin-bottom: 3.125rem;
`
export default EditRules
