import styled from 'styled-components';

const Card = styled.div`
  background: #ffffff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #c4c4c4;
  box-sizing: border-box;
  height: 8.0625rem;
  padding: 0.9375rem 1.0831rem 1.1875rem 1.1469rem;
  width: 100%;
`;

const CardTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  p {
    color: #454545;
    font-size: 0.75rem;
    font-weight: 700;
    margin: 0;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  border: 0.0625rem solid #c4c4c4;
  box-sizing: border-box;
  height: 1.4375rem;
  text-align: center;
  width: 1.4375rem;
  font-size: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardDescription = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  color: #454545;
  display: -webkit-box;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.5625rem;
  overflow: hidden;
`;

export { Card, CardTitle, Circle, CardDescription };