import React from 'react';
import ReactDOM from 'react-dom';
import { Background, ModalWrapper, HeadModal, SdIoClose, ContainerButtons, Button } from './Styles';

/* Modal de confirmacion al querer abandonar o quedarse */
const ModalConfirmation = ({ onSubmit, onClose }) => {
  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper>
        <HeadModal>
          <h2>¿Está seguro de que quiere abandonar esta página?</h2>
          <SdIoClose onClick={onClose} />
        </HeadModal>
        <p>Todos los cambios que no hayan sido guardados se perderán y no podrá recuperarlos.</p>
        <ContainerButtons>
          <Button type='normal' onClick={onClose}>
            PERMANECER EN ESTA PÁGINA
          </Button>
          <Button type='danger' onClick={onSubmit}>
            ABANDONAR ESTA PÁGINA
          </Button>
        </ContainerButtons>
      </ModalWrapper>
    </Background>,
    document.getElementById('root')
  );
};

export default ModalConfirmation;
