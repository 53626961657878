import React from 'react';
import Header from '../components/Header/Header';
import { Container } from 'react-bootstrap';
import Head from '../components/Distributor/Head/Head';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import CardDetalleRegla from '../components/Distributor/CardDetailsRule/CardDetailsRule';
import { createDateAsUTCFromString } from '../utils/formatting';
import { userStories } from '../constants/userStories';
import useDetailsRule from '../hooks/useDetailsRule';
import FullPageLoader from 'src/components/FullPageLoader/FullPageLoader';

/* Contenedor del detalle de reglas en historial */
const DetailsRule = () => {
  const { user, selectDealer, singleHistory, id, hu, isLoading } = useDetailsRule();
  return (
    <>
      <Header user={user} />
      <Container className='p-0'>
        <Head
          title={`${selectDealer.codigo_extraccion} - ${selectDealer.nombre}`}
          created={
            typeof singleHistory !== 'undefined'
              ? createDateAsUTCFromString(
                singleHistory.created_at
              ).toLocaleString([], { hour12: true })
              : '-'
          }
          updated=''
          user={
            typeof singleHistory !== 'undefined'
              ? singleHistory.created_by
              : '-'
          }
        />
        <CardDetalleRegla
          id={id}
          userStories={userStories}
          hu={hu}
          history={
            typeof singleHistory !== 'undefined'
              ? singleHistory
              : { reglas: [] }
          }
        />
      </Container>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
    </>
  );
};

export default DetailsRule;
