import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../Switch/Switch';
import { ContainerSwitch } from './Styles';

/* Contenedor del toggle para cambiar el estado activo o inactivo del distribuidor */
/*
 * @param  {} status: estado del distribuidor
 * @param  {} id
 * @param  {} country: pais del distribuidor
 * @param  {} handleChangeStatus: handle para el cambio del estado
 */
const SwitchToggle = ({ status, id, country, handleChangeStatus }) => {
  return (
    <ContainerSwitch>
      <p>Estado:</p>
      <Switch
        checked={status}
        id={id}
        country={country}
        handleChangeStatus={handleChangeStatus}
      />
      <span>{status ? 'Activo' : 'Desactivado'}</span>
    </ContainerSwitch>
  );
};

SwitchToggle.propTypes = {
  id: PropTypes.string,
  state: PropTypes.bool
};

export default SwitchToggle;
