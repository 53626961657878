import React from 'react';
import PropTypes from 'prop-types';
import ListingRules from '../Draggable/ListingRules/ListingRules';
import { GridContextProvider } from 'react-grid-dnd';
import BeforeUnloadComponent from 'react-beforeunload-component';
import ModalConfirmation from 'src/utils/ModalConfirmation/ModalConfirmation';

/* Componente padre que contiene el ordenamiento de las reglas */
/* 
 * @param  {} items: estado para ordenar y para las reglas
 * @param  {} onChange: Funcion que maneja el cambio de ordenamiento
 */
const Order = ({ items, onChange }) => {
  return (
    <GridContextProvider onChange={onChange}>
      <BeforeUnloadComponent
        blockRoute
        alertMessage='¿Está seguro de que quiere abandonar esta página?'
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          return (
            <ModalConfirmation
              onClose={handleModalCancel}
              onSubmit={handleModalLeave}
            />
          );
        }}
      />
      <ListingRules items={items.orden} id='orden' />
      <hr />
      <ListingRules items={items.reglas} id='reglas' />
    </GridContextProvider>
  );
};

Order.propTypes = {
  reglas: PropTypes.array
};

export default Order;
