import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Label, handleCustomStyles, selectTheme, TextMessage } from './styles';
import { Box } from 'rebass';


/* Componente encargado de parametrizar la regla Rankpm */
/*
 * @param  {} options: opciones del esquema
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeSelect: handle para cambiar la seleccion
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const Rankpm = ({ options, regla, handleChangeSelect, error, mError, handleOnBlur }) => {
  const { parameters } = regla;
  return (
    <Row>
      <Col md={6} xs={12}>
        <Label>Día</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) => handleChangeSelect({ e, id: regla.id })}
          options={options}
          placeholder='Seleccione'
          theme={selectTheme}
          value={
            typeof parameters.selected_day === 'undefined' ||
              parameters.selected_day === ''
              ? []
              : [
                {
                  value: parameters.selected_day,
                  label: options.find(
                    // eslint-disable-next-line eqeqeq
                    (element) => element.value == parameters.selected_day
                  ).label
                }
              ]
          }
          styles={handleCustomStyles({ error: error?.selected_day })}
          isDisabled={false}
          error={error?.selected_day}
          onBlur={handleOnBlur}
        />
        {error?.selected_day ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default Rankpm;
