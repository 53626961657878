import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetResponse } from '../actions/responseActions';

const useAlert = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const response = useSelector((state) => state.response);
  let timeAlert;
  useEffect(() => {
    if (response.show) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeAlert = window.setTimeout(() => {
        if (response.redirect) {
          history.push(response.redirect);
        }
        dispatch(resetResponse());
      }, 3000);
    }
    return () => window.clearTimeout(timeAlert);
  }, [response]);
  return {
    response
  };
};
export default useAlert;
