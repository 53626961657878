import {
  SET_INPUT_DATA,
  SET_INPUT_SELECT,
  SET_ADD_DEALER,
  RESET,
  GET_INPUT_DATA,
  SET_UPDATE_DEALER,
  GET_CURRENT_ID
} from '../constants/actionTypes';

export const setInputData = (data) => {
  return {
    type: SET_INPUT_DATA,
    payload: data
  };
};

export const setInputSelect = (data) => {
  return {
    type: SET_INPUT_SELECT,
    payload: data
  };
};

export const setAddDealer = (response) => {
  return {
    type: SET_ADD_DEALER,
    payload: response
  };
};

export const getInputData = (data) => {
  return {
    type: GET_INPUT_DATA,
    payload: data
  };
};

export const reset = () => {
  return {
    type: RESET
  };
};

export const getCurrentId = (id) => {
  return {
    type: GET_CURRENT_ID,
    payload: id
  };
};

export const setUpdataDealer = (response) => {
  return {
    type: SET_UPDATE_DEALER,
    payload: response
  };
};
