import React from 'react';
import ReactPaginate from 'react-paginate';
import './style.css';


/* Componente paginador */

/*
 * @param  {} paginate: contiene el estado del paginador
 * @param  {} changePaginate: accion que cambia de pagina
 */
const Paginator = ({ paginate, changePaginate }) => {
  const { total, size, page } = paginate;
  const pageCount = Math.ceil(total / size);
  return (
    <ReactPaginate
      previousLabel='Anterior'
      nextLabel='Siguiente'
      breakLabel='...'
      pageCount={pageCount}
      marginPagesDisplayed={2}
      initialPage={page}
      forcePage={page}
      pageRangeDisplayed={size}
      onPageChange={changePaginate}
      activeClassName='active'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      containerClassName='pagination'
      pageClassName='page-item'
      pageLinkClassName='page-link'
      previousClassName='page-item'
      previousLinkClassName='page-link'
      nextClassName='page-item'
      nextLinkClassName='page-link'
    />
  );
};

export default Paginator;
