import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Buttons from '../components/Dealers/ButtonsDistribuidores/Buttons';
import Tables from '../components/Dealers/TableDistribuidores/Tables/Tables';
import ModalApprove from '../utils/ModalApprove/ModalApprove';
import Modal from '../utils/Modal/Modal';
import Paginator from '../components/Paginator/Paginator';
import useDealers from '../hooks/useDealers';
import FullPageLoader from '../components/FullPageLoader/FullPageLoader';

/* Contenedor de los distribuidores */
const Dealers = () => {
  const {
    handleChangeStatus,
    setSearchDealer,
    searchDealer,
    onclickSearch,
    cleanSearch,
    onclickClean,
    configEdit,
    setOrderTable,
    sortDealer,
    dealerList,
    paginate,
    user,
    changePaginate,
    isLoading
  } = useDealers();
  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col md={12}>
            <Buttons
              setSearchDealer={setSearchDealer}
              searchDealer={searchDealer}
              onclickSearch={onclickSearch}
              cleanSearch={cleanSearch}
              onclickClean={onclickClean}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tables
              titleAppr='Los cambios se han guardado satisfactoriamente.'
              dealerList={dealerList}
              handleChangeStatus={handleChangeStatus}
              configEdit={configEdit}
              setOrderTable={setOrderTable}
              sortDealer={sortDealer}
            />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={8}>
            <Paginator paginate={paginate} changePaginate={changePaginate} />
          </Col>
        </Row>
      </Container>
      <FullPageLoader isLoading={isLoading} />
      <ModalApprove />
      <Modal />
    </>
  );
};

export default Dealers;
