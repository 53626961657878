import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { ReactComponent as SvgFamilia } from '../../images/Logo_Familia.svg';
import { ReactComponent as SvgLogout } from '../../images/Logo_logout.svg';

const StyledHeader = styled.header`
  border-bottom: 2px solid #0c3967;
  background: #fff;
`;

const StyledContainer = styled(Container)`
  min-height: 3.75rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogoFamilia = styled(SvgFamilia)`
  width: 5.18rem;
  height: 3.31rem;
`;

const StyledLogoLogout = styled(SvgLogout)`
  width: 1.31rem;
  height: 1.31rem;
  margin-left: 0.81rem;
`;

const ContainerLogout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blueDark);
  font-weight:500;
  text-transform: capitalize;
  
`;
const ButtonLogout = styled.div`
  cursor: pointer;
`;

export { StyledContainer, StyledHeader, StyledLogoFamilia, ContainerLogout, StyledLogoLogout, ButtonLogout };
