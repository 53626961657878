import React from 'react'
import { Select } from './Styles'

/* nombre_descendente
nombre_ascendente
codigo_descendente
codigo_ascendente */
const options = [
  { value: 'codigo_ascendente', label: 'Código ascendente' },
  { value: 'codigo_descendente', label: 'Código descendente' },
  { value: 'nombre_ascendente', label: 'Nombre ascendente' },
  { value: 'nombre_descendente', label: 'Nombre descendente' }
]

/* Este componente contiene la lista de filtros en la tabla de distribuidores */
/*
 * @param  {} setOrderTable: funcion que envia el dispatch de setSortDealer al estado
 * @param  {} sortDealer:  contiene el estado del filtro de codigo o nombre
 */
const SelectFilter = ({ setOrderTable, sortDealer }) => {
  const handleChange = (e) => {
    setOrderTable(e.target.value)
  }
  return (
    <>
      <Select onChange={handleChange} value={sortDealer}>
        {options.map((element) => (
          <option
            key={element.value}
            value={element.value}
          >
            {element.label}
          </option>
        ))}
      </Select>
      <span />
    </>
  )
}

export default SelectFilter
