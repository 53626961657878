import {
  GET_RULES_ITEMS,
  GET_SELECT_DEALER,
  GET_SELECT_CHECKED,
  GET_RULES_DEALER_HU1,
  GET_RULES_DEALER_HU2,
  GET_RULES_DEALER_HU3,
  SET_RULES_ORDER,
  SET_PARAMETER_RULE,
  RESET_RULES,
  LOAD_RULES_EDIT,
} from "../constants/actionTypes";

const initialState = {
  allRules: [],
  selectDealer: {},
  newRules: [],
  response: {},
  rulesDealer: [],
  rulesDealerHU2: [],
  rulesDealerHU3: [],
};

export const rulesReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_RULES_ITEMS:
      return {
        ...state,
        allRules: payload.status === 200 ? payload.data : [],
        response: payload,
      };
    case GET_RULES_DEALER_HU1:
      return {
        ...state,
        rulesDealer: [...payload],
      };
    case GET_RULES_DEALER_HU2:
      return {
        ...state,
        rulesDealerHU2: [...payload],
      };
    case GET_RULES_DEALER_HU3:
      return {
        ...state,
        rulesDealerHU3: [...payload],
      };
    case GET_SELECT_DEALER:
      return {
        ...state,
        ...Object.assign(
          {},
          { selectDealer: { ...state.selectDealer, ...payload } }
        ),
      };
    case GET_SELECT_CHECKED:
      const arrayRules = [];
      const rule = state.allRules.items.find(
        (item) => item.name === payload.id
      );
      arrayRules.push({ ...rule });
      return {
        ...state,
        allRules: Object.assign(
          {},
          {
            items: state.allRules.items.map((item) => {
              if (item.name === payload.id) {
                return Object.assign({}, { ...item, check: payload.check });
              } else {
                return item;
              }
            }),
          }
        ),
        selectedRules: payload.check
          ? [...state.selectedRules, ...arrayRules]
          : [
              ...state.selectedRules.filter(
                (element) => element.name !== payload.id
              ),
            ],
        newRules: payload.check
          ? [...state.newRules]
          : [
              ...state.newRules.filter(
                (element) => element.name !== payload.id
              ),
            ],
      };
    case SET_RULES_ORDER: {
      const combineRules = payload.map((rule) => {
        // eslint-disable-next-line array-callback-return
        const findRule = state.newRules.find((ruleSearch) => {
          if (ruleSearch.id === rule.id) {
            return {
              ...ruleSearch,
              parameters: { ...ruleSearch.parameters, ...rule.parameters },
            };
          }
        });
        if (typeof findRule !== "undefined") {
          return { ...findRule };
        }
        return { ...rule, parameters: {} };
      });
      return { ...state, newRules: [...combineRules] };
    }
    case SET_PARAMETER_RULE: {
      return {
        ...state,
        newRules: state.newRules.map((item) => {
          if (item.id === payload.id) {
            return Object.assign(
              {},
              {
                ...item,
                parameters: Object.assign(
                  {},
                  { ...item.parameters, [payload.name]: payload.value }
                ),
              }
            );
          } else {
            return { ...item };
          }
        }),
      };
    }
    case RESET_RULES: {
      return {
        ...state,
        allRules: Object.assign(
          {},
          {
            items: state.allRules.items.map((item) =>
              Object.assign({}, { ...item, check: false })
            ),
          }
        ),
        newRules: [],
        selectedRules: [],
      };
    }
    case LOAD_RULES_EDIT: {
      const arrayRules = [];
      return {
        ...state,
        allRules: Object.assign(
          {},
          {
            items: state.allRules.items.map((item) => {
              const foundRule = payload.find((rule) => rule.name === item.name);
              if (typeof foundRule !== "undefined") {
                arrayRules.push(foundRule);
                return Object.assign({}, { ...item, check: true });
              } else {
                return item;
              }
            }),
          }
        ),
        selectedRules: [...arrayRules],
        newRules: [...payload],
      };
    }
    default:
      return state;
  }
};
