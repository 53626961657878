import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Label, Input, handleCustomStyles, selectTheme, TextMessage } from './styles';
import { SchemeToSelectFormat, SchemeFlieldsToSelectFormat } from 'src/utils/formatting';
import { Box } from 'rebass';


/* Componente encargado de parametrizar la regla ConcatFieldsFn */
/*
 * @param  {} options: opciones del esquema
 * @param  {} regla: objeto con los parametros de esa regla
 * @param  {} handleChangeSelect: handle para cambiar la seleccion
 * @param  {} handleChangeInput: handle para el input
 * @param  {} error
 * @param  {} mError
 * @param  {} handleOnBlur
 */
const ConcatFieldsFn = ({
  options,
  regla,
  handleChangeSelect,
  handleChangeInput,
  error,
  mError,
  handleOnBlur
}) => {
  const { parameters } = regla;
  const optionsSchemas = SchemeToSelectFormat(options, 'esquema');
  const optionsCampos = parameters?.schema?.fields
    ? SchemeFlieldsToSelectFormat(parameters.schema.fields, 'campos')
    : [];
  let valuesMulti = [];
  if (typeof parameters.campos !== 'undefined' || parameters.campos !== '') {
    valuesMulti = parameters.campos;
  }
  const optionsWriteDisposition = [
    {
      value: ',',
      label: ',',
      name: 'separator'
    },
    {
      value: ';',
      label: ';',
      name: 'separator'
    },
    {
      value: '|',
      label: '|',
      name: 'separator'
    },
    {
      value: '~',
      label: '~',
      name: 'separator'
    },
    {
        value: ' ',
        label: ' ',
        name: 'separator'
    },
    {
        value: '_',
        label: '_',
        name: 'separator'
    },
    {
        value: '-',
        label: '-',
        name: 'separator'
    },
    {
        value: '',
        label: '',
        name: 'separator'
    },
  ];
  return (
    <Row>
      <Col md={6} xs={12}>
        <Label>Tabla a concatenar</Label>
        <Label required bold>*</Label>
        <Input
          name='table'
          placeholder='Tabla a concatenar'
          value={parameters.table}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.table}
          onBlur={handleOnBlur}
        />
        {error?.table ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Nombre del dataset</Label>
        <Label required bold>*</Label>
        <Input
          name='dataset'
          placeholder='Nombre del dataset'
          value={parameters.dataset}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.dataset}
          onBlur={handleOnBlur}
        />
        {error?.dataset ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Separador</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) => handleChangeSelect({ e, id: regla.id })}
          options={optionsWriteDisposition}
          placeholder='Seleccione el separador'
          theme={selectTheme}
          value={
            typeof parameters.separator === 'undefined' ||
              parameters.separator === ''
              ? []
              : [
                {
                  value: parameters.separator,
                  label: parameters.separator
                }
              ]
          }
          styles={handleCustomStyles({ error: error?.write_disposition })}
          isDisabled={false}
          error={error?.separator}
          onBlur={handleOnBlur}
        />
        {error?.separator ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Esquema</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) => handleChangeSelect({ e, id: regla.id, dependentField: 'campos' })}
          options={optionsSchemas}
          placeholder='Seleccione Esquema de la tabla inicial'
          theme={selectTheme}
          value={
            typeof parameters.esquema === 'undefined' ||
              parameters.esquema === ''
              ? []
              : [{ value: parameters?.esquema, label: parameters?.esquema }]
          }
          styles={handleCustomStyles({ error: error?.esquema })}
          isDisabled={false}
          error={error?.esquema}
          onBlur={handleOnBlur}
        />
        {error?.esquema ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Campos</Label>
        <Label required bold>*</Label>
        <Select
          onChange={(e) =>
            handleChangeSelect({ e, id: regla.id, nameNull: 'campos' })}
          options={optionsCampos}
          placeholder='Seleccione los campos que se concatenaran'
          theme={selectTheme}
          value={valuesMulti}
          isDisabled={false}
          styles={handleCustomStyles({ error: error?.campos })}
          isMulti
          onBlur={handleOnBlur}
        />
        {error?.campos ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Dataset Resultado</Label>
        <Label required bold>*</Label>
        <Input
          name='dataset2'
          placeholder='Nombre del dataset'
          value={parameters.dataset2}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.dataset2}
          onBlur={handleOnBlur}
        />
        {error?.dataset2 ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
      <Col md={6} xs={12}>
        <Label>Tabla a resultado</Label>
        <Label required bold>*</Label>
        <Input
          name='table2'
          placeholder='Tabla a concatenar'
          value={parameters.table2}
          onChange={(e) => handleChangeInput({ e, id: regla.id })}
          error={error?.table2}
          onBlur={handleOnBlur}
        />
        {error?.table2 ? (
          <Box mt={0} mb={0}>
            <TextMessage>{mError}</TextMessage>
          </Box>
        ) : null}
      </Col>
    </Row>
  );
};

export default ConcatFieldsFn;